import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IHelperService } from "src/app/services/helper/helper";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";

@Component({
  selector: "app-because-signature",
  templateUrl: "./because-signature.component.html",
  styleUrls: ["./because-signature.component.scss"],
})
export class BecauseSignatureComponent implements OnInit {
  public formGroup: FormGroup;
  public company: any = {};
  public signatureEvidences: any[] = [];

  constructor(
    private cardboardService: ICardboardService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private helperService: IHelperService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      companyId: [undefined, Validators.required],
      signedByBeCause: [undefined, Validators.required],
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: any) => {
      if (params.params.companyId) {
        this.getCompanyById(params.params.companyId);
      }
    });
  }

  getCompanyById(id: any): void {
    this.cardboardService
      .getCardboardCompanyById(id)
      .subscribe((response: any) => {
        this.formGroup.patchValue({ companyId: response.id });
        this.company = response;
      });
  }

  downloadFile(contract: any): void {
    window.open(
      `${environment.API_URL}/cardboard/${this.company?.id}/cpa/${contract.document}`,
      "_blank"
    );
  }

  downloadSignedDocument(url: string): void {
    window.open(`${environment.API_URL}${url}`, "_blank");
  }

  uploadContract(): void {
    if (this.formGroup.invalid || this.signatureEvidences?.length < 1) {
      return;
    }

    const form = Object.assign({}, this.formGroup.value);
    form.signatureEvidences = this.signatureEvidences;

    this.cardboardService
      .uploadBeCauseSignedContract(form)
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translateService.instant(
              "Cardboard_RepresentativeSignatureSuccessfulTitle_CPA"
            ),
            text: this.translateService.instant(
              "Cardboard_RepresentativeSignatureSuccessfulText_CPA"
            ),
            icon: "success",
          }).then(() => {
            window.location.reload();
          });
        }
      });
  }

  getUploadedFiles(event: any, type: string): void {
    switch (type) {
      case "1":
        if (!event[0]) {
          this.formGroup.patchValue({
            signedByBeCause: undefined,
          });

          return;
        }

        this.helperService
          .fileToBase64(event[0].file)
          .then((base64String: string) => {
            this.formGroup.patchValue({
              signedByBeCause: {
                file: base64String,
                name: event[0]?.name,
              },
            });
          });

        break;

      case "2":
        this.signatureEvidences = [];

        event.map((currentFile: any) => {
          this.helperService
            .fileToBase64(currentFile.file)
            .then((base64String: string) => {
              this.signatureEvidences.push({
                file: base64String,
                name: currentFile.name,
              });
            });
        });
        break;
    }
  }
}
