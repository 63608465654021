import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IRegister } from 'src/app/models/parameters/IRegister';
import Swal from 'sweetalert2';
import { IRegisterService } from './register';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
    providedIn: 'root'
})
export class RegisterService implements IRegisterService {
    private apiURL = `${environment.API_URL}/api/registers/`;

    constructor(
        private http: HttpClient,
        private translateService : TranslateService) { }

    getRegisters() {
        return this.http.get<IRegister[]>(this.apiURL + 'GetAllRegisters').pipe(
            retry(0),
            catchError(this.handleError)
        );
    }

    getRegisterById(id: string): Observable<IRegister> {
        return this.http.get<IRegister>(`${this.apiURL}GetRegisterById?id=${id}`).pipe(
            retry(1),
            catchError(this.handleError)
        );
    }

    getRegistersByCountry(country: string) {
        return this.http.get<any>(`${this.apiURL}getRegistersByCountry?country=${country}`)
            .pipe(map((response: any) => {
                if (response.status === 200) {
                    return response.data;
                } else {
                    var id = Sentry.captureMessage("General Error on POST:getRegistersByCountry endpoint");
                    Swal.fire({
                        icon: 'error',
                        title: `${this.translateService.instant('General_Error')}`,
                        text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
                    });
                }
            }));
    }

    saveRegister(register: IRegister) {
        return this.http.post<number>(`${this.apiURL}SaveRegister`, register).pipe(
            retry(0),
            catchError(this.handleError)
        );
    }

    updateRegister(register: IRegister) {
        return this.http.post<any>(`${this.apiURL}UpdateRegister`, register).pipe(
            retry(0),
            catchError(this.handleError)
        );
    }

    deleteRegister(id: string) {
        const register = { UpdateId: id };

        return this.http.post<any>(`${this.apiURL}DeleteRegister`, register).pipe(
            retry(0),
            catchError(this.handleError)
        );
    }

    // Error handling
    handleError(error: any) {
        var id = Sentry.captureException(error);
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        Swal.fire({
            title: `${this.translateService.instant('General_Error')}`,
            text: `Report id: ${id} \n ${errorMessage}`,
            icon: 'error'
        });

        return throwError(errorMessage);
    }
}
