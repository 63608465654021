import { DialogTermsConditionsComponent } from "src/app/components/dialogs/dialog-terms-conditions/dialog-terms-conditions.component";
import { DialogPrivacyPolicyComponent } from "src/app/components/dialogs/dialog-privacy-policy/dialog-privacy-policy.component";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { IRegisterAuthorities } from "src/app/models/parameters/IRegisterAuthorities";
import { IVatValidationObject } from "src/app/models/company/IVatValidationObject";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { ViewTitleService } from "src/app/services/viewTitle/view-title.service";
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { INationality } from "src/app/models/parameters/INationality";
import { ICountryService } from "src/app/services/country/country";
import { environment } from "src/environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { IVATService } from "src/app/services/vat/vat";
import { TranslateService } from "@ngx-translate/core";
import { MatStepper } from "@angular/material/stepper";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: "lsc-add-company",
  templateUrl: "./add-company.component.html",
  styleUrls: ["./add-company.component.scss"],
})
export class AddLSCCompanyComponent implements OnInit, OnDestroy {
  @ViewChild("inputFile") inputFile!: ElementRef;
  @ViewChild("stepper") private stepper: MatStepper;
  @ViewChild("selectLegalForms") selectLegalForms;

  public companyFormGroup: FormGroup;
  public uploadContractFormGroup: FormGroup;
  public SeparateDialCode = false;
  public Nationalities: INationality[];
  public vatValidationObject: IVatValidationObject;
  public Authorities: IRegisterAuthorities[];
  public userAccountsLimit: number = 10;
  public file: any;
  public updateFile: any;
  public crmAPI = environment.API_URL;
  public signature: any;
  public tradeRegisterExcerpt: any = undefined;
  public completed: any = {
    companyInformation: false,
    cardboardRepresentatives: false,
    contractSignature: false,
  };
  public countries: any[] = [];
  public vat: string = "";
  public legalForms: any[] = [
    {
      name: "associazione impresa",
      description: "associazione impresa",
    },
    {
      name: "impresa individuale",
      description: "impresa individuale",
    },
    {
      name: "s.r.l.",
      description: "società a responsabilità limitata",
    },
    {
      name: "s.c.a.r.l",
      description: "società consortile a responsabilità limitata",
    },
    {
      name: "soc.coop.cons.",
      description: "società cooperativa consortile",
    },
    {
      name: "s.p.a. con unico socio",
      description: "società per azioni con unico socio",
    },
    {
      name: "s.c.a.s.",
      description: " società consortile in accomandita semplice",
    },
    {
      name: "s.c.p.a",
      description: "società consortile per azioni",
    },
    {
      name: "s.p.a.",
      description: "società per azioni",
    },
    {
      name: "s.a.s.",
      description: "società in accomandita semplice",
    },
    {
      name: "s.a.p.a.",
      description: "società in accomandita per azioni",
    },
    {
      name: "soc.coop.",
      description: "società cooperativa",
    },
    {
      name: "s.c.r.l.",
      description: "società cooperativa a responsabilità limitata",
    },
    {
      name: "s.n.c.",
      description: "società in nome collettivo",
    },
    {
      name: "s.s.",
      description: "società semplice",
    },
    {
      name: "s.r.l. con unico socio",
      description: "società a responsabilità limitata con unico socio",
    },
  ];
  public company: any = {};

  public titles: any[] = [
    "General_Mr",
    "General_Ms",
    "General_Dr",
    "General_Dra",
  ];
  public provinces: any = [
    {
      name: "Agrigento",
      initial: "AG",
    },
    {
      name: "Alessandria",
      initial: "AL",
    },
    {
      name: "Ancona",
      initial: "AN",
    },
    {
      name: "Aosta",
      initial: "AO",
    },
    {
      name: "Arezzo",
      initial: "AR",
    },
    {
      name: "Ascoli Piceno",
      initial: "AP",
    },
    {
      name: "Asti",
      initial: "AT",
    },
    {
      name: "Avellino",
      initial: "AV",
    },
    {
      name: "Bari",
      initial: "BA",
    },
    {
      name: "Barletta-Andria-Trani",
      initial: "BT",
    },
    {
      name: "Belluno",
      initial: "BL",
    },
    {
      name: "Benevento",
      initial: "BN",
    },
    {
      name: "Bergamo",
      initial: "BG",
    },
    {
      name: "Biella",
      initial: "BI",
    },
    {
      name: "Bologna",
      initial: "BO",
    },
    {
      name: "Bolzano",
      initial: "BZ",
    },
    {
      name: "Brescia",
      initial: "BS",
    },
    {
      name: "Brindisi",
      initial: "BR",
    },
    {
      name: "Cagliari",
      initial: "CA",
    },
    {
      name: "Caltanissetta",
      initial: "CL",
    },
    {
      name: "Campobasso",
      initial: "CB",
    },
    {
      name: "Caserta",
      initial: "CE",
    },
    {
      name: "Catania",
      initial: "CT",
    },
    {
      name: "Catanzaro",
      initial: "CZ",
    },
    {
      name: "Chieti",
      initial: "CH",
    },
    {
      name: "Como",
      initial: "CO",
    },
    {
      name: "Cosenza",
      initial: "CS",
    },
    {
      name: "Cremona",
      initial: "CR",
    },
    {
      name: "Crotone",
      initial: "KR",
    },
    {
      name: "Cuneo",
      initial: "CN",
    },
    {
      name: "Enna",
      initial: "EN",
    },
    {
      name: "Fermo",
      initial: "FM",
    },
    {
      name: "Ferrara",
      initial: "FE",
    },
    {
      name: "Firenze",
      initial: "FI",
    },
    {
      name: "Foggia",
      initial: "FG",
    },
    {
      name: "Forlì-Cesena",
      initial: "FC",
    },
    {
      name: "Frosinone",
      initial: "FR",
    },
    {
      name: "Genova",
      initial: "GE",
    },
    {
      name: "Gorizia",
      initial: "GO",
    },
    {
      name: "Grosseto",
      initial: "GR",
    },
    {
      name: "Imperia",
      initial: "IM",
    },
    {
      name: "Isernia",
      initial: "IS",
    },
    {
      name: "L'Aquila",
      initial: "AQ",
    },
    {
      name: "La Spezia",
      initial: "SP",
    },
    {
      name: "Latina",
      initial: "LT",
    },
    {
      name: "Lecce",
      initial: "LE",
    },
    {
      name: "Lecco",
      initial: "LC",
    },
    {
      name: "Livorno",
      initial: "LI",
    },
    {
      name: "Lodi",
      initial: "LO",
    },
    {
      name: "Lucca",
      initial: "LU",
    },
    {
      name: "Macerata",
      initial: "MC",
    },
    {
      name: "Mantova",
      initial: "MN",
    },
    {
      name: "Massa-Carrara",
      initial: "MS",
    },
    {
      name: "Matera",
      initial: "MT",
    },
    {
      name: "Messina",
      initial: "ME",
    },
    {
      name: "Milano",
      initial: "MI",
    },
    {
      name: "Modena",
      initial: "MO",
    },
    {
      name: "Monza e Brianza",
      initial: "MB",
    },
    {
      name: "Napoli",
      initial: "NA",
    },
    {
      name: "Novara",
      initial: "NO",
    },
    {
      name: "Nuoro",
      initial: "NU",
    },
    {
      name: "Oristano",
      initial: "OR",
    },
    {
      name: "Padova",
      initial: "PD",
    },
    {
      name: "Palermo",
      initial: "PA",
    },
    {
      name: "Parma",
      initial: "PR",
    },
    {
      name: "Pavia",
      initial: "PV",
    },
    {
      name: "Perugia",
      initial: "PG",
    },
    {
      name: "Pesaro e Urbino",
      initial: "PU",
    },
    {
      name: "Pescara",
      initial: "PE",
    },
    {
      name: "Piacenza",
      initial: "PC",
    },
    {
      name: "Pisa",
      initial: "PI",
    },
    {
      name: "Pistoia",
      initial: "PT",
    },
    {
      name: "Pordenone",
      initial: "PN",
    },
    {
      name: "Potenza",
      initial: "PZ",
    },
    {
      name: "Prato",
      initial: "PO",
    },
    {
      name: "Ragusa",
      initial: "RG",
    },
    {
      name: "Ravenna",
      initial: "RA",
    },
    {
      name: "Reggio Calabria",
      initial: "RC",
    },
    {
      name: "Reggio Emilia",
      initial: "RE",
    },
    {
      name: "Rieti",
      initial: "RI",
    },
    {
      name: "Rimini",
      initial: "RN",
    },
    {
      name: "Roma",
      initial: "RM",
    },
    {
      name: "Rovigo",
      initial: "RO",
    },
    {
      name: "Salerno",
      initial: "SA",
    },
    {
      name: "Sassari",
      initial: "SS",
    },
    {
      name: "Savona",
      initial: "SV",
    },
    {
      name: "Siena",
      initial: "SI",
    },
    {
      name: "Siracusa",
      initial: "SR",
    },
    {
      name: "Sondrio",
      initial: "SO",
    },
    {
      name: "Sud Sardegna",
      initial: "SU",
    },
    {
      name: "Taranto",
      initial: "TA",
    },
    {
      name: "Teramo",
      initial: "TE",
    },
    {
      name: "Terni",
      initial: "TR",
    },
    {
      name: "Torino",
      initial: "TO",
    },
    {
      name: "Trapani",
      initial: "TP",
    },
    {
      name: "Trento",
      initial: "TN",
    },
    {
      name: "Treviso",
      initial: "TV",
    },
    {
      name: "Trieste",
      initial: "TS",
    },
    {
      name: "Udine",
      initial: "UD",
    },
    {
      name: "Varese",
      initial: "VA",
    },
    {
      name: "Venezia",
      initial: "VE",
    },
    {
      name: "Verbano-Cusio-Ossola",
      initial: "VB",
    },
    {
      name: "Vercelli",
      initial: "VC",
    },
    {
      name: "Verona",
      initial: "VR",
    },
    {
      name: "Vibo Valentia",
      initial: "VV",
    },
    {
      name: "Vicenza",
      initial: "VI",
    },
    {
      name: "Viterbo",
      initial: "VT",
    },
  ];
  public businessTitles: string[] = [
    "Cardboard_Ammindistratore_Delegato",
    "Cardboard_Amministratore_Unico",
    "Cardborad_Consigliere",
    "Cardborad_Consigliere_Delegato",
    "Cardboard_Direttore_Finanziario",
    "Cardboard_Direttore_Generale",
    "Cardboard_Legale_Rappresentante",
    "Cardboard_Institore",
    "Cardboard_Presidente",
    "Cardboard_Presidente_Consiglio_Amministrazione",
    "Cardboard_Procuratore_Speciale",
    "Cardboard_Vice_Presidente",
  ];
  public phoneCountries: any[] = [
    // {
    //   name: "General_France",
    //   code: "+33",
    //   flag: "fr",
    // },
    // {
    //   name: "General_Germany",
    //   code: "+49",
    //   flag: "de",
    // },
    {
      name: "General_Italy",
      code: "+39",
      flag: "it",
    },
    // {
    //   name: "General_Luxembourg",
    //   code: "+352",
    //   flag: "lu",
    // },
    // {
    //   name: "General_Spain",
    //   code: "+34",
    //   flag: "es",
    // },
    // {
    //   name: "General_Switzerland",
    //   code: "+41",
    //   flag: "ch",
    // },
  ];

  get ContactName(): AbstractControl {
    return this.companyFormGroup.get(["contact", "name"]);
  }

  get ContactLastName(): AbstractControl {
    return this.companyFormGroup.get(["contact", "lastName"]);
  }

  get ContactTitle(): AbstractControl {
    return this.companyFormGroup.get(["contact", "title"]);
  }

  get ContactEmail(): AbstractControl {
    return this.companyFormGroup.get(["contact", "email"]);
  }

  get ContactPhone(): AbstractControl {
    return this.companyFormGroup.get(["contact", "phone"]);
  }

  get ContactCountryPhone(): AbstractControl {
    return this.companyFormGroup.get(["contact", "countryPhone"]);
  }

  @ViewChild("select") select: NgSelectComponent;
  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost =
        (event.target.className as string).indexOf("ng-dropdown-panel-items") >
        -1;
      if (isScrollingInScrollHost) {
        return;
      }
      this.select.close();
    }
  };

  constructor(
    private cardboardService: ICardboardService,
    private viewTitleService: ViewTitleService,
    private countryService: ICountryService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private vatService: IVATService,
    private _dialog: MatDialog,
    private _router: Router
  ) {
    this.companyFormGroup = this.formBuilder.group({
      id: 0,
      name: ["", [Validators.required, Validators.maxLength(100)]],
      vat: [
        "",
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ],
      ],
      legalForm: ["", Validators.required],
      comment: [""],
      contact: this.formBuilder.group({
        id: 0,
        name: ["", Validators.required],
        lastName: ["", Validators.required],
        title: ["", Validators.required],
        email: [
          "",
          [
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            Validators.required,
          ],
        ],
        countryPhone: ["+39", Validators.required],
        phone: [
          "",
          [Validators.required, Validators.pattern("^[- +()0-9]{7,}")],
        ],
      }),
      country: ["", Validators.required],
      street: ["", Validators.required],
      number: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
      cardboardUsers: this.formBuilder.array([]),
      termsConditions: [false, Validators.requiredTrue],
      privacyPolicy: [false, Validators.requiredTrue],
    });

    this.uploadContractFormGroup = this.formBuilder.group({
      file: [""],
      cardboardRepresentatives: this.formBuilder.array([]),
    });

    this.legalForms = this.legalForms.sort((a: any, b: any) => {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
  }

  ngOnInit() {
    window.addEventListener("scroll", this.onScroll, true);

    this.translate.stream("General_Italy").subscribe((value: string) => {
      this.companyFormGroup.patchValue({
        country: this.translate.instant(value),
      });

      this.companyFormGroup.get("country").disable({ onlySelf: true });

      this.viewTitleService.emitChange({
        title: `${this.translate.instant(
          "General_Company"
        )} | CDC Cartel Damage Claims`,
        breadCrumbsItems: [],
      });
    });

    this.countries = [
      {
        name: "Italy",
        id: "5eb42a79a4fcca596c4ccbc4",
      },
    ];

    this.initVat();
    let setCompanyValue = false;
    this.activatedRoute.params.subscribe((values: any) => {
      this.companyFormGroup.patchValue({
        id: values.id ?? 0,
        vat: values.vat ?? "",
      });

      if (!setCompanyValue) {
        this.setCompanyData();
      }
    });
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  }

  ngAfterContentInit() {}

  getCountries(): void {
    this.countryService
      .getCountriesDropdownWithLegalForms()
      .subscribe((countries: any) => {
        this.countries = countries;
        this.phoneCountries = countries.map((country: any) => {
          return {
            name: country.name,
            code: `+${country.phoneCode}`,
            flag: country.iso,
          };
        });
      });
  }

  setCompanyData(): void {
    const company = Object.assign({}, this.companyFormGroup.value);

    if (company?.id && company?.vat) {
      this.cardboardService
        .getCardboardCompanyByVAT(company.vat)
        .subscribe((response: any) => {
          if (!response) {
            this._router.navigate(["/register/"]);
            return;
          }

          this.company = response;
          this.vat = response.vat;

          if (this.company.country !== "Italy") {
            this.getCountries();
          }

          this.companyFormGroup.patchValue({
            id: response.id,
            name: response.name,
            vat: response.vat,
            legalForm: response.legalForm,
            comment: response.comment,
            country: response.country,
            city: response.city,
            state: response.state,
            street: response.street,
            number: response.number,
            postalCode: response.postalCode,
            // contact: response.contact,
            termsConditions: true,
            privacyPolicy: true,
          });

          if (response.name) {
            this.companyFormGroup.get("name").disable({ onlySelf: true });
          }

          if (response.vat) {
            this.companyFormGroup.get("vat").disable({ onlySelf: true });
          }

          if (response.cardboardContacts?.length > 0) {
            const contact = {
              id: response.cardboardContacts[0]?.id,
              name: response.cardboardContacts[0]?.name,
              lastName: response.cardboardContacts[0]?.lastName,
              title: response.cardboardContacts[0]?.title,
              email: response.cardboardContacts[0]?.email,
              phone: response.cardboardContacts[0]?.phone,
              countryPhone: response.cardboardContacts[0]?.countryPhone,
            };

            this.companyFormGroup.patchValue({
              contact: contact,
            });
          }

          const registerURL = `/register/${company.vat}/${response.id}`;

          if (this._router.url !== registerURL) {
            this._router.navigate([registerURL]);
          }

          if (response.cardboardUsers?.length) {
            while (this.cardboardUsers.length !== 0) {
              this.cardboardUsers.removeAt(0);
            }

            response.cardboardUsers.map((user: any) => {
              this.cardboardUsers.push(
                this.formBuilder.group({
                  id: user.id,
                  name: [user.name, Validators.required],
                  lastName: [user.lastName, Validators.required],
                  title: [user.title, Validators.required],
                  email: [
                    user.email,
                    [
                      Validators.required,
                      Validators.pattern(
                        "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
                      ),
                    ],
                  ],
                })
              );
            });
          }

          if (
            !this.companyFormGroup.invalid &&
            this.cardboardUsers?.length > 0
          ) {
            this.completed.companyInformation = true;
            this.stepper.selected.completed = true;
            this.stepper.next();

            this.companyFormGroup.get("termsConditions").disable();
            this.companyFormGroup.get("privacyPolicy").disable();
          }

          if (response.cardboardRepresentatives?.length) {
            while (this.cardboardRepresentatives.length !== 0) {
              this.removeRepresentative(0);
            }

            response.cardboardRepresentatives.map((value: any) => {
              this.cardboardRepresentatives.push(
                this.formBuilder.group({
                  id: value.id,
                  name: [value.name, Validators.required],
                  lastName: [value.lastName, Validators.required],
                  title: [value.title, Validators.required],
                  businessTitle: [value.businessTitle, Validators.required],
                  email: [
                    value.email,
                    [
                      Validators.required,
                      Validators.pattern(
                        "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
                      ),
                    ],
                  ],
                  phone: [value.phone],
                  countryPhone: [value.countryPhone],
                })
              );
            });

            // if (!response.signature) {
            //   this.createNDA();
            // }
          }

          if (response.document) {
            this.tradeRegisterExcerpt = {
              fullName: response.document.document,
              displayName: response.document.fileName,
            };
          }

          this.signature = response.signature;

          if (this.cardboardRepresentatives?.length > 0 && response.document) {
            this.completed.cardboardRepresentatives = true;
            this.stepper.selected.completed = true;
            this.stepper.next();

            // if (this.completed.cardboardRepresentatives) {
            //   this.companyFormGroup.get("name").disable();
            //   this.companyFormGroup.get("vat").disable();
            //   this.companyFormGroup.get("legalForm").disable();
            //   this.companyFormGroup.get("comment").disable();
            //   this.companyFormGroup.get("country").disable();
            //   this.companyFormGroup.get("city").disable();
            //   this.companyFormGroup.get("state").disable();
            //   this.companyFormGroup.get("street").disable();
            //   this.companyFormGroup.get("number").disable();
            //   this.companyFormGroup.get("postalCode").disable();
            //   this.companyFormGroup.get("contact").disable();
            //   this.companyFormGroup.get("termsConditions").disable();
            //   this.companyFormGroup.get("privacyPolicy").disable();
            //   this.companyFormGroup.get("countryPhone").disable();
            // }
          }
        });
    } else {
      while (this.cardboardUsers.length !== 0) {
        this.cardboardUsers.removeAt(0);
      }

      this.addUserToList();
    }
  }

  initVat() {
    this.vatValidationObject = {
      valid: null,
      company_address: "",
      company_name: "",
      country_code: "",
      database: "",
      format_valid: false,
      query: "",
      vat_number: "",
    };
  }

  hasOneDigit(str: string) {
    const oneDigitPattern = new RegExp("(?=.*?[0-9])");
    return oneDigitPattern.test(str);
  }

  getNationalities() {
    this.countryService.getAllNationalities().subscribe((response: any) => {
      this.Nationalities = response;
    });
  }

  validateVatNumber() {
    const vat = this.companyFormGroup.controls["vat"].value;

    if (!vat) {
      return;
    }

    const reVAT = new RegExp(
      "^(AE[0-9]{8}|ATU[0-9]{8}|BE[0-9]{10}|BG[0-9]{9,10}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL|GR[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9,A-H,J-N,P-Z]{2}[0-9]{9}|GB[0-9]{9}|HR[0-9]{11}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9,10}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10}|(CH|CHE)(d{6}|d{9})(MWST|TVA|IVA))$"
    );
    const isVAT = reVAT.test(vat);
    if (isVAT) {
      this.vatValidationObject.valid = true;
      return;
      //Temporaly disable endoint validation
      // this.vatService.getVatByNumber(vat).subscribe((response: any) => {
      //   if (response.status === 200) {
      //     if (!response.data) {
      //       this.vatValidation.validateVAT(vat).subscribe((vatLayer: any) => {
      //         this.vatValidationObject = vatLayer;

      //         if (vatLayer.valid === true) {
      //           this.saveValidVAT(vatLayer);
      //         }
      //       });
      //       return;
      //     }

      //     this.vatValidationObject = {
      //       valid: true,
      //       format_valid: true,
      //       country_code: response.data.countryCode,
      //       vat_number: response.data.vatNumber,
      //       company_name: response.data.companyName,
      //       company_address: response.data.companyAddress,
      //       database: "",
      //       query: "",
      //     };
      //   } else {
      //     Swal.fire({
      //       title: `${this.translate.instant("General_Error")}`,
      //       text: response.errors.join(","),
      //       icon: "error",
      //     });
      //   }
      // });
    } else {
      this.vatValidationObject.valid = false;
      return;
    }
  }

  saveValidVAT(vat: any) {
    const validVat = {
      companyName: vat.company_name,
      companyAddress: vat.company_address,
      vatNumber: vat.vat_number,
      countryCode: vat.country_code,
    };

    this.vatService.saveVat(validVat).subscribe((response: any) => {
      if (response.status === 200) {
      } else {
        var id = Sentry.captureMessage("Error saving Vat");
        Swal.fire({
          title: `${this.translate.instant("General_Error")}`,
          text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
          icon: "error",
        });
      }
    });
  }

  smoothScroll(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;

      if (pos > 0) {
        window.scrollTo(10, pos - 10); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  onSubmit(): void {
    if (
      this.cardboardUsers.length < 1 ||
      !this.cardboardUsers.value[0]?.name ||
      !this.cardboardUsers.value[0]?.lastName ||
      !this.cardboardUsers.value[0]?.email
    ) {
      Swal.fire({
        title: `${this.translate.instant("SCALE_MissingDataTitle")}`,
        text: `${this.translate.instant("SCALE_MissingUserDetails")}`,
        icon: "warning",
        timer: 4000,
      });

      return;
    }

    if (this.companyFormGroup.invalid) {
      return;
    }

    const company = Object.assign({}, this.companyFormGroup.value);
    company.country = "Italy";
    company.cardboardContacts = [company.contact];

    if (!company.id) {
      this.cardboardService
        .companyRegistration(company)
        .subscribe((companyId) => {
          if (companyId) {
            Swal.fire({
              title: `${this.translate.instant("General_Created")}`,
              text: `${this.translate.instant("CRM_Company_Sucess_Created")}`,
              icon: "success",
              timer: 3000,
            }).then(() => {
              this._router.navigate([`/register/${company.vat}/${companyId}`]);

              this.completed.companyInformation = true;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
          }
        });
    } else {
      this.cardboardService
        .updateCardboardCompany(company)
        .subscribe((updated: boolean) => {
          if (updated) {
            Swal.fire({
              title: `${this.translate.instant("General_Updated")}`,
              text: `${this.translate.instant("CRM_Company_Sucess_Updated")}`,
              icon: "success",
              timer: 3000,
            }).then(() => {
              // this._router.navigate([`/register/${company.vat}/${company.id}`]);

              this.completed.companyInformation = true;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
          }
        });
    }
  }

  showTermsConditions(): void {
    this._dialog.open(DialogTermsConditionsComponent, {
      width: "900px",
      disableClose: true,
      data: {
        withLabel: true,
        label: "Cardboard_TermsOfUse_Content",
      },
    });
  }

  showPrivacyPolicy(): void {
    this._dialog.open(DialogPrivacyPolicyComponent, {
      width: "980px",
      disableClose: true,
      data: {
        withLabel: true,
        label: "Cardboard_Privacy_Content",
      },
    });
  }

  addUserToList(): void {
    const usersCount = this.cardboardUsers.length;

    if (usersCount < this.userAccountsLimit) {
      this.cardboardUsers.push(
        this.formBuilder.group({
          id: 0,
          name: ["", Validators.required],
          lastName: ["", Validators.required],
          title: ["", Validators.required],
          email: [
            "",
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ],
          ],
        })
      );
    } else {
      Swal.fire({
        title: `${this.translate.instant("SCALE_AmountLimit")}`,
        text: `${this.translate.instant("SCALE_UsersLimitMessage", {
          value: this.userAccountsLimit,
        })}`,
        icon: "warning",
        timer: 4000,
      });
    }
  }

  removeCardboardUserElement(user: any, index: number) {
    if (!user) {
      return;
    }

    Swal.fire({
      title: `${this.translate.instant("General_Delete")}`,
      text: `${this.translate.instant("General_Delete_Confirmation", {
        value: this.translate.instant("General_User"),
      })}`,
      confirmButtonText: `${this.translate.instant("General_Confirm")}`,
      cancelButtonText: `${this.translate.instant("General_Cancel")}`,
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        if (user.id) {
          this.cardboardService
            .deleteUser({ vat: this.vat, userId: user.id })
            .subscribe((value: boolean) => {
              if (value) {
                this.cardboardUsers.removeAt(index);

                Swal.fire({
                  title: `${this.translate.instant("General_Deleted")}`,
                  text: `${this.translate.instant("General_Delete_Success")}`,
                  icon: "success",
                  timer: 3000,
                });
              }
            });
        } else {
          this.cardboardUsers.removeAt(index);

          Swal.fire({
            title: `${this.translate.instant("General_Deleted")}`,
            text: `${this.translate.instant("General_Delete_Success")}`,
            icon: "success",
            timer: 3000,
          });
        }
      }
    });
  }

  get cardboardUsers(): FormArray {
    return this.companyFormGroup.get("cardboardUsers") as FormArray;
  }

  addRepresentative(): void {
    if (this.cardboardRepresentatives.length < 2) {
      this.cardboardRepresentatives.push(
        this.formBuilder.group({
          name: ["", Validators.required],
          lastName: ["", Validators.required],
          title: ["", Validators.required],
          businessTitle: ["", Validators.required],
          email: [
            "",
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ],
          ],
          phone: ["", Validators.required],
          countryPhone: ["+39", Validators.required],
        })
      );
    } else {
      Swal.fire({
        title: `${this.translate.instant("SCALE_AmountLimit")}`,
        text: `${this.translate.instant("SCALE_RepresentativesLimitMessage", {
          value: 3,
        })}`,
        icon: "warning",
      });
    }
  }

  removeRepresentative(index: number) {
    this.cardboardRepresentatives.removeAt(index);
  }

  get cardboardRepresentatives(): FormArray {
    return this.uploadContractFormGroup.get(
      "cardboardRepresentatives"
    ) as FormArray;
  }

  uploadContract(): void {
    const form = Object.assign({}, this.uploadContractFormGroup.value);
    form.file = this.file;

    if (this.file) {
      this.cardboardService
        .uploadDocumentToCompany(
          this.companyFormGroup.value.id,
          "trade_register_excerpt",
          this.file
        )
        .subscribe((value: any) => {
          if (value) {
            this.addRepresentatives();
          }
        });
    } else {
      this.addRepresentatives();
    }
  }

  addRepresentatives(): void {
    const form = Object.assign({}, this.uploadContractFormGroup.value);
    form.id = this.companyFormGroup.value.id;

    this.cardboardService
      .setRepresentatives(form)
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translate.instant("General_Saved"),
            text: this.translate.instant("General_AddRepresentativesMessage"),
            icon: "success",
            timer: 4000,
          });

          if (this.file && form.cardboardRepresentatives?.length > 0) {
            window.location.reload();
          }
        }
      });
  }

  downloadContract(): void {
    let url = `${environment.API_URL}/documents/mandato_modelo_576.pdf`;
    window.open(url, "_blank")?.focus();
  }

  handleFile(event: any): void {
    this.file = event.target.files[0];
  }

  downloadUploadedContract(): void {
    let url = `${environment.API_URL}/companies/${this.companyFormGroup.value.id}/${this.updateFile.fullName}`;
    window.open(url, "_blank")?.focus();
  }

  createNDA(): void {
    this.cardboardService
      .createCardboardNDA({ vat: this.vat })
      .subscribe((response: any) => {
        if (response) {
          Swal.fire({
            title: this.translate.instant("General_Contract"),
            text: this.translate.instant("General_CreateContractMessage"),
            icon: "success",
            timer: 4000,
          });

          if (response.documents) {
            const document = response.documents
              .filter(
                (document: any) =>
                  document.documentType === "trade_register_excerpt"
              )
              .at(-1);

            if (document) {
              this.tradeRegisterExcerpt = document;
              this.completed.cardboardRepresentatives = true;
            }
          }

          this.signature = response.signature;

          if (!this.completed.cardboardRepresentatives) {
            this.completed.cardboardRepresentatives = true;
            this.stepper.selected.completed = true;
            this.stepper.next();
          }
        }
      });
  }

  downloadTradeRegisterExcerpt(): void {
    let url = `${environment.API_URL}/companies/${this.companyFormGroup.value.id}/${this.tradeRegisterExcerpt?.fullName}`;
    window.open(url, "_blank")?.focus();
  }

  removeLegalFormFromCompanyName(): void {
    let company = this.companyFormGroup.value.name;

    if (!company) {
      return;
    }

    if (this.legalForms?.length < 1) {
      return;
    }

    let split = company.split(" ");

    if (split?.length < 1) {
      return;
    }

    split
      .filter((value: any) => value)
      .map((value: string) => {
        const find = this.legalForms.filter((legalForm: any) =>
          legalForm.name.toLowerCase().includes(value.toLowerCase())
        )[0];

        if (find) {
          company = company.replaceAll(value, "").trim();

          this.companyFormGroup.patchValue({
            name: company,
          });
        }
      });
  }

  getUploadedFiles(files: any): void {
    if (!files) {
      return;
    }

    this.file = files[0]?.file;
  }

  removeCharacters(event: any): void {
    let value = event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/\D/g, "");

    if (event.srcElement.id == "vatInput") {
      this.companyFormGroup.patchValue({
        vat: value,
      });
    } else if (event.srcElement.id == "postalCodeInput") {
      this.companyFormGroup.patchValue({
        postalCode: value,
      });
    }
  }

  setValueToFirstUser(type: string): void {
    switch (type) {
      case "title":
        this.cardboardUsers.at(0).patchValue({
          title: this.ContactTitle.value,
        });

        break;

      case "name":
        this.cardboardUsers.at(0).patchValue({
          name: this.ContactName.value,
        });

        break;

      case "lastName":
        this.cardboardUsers.at(0).patchValue({
          lastName: this.ContactLastName.value,
        });

        break;

      case "email":
        this.cardboardUsers.at(0).patchValue({
          email: this.ContactEmail.value,
        });

        break;
    }
  }

  setLegalFormList(): void {
    this.legalForms = [];

    if (!this.companyFormGroup.value.country) {
      return;
    }

    this.legalForms = this.companyFormGroup.value.country.legalForms;
  }
}
