<h6 class="form-divider">
    <fa-icon icon="user-tie"></fa-icon> {{'General_Representative' | translate }}
</h6>

<form [formGroup]="representativeFormGroup">
    <div formGroupName="Representative" class="row" *ngIf="OnlyNaturalPerson == false">
        <div class="col">
            <div class="form-group">
                <label class="label-control">{{'General_IsNaturalPerson' | translate }}</label>
                <br />

                <div class="pretty p-switch p-toggle p-fill">
                    <input type="checkbox" formControlName="IsNaturalPerson" />
                    <div class="state p-success p-on">
                        <label>{{'General_Yes' | translate | titlecase}}</label>
                    </div>
                    <div class="state p-danger p-off">
                        <label>No</label>
                        <!-- <label>{{'General_No' | translate | titlecase}}</label> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div formGroupName="Representative" *ngIf="representativeFormGroup.value.Representative.IsNaturalPerson == true">
        <div class="row">
            <div class="col-md-3" [hidden]="OnlyNaturalPerson == true">
                <div class="form-group">
                    <div *ngIf="OnlyNaturalPerson == true">
                        <label class="label-control">{{'General_NameLastName' | translate}}</label>
                    </div>
                    <div *ngIf="OnlyNaturalPerson == false">
                        <label class="label-control">{{'General_NameLastNameRepresentative' | translate}}</label>
                    </div>
                    <input type="text" class="form-control" formControlName="RepresentativeName"
                        placeholder="{{'General_NameLastName' | translate }}" [ngClass]="{'is-valid': !representativeFormGroup.get('Representative').get('RepresentativeName').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeName').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeName').dirty),
                                 'is-invalid': representativeFormGroup.get('Representative').get('RepresentativeName').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeName').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeName').dirty)}" />
                    <!-- <div *ngIf="representativeFormGroup.get('Representative').get('RepresentativeName').invalid &&
                                (representativeFormGroup.get('Representative').get('RepresentativeName').dirty ||
                                representativeFormGroup.get('Representative').get('RepresentativeName').touched) &&
                                representativeFormGroup.get('Representative').get('RepresentativeName').errors.required">
                        The name is required
                    </div> -->
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-control">{{'General_Title' | translate }}</label>

                    <input type="text" class="form-control" formControlName="RepresentativeTitle"
                        placeholder="{{'General_Title' | translate | titlecase}}"
                        [ngClass]="{'is-valid': !representativeFormGroup.get('Representative').get('RepresentativeTitle').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeTitle').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeTitle').dirty),
                                 'is-invalid': representativeFormGroup.get('Representative').get('RepresentativeTitle').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeTitle').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeTitle').dirty)}" />
                    <!-- <div *ngIf="representativeFormGroup.get('Representative').get('RepresentativeTitle').invalid &&
                            (representativeFormGroup.get('Representative').get('RepresentativeTitle').dirty ||
                            representativeFormGroup.get('Representative').get('RepresentativeTitle').touched) &&
                            representativeFormGroup.get('Representative').get('RepresentativeTitle').errors.required">
                        The title is required
                    </div> -->
                </div>
            </div>

            <div class="col-md-3" *ngIf="OnlyNaturalPerson == false">
                <div class="form-group">
                    <label class="label-control">{{'General_Id_Number' | translate | uppercase}}</label>
                    <input type="text" class="form-control" formControlName="DNI"
                        placeholder="{{'General_Id_Number' | translate | uppercase}}"
                        [ngClass]="{'is-valid': !representativeFormGroup.get('Representative').get('DNI').invalid
                                 && (representativeFormGroup.get('Representative').get('DNI').touched
                                 || representativeFormGroup.get('Representative').get('DNI').dirty),
                                 'is-invalid': representativeFormGroup.get('Representative').get('DNI').value == ''
                                 && (representativeFormGroup.get('Representative').get('DNI').touched
                                 || representativeFormGroup.get('Representative').get('DNI').dirty)}" />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-control">{{'General_Nationality' | translate | titlecase}} <span class="required-star">*</span></label>

                    <ng-select [clearable]="true" [searchable]="true" [items]="Nationalities" bindLabel="name"
                        bindValue="name" formControlName="RepresentativeNationality" appendTo="body"
                        [virtualScroll]="true"
                        [ngClass]="{'is-valid': !representativeFormGroup.get('Representative').get('RepresentativeNationality').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeNationality').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeNationality').dirty),
                                 'is-invalid': representativeFormGroup.get('Representative').get('RepresentativeNationality').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeNationality').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeNationality').dirty)}"></ng-select>

                    <!-- <input type="text" class="form-control" formControlName="RepresentativeTitle" placeholder="{{'General_Nationality' | translate | titlecase}}"
                    [ngClass]="{'is-valid': !representativeFormGroup.get('Representative').get('RepresentativeNationality').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeNationality').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeNationality').dirty),
                                 'is-invalid': representativeFormGroup.get('Representative').get('RepresentativeNationality').invalid
                                 && (representativeFormGroup.get('Representative').get('RepresentativeNationality').touched
                                 || representativeFormGroup.get('Representative').get('RepresentativeNationality').dirty)}" /> -->

                    <!-- <div *ngIf="representativeFormGroup.get('Representative').get('RepresentativeTitle').invalid &&
                            (representativeFormGroup.get('Representative').get('RepresentativeTitle').dirty ||
                            representativeFormGroup.get('Representative').get('RepresentativeTitle').touched) &&
                            representativeFormGroup.get('Representative').get('RepresentativeTitle').errors.required">
                        The title is required
                    </div> -->

                </div>
            </div>
        </div>

        <!-- Upload Natural person ID -->
        <!-- <div class="row">
            <div class="col-12">
                <h6 class="form-divider">
                    <fa-icon icon="cloud-upload-alt"></fa-icon>
                    {{'CRM_Person_UploadId' | translate }} <b
                        *ngIf="representativeFormGroup.get('Representative').get('RepresentativeName').value"
                        style="color: #0497ff;">({{representativeFormGroup.get('Representative').get('RepresentativeName').value}})</b>
                </h6>

                <app-file-upload [companyId]="companyId" #fileUploadRepresentativeID
                                 [documents]="Documents"
                                 [documentType]="'Representative-ID'"
                                 [docLimit]="2"
                                 ></app-file-upload>
            </div>
        </div> -->

        <!-- Beginning power document -->
        <!-- <h6 class="form-divider">
            <fa-icon icon="file-contract"></fa-icon> {{ 'CRM_PowerNotaryTitle' | translate }}
        </h6>
        <div class="row" >
            <div class="col-md-3">
                <div class="form-group" formGroupName="Notary">
                    <label
                        class="label-control">{{ 'CRM_NotaryFrom' | translate }}</label>
                    <input type="text" class="form-control"  formControlName="State"
                    [ngClass]="{'is-invalid':
                                NotaryState.invalid &&
                                NotaryState.errors.required &&
                                (NotaryState.touched ||NotaryState.dirty),
                            'is-valid':
                                NotaryState.valid &&
                                (NotaryState.touched ||NotaryState.dirty)
                            }">

                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName="Notary">
                    <label class="label-control">{{'General_PowerDate' | translate}}</label>

                    <div class="input-group mb-3">
                        <input type="text" class="form-control"
                                [matDatepicker]="RegistrationDate"
                                formControlName="RegistrationDate"
                                [ngClass]="{'is-valid': NotaryRegistrationDate.valid
                                 && (NotaryRegistrationDate.touched
                                 || NotaryRegistrationDate.dirty),
                                 'is-invalid': NotaryRegistrationDate.invalid
                                 && (NotaryRegistrationDate.touched
                                 || NotaryRegistrationDate.dirty)}">
                        <mat-datepicker-toggle [for]="RegistrationDate"></mat-datepicker-toggle>

                        <div class="input-group-append">
                            <mat-datepicker #RegistrationDate></mat-datepicker>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName="Notary">
                    <label
                        class="label-control">{{'CRM_NotaryName' | translate}}</label>
                    <input type="text" class="form-control"
                        formControlName="Name" autocomplete="off"
                        [ngClass]="{'is-invalid':
                                            NotaryName.invalid &&
                                            NotaryName.errors.required &&
                                            (NotaryName.touched ||NotaryName.dirty),
                                        'is-valid':
                                            NotaryName.valid &&
                                            (NotaryName.touched ||NotaryName.dirty)
                                        }"
                        />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName="Notary">
                    <label
                        class="label-control">{{'CRM_NotaryRegistrationNumber' | translate}}</label>
                    <input type="text" class="form-control"
                        formControlName="RegistrationNumber" autocomplete="off"
                        [ngClass]="{'is-invalid':
                        NotaryRegistrationNumber.invalid &&
                        NotaryRegistrationNumber.errors.required &&
                        (NotaryRegistrationNumber.touched ||NotaryRegistrationNumber.dirty),
                    'is-valid':
                        NotaryRegistrationNumber.valid &&
                        (NotaryRegistrationNumber.touched ||NotaryRegistrationNumber.dirty)
                   }"/>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h6 class="form-divider">
                    <fa-icon icon="cloud-upload-alt"></fa-icon>
                    {{'CRM_CompanyNotaryUpload' | translate }} <b
                        *ngIf="representativeFormGroup.get('Representative').get('CompanyName').value"
                        style="color: #0497ff;">({{representativeFormGroup.get('Representative').get('RepresentativeName').value}})</b>
                </h6>

                <app-file-upload [companyId]="companyId" #fileUploadRepresentativeUploadReresentationNotary
                                 [documents]="Documents"
                                 [documentType]="'Representation-Notary'"
                                 [docLimit]="1"></app-file-upload>
            </div>
        </div> -->
         <!-- End power document -->
    </div>

    <div formGroupName="Representative" *ngIf="representativeFormGroup.value.Representative.IsNaturalPerson == false">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="label-control">{{'General_Company_Name' | translate}} <span class="required-star">*</span></label>
                    <input type="text" class="form-control"
                        placeholder="{{'General_Company_Name' | translate}}" formControlName='CompanyName'
                        [ngClass]="{'is-valid': !representativeFormGroup.get('Representative').get('CompanyName').invalid
                                 && (representativeFormGroup.get('Representative').get('CompanyName').touched
                                 || representativeFormGroup.get('Representative').get('CompanyName').dirty),
                                 'is-invalid': representativeFormGroup.get('Representative').get('CompanyName').invalid
                                 && (representativeFormGroup.get('Representative').get('CompanyName').touched
                                 || representativeFormGroup.get('Representative').get('CompanyName').dirty)}" />
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label class="label-control">{{'General_VAT' | translate | uppercase}} <span class="required-star">*</span></label>
                    <input type="text" class="form-control" placeholder="{{'General_VAT' | translate | uppercase}}"
                        formControlName='VAT' (change)="validateVatNumber()"
                        [ngClass]="{'is-valid': VatValidation && VatValidation.valid, 'is-invalid': VatValidation && VatValidation.valid == false}" />
                    <div
                        [ngClass]="{'valid-feedback': VatValidation && VatValidation.valid, 'invalid-feedback': VatValidation && VatValidation.valid == false}">
                        <div [ngSwitch]="VatValidation.valid">
                            <span *ngSwitchCase="true">
                                {{'CRM_Validation_VAT_Name' | translate }}
                                '{{VatValidation.company_name}}'
                            </span>

                            <span *ngSwitchCase="false">
                                {{'CRM_Validation_VAT_Name_Invalid' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Regiter document upload -->
<!--
        <div class="row">
            <div class="col-12">
                <h6 class="form-divider">
                    <fa-icon icon="cloud-upload-alt"></fa-icon>
                    {{'CRM_Company_UploadDocuments' | translate | titlecase}} <b
                        *ngIf="representativeFormGroup.get('Representative').get('CompanyName').value"
                        style="color: #0497ff;">({{representativeFormGroup.get('Representative').get('CompanyName').value}})</b>
                </h6>

                <app-file-upload [companyId]="companyId" [documents]="Documents" [docLimit]="1"></app-file-upload>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-3">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control"> {{'General_Country' | translate }} <span class="required-star">*</span></label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="LangCountries" bindLabel="name"
                        formControlName='Country' [virtualScroll]="true"
                        (change)="getStatesByCountry(); getLegalFormsByCountry(); getRegistersByCountry()"
                        appendTo="body" [ngClass]="{'is-valid': !Country?.invalid
                                 && Country?.touched
                                 || Country?.dirty,
                                 'is-invalid': Country?.invalid
                                 && Country?.touched
                                 || Country?.dirty}">
                    </ng-select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_States' | translate }}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="StatesRepresentatives" bindLabel="name"
                        [virtualScroll]="true" bindValue="name" formControlName='State' (change)="getCitiesByState()"
                        appendTo="body">
                    </ng-select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_Cities' | translate }} <span class="required-star">*</span></label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="CitiesRepresentatives" bindLabel="name"
                        [virtualScroll]="true" bindValue="name" formControlName='City' (change)="getPostalCodesByCity()"
                        appendTo="body">
                    </ng-select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_Postal_Code' | translate }}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="PostalCodesRepresentatives"
                        [virtualScroll]="true" bindLabel="code" bindValue="code" formControlName='PostalCode'
                        appendTo="body"></ng-select>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_StreetNumber' | translate }}</label>
                    <input type="text" class="form-control" formControlName='StreetNumber' />
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label class="label-control">{{'General_Legal_Form' | translate }} <span class="required-star">*</span></label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="LegalFormsRepresentatives"
                        bindLabel="name" formControlName='LegalForm' appendTo="body"></ng-select>
                </div>
            </div>
        </div>

        <!-- Register Section -->
        <!-- <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="label-control">{{'General_Register' | translate | titlecase}}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="Registers" bindLabel="register"
                        (change)="getRegistrationAuthorities()" bindValue="register" formControlName="Register">
                    </ng-select>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <label class="label-control">{{'General_RegistrationAuthority' | translate | titlecase}}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="Authorities" bindLabel="authority"
                        bindValue="authority" [virtualScroll]="true" formControlName="RegistrationAuthority">
                    </ng-select>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <label class="label-control">{{'General_RegistrationNumber' | translate | titlecase}}</label>
                    <input type="text" class="form-control"
                        placeholder="{{'General_RegistrationNumber' | translate | titlecase}}"
                        formControlName="RegistrationNumber" autocomplete="off" />
                </div>
            </div>
        </div> -->
        <!-- end register section -->
    </div>

    <div class="row">
        <div class="col-12 mb-2 mt-2">
            <button type="button" class="btn btn-xs btn-success" (click)="saveRepresentative()"
                *ngIf="OnlyNaturalPerson == false || (OnlyNaturalPerson == true && Representatives.length < 1) || representativeFormGroup.value.Representative.Index !== ''">
                <fa-icon [icon]="['fas', 'plus-circle']"></fa-icon>

                <span *ngIf="representativeFormGroup.value.Representative.Index === ''">
                    {{'General_AddRepresentative' | translate }}
                </span>

                <span *ngIf="representativeFormGroup.value.Representative.Index !== ''">
                    {{'General_UpdateRepresentative' | translate }}
                </span>
            </button>

            <button type="button" class="btn btn-xs btn-secondary ml-1" (click)="cleanRepresentative()"
                *ngIf="OnlyNaturalPerson == false">
                <fa-icon icon="eraser"></fa-icon>
                {{'General_CleanRepresentative' | translate }}
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table table-sm table-hover table-bordered table-striped">
                    <thead class="thead-dark">
                        <th scope="col">#</th>
                        <th scope="col">
                            <span  *ngIf="representativeFormGroup.value.Representative.IsNaturalPerson == true">{{'General_NameLastName' | translate }}</span>
                            <span  *ngIf="representativeFormGroup.value.Representative.IsNaturalPerson == false">{{'General_Name' | translate }}</span>
                        </th>
                        <th scope="col">
                            <span *ngIf="OnlyNaturalPerson == false">
                                {{'General_IsNaturalPerson' | translate }}
                            </span>

                            <span *ngIf="OnlyNaturalPerson == true">
                                {{'General_Nationality' | translate }}
                            </span>
                        </th>
                        <th></th>
                    </thead>

                    <tbody formArrayName="Representatives">
                        <tr>
                            <td class="text-center" colspan="4" *ngIf="Representatives.length < 1">
                                {{'CRM_Validations_Company_TableRepresentatives' | translate }}
                            </td>
                        </tr>
                        <tr *ngFor="let representative of Representatives.value; let i=index" [formGroupName]="i">
                            <th scope="row">{{i + 1}}</th>
                            <td>
                                <span formGroupName="RepresentativeName">{{representative.RepresentativeName}}</span>
                            </td>
                            <td>
                                <span
                                    *ngIf="OnlyNaturalPerson == false">{{representative.IsNaturalPerson == true ? 'Yes' : 'No'}}</span>
                                <span
                                    *ngIf="OnlyNaturalPerson == true">{{representative.RepresentativeNationality}}</span>
                            </td>

                            <td class="text-center">
                                <button type="button" class="btn btn-xs btn-rounded btn-info mr-1"
                                    title="{{'General_Update' | translate }}" (click)="updateRepresentative(i)">
                                    <fa-icon icon="pencil-alt"></fa-icon>
                                </button>

                                <button type="button" class="btn btn-xs btn-rounded btn-secondary mr-1"
                                    title="Add Representative" (click)="addRepresentativeToRepresentative(i)"
                                    [disabled]="representative.IsNaturalPerson == true">
                                    <fa-icon [icon]="['fas','user-tie']"></fa-icon>
                                </button>

                                <button type="button" class="btn btn-xs btn-rounded btn-danger"
                                    title="{{'General_Remove' | translate }}" (click)="removeRepresentative(i)">
                                    <fa-icon icon="trash"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</form>
