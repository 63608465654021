<footer class="footer-area">
  <div class="copyright-area style-03">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="copyright-area-inner">
            {{'General_Footer_CDC_CR' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
