import { DialogTermsConditionsComponent } from '../../dialogs/dialog-terms-conditions/dialog-terms-conditions.component';
import { DialogCompanyHistoryComponent } from '../../dialogs/dialog-company-history/dialog-company-history.component';
import { AddRepresentativeComponent } from '../../representatives/add-representative/add-representative.component';
import { DialogPrivacyPolicyComponent } from '../../dialogs/dialog-privacy-policy/dialog-privacy-policy.component';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Component, OnInit, NgZone, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { IRegisterAuthorities } from 'src/app/models/parameters/IRegisterAuthorities';
import { IVatValidationObject } from 'src/app/models/company/IVatValidationObject';
import { ViewTitleService } from 'src/app/services/viewTitle/view-title.service';
import { IVatValidation } from 'src/app/services/vat-validation/vat-validation';
import { ITranslationService } from 'src/app/services/translations/translation';
import { IComponentView } from 'src/app/models/navigation/IComponentView';
import { IRepresentative } from 'src/app/models/company/IRepresentative';
import { INationality } from 'src/app/models/parameters/INationality';
import { IRegisterService } from 'src/app/services/register/register';
import { IProjectService } from 'src/app/services/projects/project';
import { IPostalCode } from 'src/app/models/parameters/IPostalCode';
import { ICountryService } from 'src/app/services/country/country';
import { ICompanyService } from 'src/app/services/company/company';
import { ILegalForm } from 'src/app/models/parameters/ILegalForm';
import { IRegister } from 'src/app/models/parameters/IRegister';
import { IHelperService } from 'src/app/services/helper/helper';
import { ICountry } from 'src/app/models/parameters/ICountry';
import { ICompany } from 'src/app/models/company/ICompany';
import { IState } from 'src/app/models/parameters/IState';
import { Router, ActivatedRoute } from '@angular/router';
import { ICity } from 'src/app/models/parameters/ICity';
import { IVATService } from 'src/app/services/vat/vat';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MapsAPILoader } from '@agm/core';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {
  public company: ICompany;
  public companyFormGroup: FormGroup;
  public SeparateDialCode = false;
  public Countries: ICountry[];
  public Nationalities: INationality[];
  public LangCountries: any[];
  public States: IState[];
  public StatesRepresentatives: IState[];
  public StatesDelivery: IState[];
  public Cities: ICity[];
  public CitiesRepresentatives: ICity[];
  public CitiesDelivery: ICity[];
  public LegalForms: ILegalForm[];
  public LegalFormsRepresentatives: ILegalForm[];
  public PostalCodes: IPostalCode[];
  public PostalCodesRepresentatives: IPostalCode[];
  public PostalCodesDelivery: IPostalCode[];
  public Registers: IRegister[];
  public VatValidation: IVatValidationObject;
  public VatValidationRepresentative: IVatValidationObject;
  public Representative: IRepresentative;
  public idCompany: string;
  public actualCartel: string;
  public Authorities: IRegisterAuthorities[];
  public ActualLanguage: any;
  public formTypeSelection: string;
  public MilkYears: any;
  public showSummaryErrors = false;
  public RepresentativeChildForm: FormGroup;
  public NotaryFromList: any[];
  public representativeValidationList: string[];

  private _componentView: IComponentView = {
    title: 'Company',
    breadCrumbsItems: []
  };
  public hasExtraDeliveryAddress = false;
  private project: any;

  state$: Observable<object>;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  public cartelName = 'Test cartel';
  public oneDigitPattern = new RegExp('(?=.*?[0-9])');
  public companyCount: number;

  @ViewChild('addRepresentative') addRepresentative: AddRepresentativeComponent;
  @ViewChild('search', { static: false }) set content(content: ElementRef) {
    if (content) {
      this.searchElementRef = content;
    }
  }
  public searchElementRef: ElementRef;

  constructor(
    private _formBuilder: FormBuilder,
    private _companyService: ICompanyService,
    private _countryService: ICountryService,
    private _viewTitleService: ViewTitleService,
    private _vatService: IVatValidation,
    private _registerService: IRegisterService,
    private _helperService: IHelperService,
    private _router: Router,
    public _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _projectService: IProjectService,
    private vatService: IVATService,
    public translate: TranslateService,
    private translationService: ITranslationService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.initCompanyRegistration();
    this.getProjectUrlParameter();
    this.getCompanyCount();

    this.translationService.getLanguage().subscribe((currentLang: string) => {
      this.buildContriesLang(currentLang);
    });

    this.createYearLists();
  }

  getCompanyCount() {
    this._companyService.companyCount().subscribe((response: any) => {
      this.companyCount = response;
    });
  }

  createYearLists() {
    let startYear = 2000;
    const lastYear = 2013;
    const years = [];

    years.push({
      label: 'Estimado (2000 - 2013)',
      value: 'Estimated'
    });

    while (startYear <= lastYear) {
      const value = startYear++;

      years.push({
        label: value,
        value: value.toString()
      });
    }

    this.MilkYears = years;
  }

  initGoogleMapsSearch() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();

      this.geoCoder = new google.maps.Geocoder();
      const options = {
        componentRestrictions: {
          country: 'es'
        }
      };

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, options);

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const info = {
            Country: '',
            State: '',
            City: '',
            PostalCode: '',
            Street: '',
            Number: ''
          };

          place.address_components.map((component: any) => {
            const country = component.types.filter((i: string) => i === 'country')[0];
            if (country) {
              info.Country = component.long_name;
            }

            const state = component.types.filter((i: string) => i === 'administrative_area_level_1')[0];
            if (state) {
              info.State = component.long_name;
            }

            const city = component.types.filter((i: string) => i === 'administrative_area_level_2' || i === 'locality')[0];
            if (city) {
              info.City = component.long_name;
            }

            const postalCode = component.types.filter((i: string) => i === 'postal_code')[0];
            if (postalCode) {
              info.PostalCode = component.long_name;
            }

            const street = component.types.filter((i: string) => i === 'route')[0];
            if (street) {
              info.Street = component.long_name;
            }

            const streetNumber = component.types.filter((i: string) => i === 'street_number')[0];
            if (streetNumber) {
              info.Number = component.long_name;
            }
          });

          if (!this.LangCountries) {
            return;
          }

          const currentCountry = this.LangCountries.filter(c => c.name === info.Country)[0];
          const countryTranslation = this.Countries.filter(x => x.translations.filter((t: any) => t.name === info.Country));

          const translatedCountry = this.Countries.map(country => {
            if (country !== undefined) {
              const finded = country.translations.filter(t => t.name === info.Country).length > 0;

              if (finded) {
                return country;
              }
            }
          }).filter(i => i)[0];

          this.companyFormGroup.patchValue({
            SeatAddress: {
              Country: {
                name: translatedCountry.translations.filter(c => c.code === this.ActualLanguage)[0].name,
                value: translatedCountry,
              },
              State: info.State,
              City: info.City,
              PostalCode: info.PostalCode,
              StreetNumber: `${info.Street} ${info.Number}`
            }
          });

          // Load lists depending on the country selection
          this.getStatesByCountry();
          this.getCitiesByState();
          this.getLegalFormsByCountry();
          this.getRegistersByCountry();
          this.getPostalCodesByCity();

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getTranslationCountryValue(country: string) {
    if (!country) {
      return '';
    }

    const langCountries = this.LangCountries;

    if (!langCountries) {
      console.error(`There is no translation for ${country} country`);
      return;
    }

    const countryTranslation = this.LangCountries.filter(c => c.name === country)[0];

    if (!countryTranslation) {
      console.error(`There is no translation for ${country} country`);
      return;
    }

    return countryTranslation.value.name;
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  buildContriesLang(lang: string) {
    this.ActualLanguage = lang;

    if (!this.Countries || this.Countries.length < 1) {
      return;
    }

    this.LangCountries = this.Countries.map((country: ICountry) => {
      const translations = country.translations.filter(trans => trans.code === lang)[0];

      if (!translations) {
        return undefined;
      }

      return {
        name: translations['name'],
        value: country
      };
    }).filter(i => i !== undefined);

    const countryValue = this.companyFormGroup.controls['SeatAddress'].value.Country;

    if (countryValue) {
      const countryTranslation = countryValue.value.translations.filter(t => t.code === lang)[0];

      if (countryTranslation) {
        this.companyFormGroup.patchValue({
          SeatAddress: {
            Country: {
              name: countryTranslation.name,
              value: countryValue.value,
            },
          }
        });
      }
    }
  }

  getProjectUrlParameter(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      const projectId = params.get('id');

      if (projectId) {
        this.companyFormGroup.addControl('Projects', this._formBuilder.array([projectId]));
      }
    });
  }

  initCompanyRegistration(): void {
    this.idCompany = this._helperService.generateObjectId();
    this.setForm();

    this._componentView.title = '';
    this._componentView.breadCrumbsItems = [];

    this._viewTitleService.emitChange(this._componentView);

    this.initVat();
    this.getCountries();
    this.getNationalities();
  }

  initVat() {
    this.VatValidation = { valid: null, company_address: '', company_name: '', country_code: '', database: '', format_valid: false, query: '', vat_number: '' };
  }

  hasOneDigit(str: string) {
    return this.oneDigitPattern.test(str);
  }

  setForm() {
    this.companyFormGroup = this._formBuilder.group({
      UpdateId: [this.idCompany],
      Name: ['', [
        Validators.required,
        Validators.maxLength(100)
      ]],
      Email: ['', Validators.required],
      Phone: ['', Validators.required],
      VAT: ['', Validators.required],
      RepresentativeNationality: [''],
      NaturalPersonDocumentIds: this._formBuilder.array([]),
      SeatAddress: this._formBuilder.group({
        Country: ['', Validators.required],
        State: ['', Validators.required],
        City: ['', Validators.required],
        PostalCode: [''],
        StreetNumber: ['', Validators.required]
      }),
      LegalForm: [{}, Validators.required],
      Comment: [''],
      Register: [''],
      RegistrationAuthority: [''],
      RegistrationNumber: [''],
      Contact: this._formBuilder.group({
        Name: [''],
        Title: [''],
        Email: [''],
        Phone: ['']
      }),
      DeliveryAddresses: this._formBuilder.array([]),
      Documents: this._formBuilder.array([]),
      Representatives: this._formBuilder.array([]),
      FormTypeSelection: '',
      registrationType: 'general-form'
    });
  }

  assignRepresentatives(representativeList: FormArray) {
    if (representativeList) {
      // this.companyFormGroup.controls['Representatives'].reset(representativeList.value);
      // this.companyFormGroup.controls['Representatives'].setValue(this._formBuilder.array([]));

      representativeList.value.map((represent: any) => {
        if (represent.RepresentativeName != null) {

          // find and remove index of existing representative.
          const listRepresentativeNames = this.Representatives.value.map(rep => rep.Representative.RepresentativeName);
          const index = listRepresentativeNames.indexOf(represent.RepresentativeName);

          if (index >= 0) {
            this.Representatives.removeAt(index);
          }

          this.Representatives.push(this._formBuilder.group({
            Representative: this._formBuilder.group({
              RepresentativeName: [represent.RepresentativeName],
              RepresentativeTitle: [represent.RepresentativeTitle],
              RepresentativeNationality: [represent.RepresentativeNationality],
              IsNaturalPerson: [represent.IsNaturalPerson],
              VAT: [represent.VAT],
              DNI: [represent.DNI],
              Address: [represent.Address],
              LegalForm: [represent.LegalForm],
              Representatives: [represent.Representatives],
              Documents: [represent.Documents],
              Register: [represent.Register],
              RegistrationAuthority: [represent.RegistrationAuthority],
              RegistrationNumber: [represent.RegistrationNumber],
              Notary: [represent.Notary]
            })
          }));
        }
      });
    }
  }

  getCountries() {
    this._countryService.getCountries().subscribe((response: ICountry[]) => {
      this.Countries = response;

      this.buildContriesLang(this.translate.currentLang);
    });
  }

  getNationalities() {
    this._countryService.getAllNationalities().subscribe((response: any) => {
      this.Nationalities = response;
    });
  }

  toggleExtraDeliveryAddress() {
    this.hasExtraDeliveryAddress = !this.hasExtraDeliveryAddress;
    if (this.hasExtraDeliveryAddress === false) {
      this.companyFormGroup.controls['DeliveryAddresses'] = this._formBuilder.array([], Validators.required);//.reset();
    }
  }

  getLegalFormsByCountry(type = '') {
    let country = '';
    let legalForms = [];

    switch (type) {
      case 'representative':
        country = this.companyFormGroup.controls['Representative'].value.Address.Country;
        legalForms = this.Countries.filter(c => c.name === country)[0].legalForms || [];

        this.LegalFormsRepresentatives = legalForms;
        break;

      default:
        const countryValue = this.companyFormGroup.controls['SeatAddress'].value.Country;

        if (!countryValue) {
          this.companyFormGroup.patchValue({
            LegalForm: [{}]
          });

          this.LegalForms = [];
          return;
        }

        country = countryValue.value.name;
        legalForms = this.Countries.filter(c => c.name === country)[0].legalForms || [];

        this.LegalForms = legalForms;
        break;
    }
  }

  getStatesByCountry(type = '') {
    let country = '';
    let states = [];

    switch (type) {
      case 'representative':
        country = this.companyFormGroup.controls['Representative'].value.Address.Country;
        states = this.Countries.filter(c => c.name === country)[0].states || [];

        this.StatesRepresentatives = states;
        break;

      default:
        const countryValue = this.companyFormGroup.controls['SeatAddress'].value.Country;

        if (!countryValue) {
          this.States = [];
          this.Cities = [];
          this.PostalCodes = [];
          this.LegalForms = [];
          this.Registers = [];
          this.Authorities = [];
          this.NotaryFromList = [];

          this.renderer.setProperty(this.searchElementRef.nativeElement, 'value', '');

          this.companyFormGroup.patchValue({
            SeatAddress: {
              Country: '',
              State: '',
              City: '',
              PostalCode: '',
              StreetNumber: ''
            },
            LegalForm: [{}],
            Register: [{}],
            RegistrationAuthority: [{}]
          });

          return;
        }

        country = countryValue.value.name;
        states = this.Countries.filter(c => c.name === country)[0].states || [];

        this.States = states;
        break;
    }
  }

  getCitiesByCountry() {
    const country = this.companyFormGroup.controls['SeatAddress'].value.Country.value.name;

    if (!country) {
      this.Cities = [];
      this.PostalCodes = [];
      this.LegalForms = [];

      this.companyFormGroup.patchValue({
        SeatAddress: {
          Country: '',
          State: '',
          City: '',
          PostalCode: '',
        },
        LegalForm: [{}]
      });
      this.NotaryFromList = [];
      return;
    }

    const states = this.Countries.filter(c => c.name === country)[0].states || [];

    if (!states || states.length < 1) {
      this.Cities = [];
      this.NotaryFromList = [];
      return;
    }

    const cities = [];

    states.map(state => {
      state.cities.map(c => {
        cities.push(c);
      })
    });

    this.Cities = cities;
    this.NotaryFromList = cities;
  }

  setStateByCity() {
    this.companyFormGroup.patchValue({
      SeatAddress: {
        State: ''
      },
    });

    const country = this.companyFormGroup.controls['SeatAddress'].value.Country.name;
    const states = this.Countries.filter(c => c.name === country)[0].states || [];

    const city = this.companyFormGroup.controls['SeatAddress'].value.City;

    const state = [];

    states.map(st => {
      st.cities.map(c => {
        if (c.name === city) {
          state.push(st);
        }
      });
    });

    this.States = state;

    // Select State by City
    this.companyFormGroup.patchValue({
      SeatAddress: {
        State: state[0].name
      },
    });
  }

  getStatesByCountryDeliveryAddress(index: number) {
    const deliveryAddress = this.DeliveryAddresses.at(index);

    if (!deliveryAddress) {
      return;
    }

    const country = deliveryAddress.value.Country;

    if (!country) {
      return;
    }

    this.StatesDelivery = this.Countries.filter(c => c.name === country)[0].states || [];
  }

  getCitiesByState(type = '') {
    let state = '';
    let cities = [];

    switch (type) {
      case 'representative':
        state = this.companyFormGroup.controls['Representative'].value.Address.State;
        cities = this.StatesRepresentatives.filter(c => c.name === state)[0].cities || [];

        this.CitiesRepresentatives = cities;
        this.NotaryFromList = cities;
        break;

      default:
        state = this.companyFormGroup.controls['SeatAddress'].value.State;

        if (!state) {
          this.companyFormGroup.patchValue({
            SeatAddress: {
              State: '',
              City: '',
              PostalCode: '',
            }
          });

          this.Cities = [];
          this.PostalCodes = [];
          return;
        }

        cities = this.States !== undefined ? this.States.filter(c => c.name === state)[0].cities : [];

        // if (!cities) {
        //   cities = this.States !== undefined ? this.States.filter(c => c.translations === state)[0].cities : [];
        // }

        this.Cities = cities;
        this.NotaryFromList = cities;
        break;
    }
  }

  getCitiesByStateDeliveryAddress(index: number) {
    const deliveryAddress = this.DeliveryAddresses.at(index);
    const state = deliveryAddress.value.State;

    if (!state) {
      return;
    }

    this.CitiesDelivery = this.StatesDelivery.filter(c => c.name === state)[0].cities || [];
  }

  getPostalCodesByCity(type = '') {
    let city = '';
    let postalCodes = [];

    switch (type) {
      case 'representative':
        city = this.companyFormGroup.controls['Representative'].value.Address.City;
        postalCodes = this.CitiesRepresentatives.filter(c => c.name === city)[0].postalCodes || [];

        this.PostalCodesRepresentatives = postalCodes;
        break;

      default:
        city = this.companyFormGroup.controls['SeatAddress'].value.City;

        if (!city) {
          this.companyFormGroup.patchValue({
            SeatAddress: {
              City: '',
              PostalCode: '',
            }
          });

          this.PostalCodes = [];

          return;
        }

        postalCodes = this.Cities.filter(c => c.name === city)[0].postalCodes || [];

        this.PostalCodes = postalCodes;
        break;
    }
  }

  getPostalCodesByCityDeliveryAddress(index: number) {
    const deliveryAddress = this.DeliveryAddresses.at(index);
    const city = deliveryAddress.value.City;

    if (!city) {
      return;
    }

    this.PostalCodesDelivery = this.CitiesDelivery.filter(c => c.name === city)[0].postalCodes || [];
  }

  getRegisters() {
    this._registerService.getRegisters().subscribe(response => {
      this.Registers = response['data'];
    });
  }

  getRegistersByCountry() {
    const country = this.companyFormGroup.controls['SeatAddress'].value.Country.name;

    if (!country) {
      return;
    }

    const translationCountry = this.getTranslationCountryValue(country);

    this._registerService.getRegistersByCountry(translationCountry).subscribe((response: any) => {
      // const registerAuthorities = response && response.length > 0 ? response.map((register: any) => register.registerAuthorities).flat() : [];
      const registerAuthorities = response && response.length > 0 ? response.reduce((acc, val) => acc.concat(val.registerAuthorities), []) : [];

      this.Registers = registerAuthorities;
    });
  }

  getRegistrationAuthorities() {
    const register = this.companyFormGroup.controls['Register'].value;

    if (!register) {
      return;
    }

    const authorities = [];

    Object.assign([], this.Registers.filter((reg: any) => reg.register === register)).map(reg => {
      reg.authorities.map((auth: any) => {
        authorities.push(auth);
      });
    });

    this.Authorities = authorities;
  }

  validateVatNumber() {
    const vat = this.companyFormGroup.controls['VAT'].value;

    if (!vat) {
      return;
    }

    const reVAT = new RegExp('^(AE[0-9]{8}|ATU[0-9]{8}|BE[0-9]{10}|BG[0-9]{9,10}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL|GR[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9,A-H,J-N,P-Z]{2}[0-9]{9}|GB[0-9]{9}|HR[0-9]{11}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9,10}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10}|(CH|CHE)(\d{6}|\d{9})(MWST|TVA|IVA))$');
    const isVAT = reVAT.test(vat);
    if (isVAT) {
      this.vatService.getVatByNumber(vat).subscribe((response: any) => {
        if (response.status === 200) {
          if (!response.data) {
            this._vatService.validateVAT(vat).subscribe((vatLayer: any) => {
              this.VatValidation = vatLayer;

              if (vatLayer.valid === true) {
                this.saveValidVAT(vatLayer);
              }
            });

            return;
          }

          this.VatValidation = {
            valid: true,
            format_valid: true,
            country_code: response.data.countryCode,
            vat_number: response.data.vatNumber,
            company_name: response.data.companyName,
            company_address: response.data.companyAddress,
            database: '',
            query: ''
          };
        } else {
          Swal.fire({
            title: `${this.translate.instant('General_Error')}`,
            text: response.errors.join(','),
            icon: 'error'
          });
        }
      });
    } else {
      return;
    }
  }

  saveValidVAT(vat: any) {
    const validVat = {
      companyName: vat.company_name,
      companyAddress: vat.company_address,
      vatNumber: vat.vat_number,
      countryCode: vat.country_code
    };

    this.vatService.saveVat(validVat).subscribe((response: any) => {
      if (response.status === 200) {
      } else {
        Swal.fire({
          title: `${this.translate.instant('General_Error')}`,
          text: response.errors.join(','),
          icon: 'error'
        });
      }
    });
  }

  cleanRepresentativeType(representatives) {
    const representativesList = [];

    representatives.map(currentRepresentative => {
      if (Object.keys(currentRepresentative).includes('Representative')) {
        currentRepresentative = currentRepresentative['Representative'];
      }

      if (currentRepresentative?.Representatives && Object.keys(currentRepresentative?.Representatives).includes('value')) {
        currentRepresentative.Representatives = currentRepresentative.Representatives.value;
      }

      if (currentRepresentative?.Representatives?.length > 0) {
        representativesList.push(this.cleanRepresentativeType(currentRepresentative['Representatives']));
      }
    })

    return representativesList;
  }

  smoothScroll() {
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;

      if (pos > 0) {
        window.scrollTo(10, pos - 10); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  onSubmit() {
    //Check if the representative has not yet been sumitted.
    this.company = Object.assign({}, this.companyFormGroup.value);
    if (this.company['Representatives'].length <= 0) {
      if (this.addRepresentative != null) {
        const representativeName = this.addRepresentative.representativeFormGroup.get('Representative').get('RepresentativeName').value;
        const representativeTitle = this.addRepresentative.representativeFormGroup.get('Representative').get('RepresentativeTitle').value;
        const representativeNationality = this.addRepresentative.representativeFormGroup.get('Representative').get('RepresentativeNationality').value;
        const representativeDNI = this.addRepresentative.representativeFormGroup.get('Representative').get('DNI').value;
        if (representativeName && representativeTitle && representativeNationality && representativeDNI) {
          this.addRepresentative.saveRepresentative();
        }
      }
    }
    this.company = Object.assign({}, this.companyFormGroup.value);
    if (this.companyFormGroup.invalid || !this.hasOneDigit(this.companyFormGroup.controls['SeatAddress'].get('StreetNumber').value)) {
      this.showSummaryErrors = true;
      this.smoothScroll();

      return;
    }

    // representatives validations    
    let hasAtLeastOneRepresentative = true;
    if (this.formTypeSelection.toLocaleLowerCase() == 'company') {
      const hasAtLeastOneRepresentative = this.company['Representatives'] ? this.company['Representatives'].length > 0 : false;
      if (!hasAtLeastOneRepresentative) {
        Swal.fire({
          title: `${this.translate.instant('General_Error')}`,
          text: `${this.translate.instant('Registration_MissingRepresentative')}`,
          icon: 'warning',
          timer: 5000
        });
        return;
      }
    }

    const naturalPersonArray = this.IsRepresentedByNaturalPerson(this.company['Representatives']);
    const isValidRepresentative = this.endInNaturalPerson(naturalPersonArray); //TODO: make it in a global variable and add validation

    if (!isValidRepresentative) {
      Swal.fire({
        title: `${this.translate.instant('General_Error')}`,
        text: `${this.translate.instant('CRM_Validations_RepresentativeChain_Error')}`,
        icon: 'warning',
        timer: 3000
      });

      return;
    }

    this.cleanRepresentativeType(this.company['Representatives']);

    // Extract representatives from 'Representative Object'
    const extractedRepresentatives = [];

    this.company['Representatives'].map((currentRepresentative: any) => {
      if (Object.keys(currentRepresentative).includes('Representative')) {
        extractedRepresentatives.push(currentRepresentative['Representative']);
      } else {
        extractedRepresentatives.push(currentRepresentative)
      }
    });

    this.company['Representatives'] = extractedRepresentatives;

    if (this.company['SeatAddress'].Country && this.company['SeatAddress'].Country.value) {
      this.company['SeatAddress'].Country = this.company['SeatAddress'].Country.value.name;
    }

    this.company['FormTypeSelection'] = this.formTypeSelection;

    if (this.company['FormTypeSelection'] == 'person') {
      this.company['LegalForm'] = null;
    }

    this._companyService.saveCompanyRegistration(this.company).subscribe(res => {
      if (res) {
        Swal.fire({
          title: `${this.translate.instant('General_Created')}`,
          text: `${this.translate.instant('CRM_Company_Sucess_Created')}`,
          icon: 'success',
          timer: 3000
        }).then(() => {
          this._router.navigate(['/company-created', res]);
        });
      }
    });
  }

  RepresentativeHandler(event: any) {
    this.RepresentativeChildForm = event;
  }

  getDocumentsUploadedRepresentatives(representatives: []) {
    const filesToUpload = [];

    if (!representatives || representatives.length < 1) {
      return;
    }

    // Obtener la lista de Archivos a Subir
    representatives.map(r => {
      // {
      //  name: nameFile,
      //  file: [file]
      // }
    });
  }

  endInNaturalPerson(naturalPersonArray: any) {
    const responseList: any[] = [];
    naturalPersonArray.map((element: any) => {
      if (Array.isArray(element)) {
        responseList.push(this.endInNaturalPerson(element));
      } else {
        responseList.push(element);
      }
    });

    return !responseList.includes(false);
  }

  cleanRepresentative(): void {
    this.companyFormGroup.controls.Representative.patchValue({
      RepresentativeName: '',
      RepresentativeTitle: '',
      IsNaturalPerson: true,

      CompanyName: '',
      VAT: '',

      Address: {
        Country: '',
        State: '',
        City: '',
        PostalCode: '',
        StreetNumber: '',
      },
      LegalForm: {},
      Index: ''
    });
  }

  updateRepresentative(index: number) {
    const representative = this.Representatives.value[index];

    if (representative.IsNaturalPerson === true) {
      this.companyFormGroup.controls.Representative.patchValue({
        RepresentativeName: representative.Name,
        RepresentativeTitle: representative.Title,
        IsNaturalPerson: representative.IsNaturalPerson,
        DNI: representative.DNI,
        Index: index
      });
    } else {
      this.companyFormGroup.controls.Representative.patchValue({
        CompanyName: representative.Name,
        VAT: representative.VAT,
        IsNaturalPerson: representative.IsNaturalPerson,
        Address: representative.Address,
        LegalForm: representative.LegalForm,
        Index: index
      });
    }
  }

  removeRepresentative(index: number) {
    this.Representatives.removeAt(index);
  }

  addContact() {
    this.Contacts.push(this._formBuilder.group({
      Name: [''],
      Title: [''],
      Phone: [''],
      Email: [''],
      Comment: ['']
    }));
  }

  removeContact(index: number) {
    this.Contacts.removeAt(index);
  }

  addDeliveryAddress() {
    this.DeliveryAddresses.push(this._formBuilder.group({
      Country: ['', Validators.required],
      State: ['', Validators.required],
      City: ['', Validators.required],
      PostalCode: ['', Validators.required],
      StreetNumber: ['', Validators.required]
    }));
  }

  removeDeliveryAddress(index: number) {
    this.DeliveryAddresses.removeAt(index);
  }

  updateChangeHistory(index: number): void {
    const history = this.Histories.value[index];

    const dialogRef = this._dialog.open(DialogCompanyHistoryComponent, {
      width: '900px',
      data: {
        history,
        countries: this.Countries
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const currentList = this.Histories.value;

        currentList[index] = {
          Name: result.Name,
          ChangeDate: result.ChangeDate,
          Address: result.Address,
          LegalForm: result.LegalForm,
          Representatives: result.Representatives,
          HasNameChange: result.HasNameChange,
          HasSeatAddressChange: result.HasSeatAddressChange,
          HasLegalFormChange: result.HasLegalFormChange,
          HasRepresentativeChange: result.HasRepresentativeChange,
        };

        this.Histories.patchValue(currentList);

        Swal.fire({
          title: `${this.translate.instant('General_Updated')}`,
          text: `${this.translate.instant('CRM_ChangeHistory_Sucess_Updated')}`,
          icon: 'success',
          timer: 3000
        });
      }
    });
  }

  openCompanyHistoryDialog(): void {
    const selectedCountry = this.companyFormGroup.controls['SeatAddress'].value;
    const dialogRef = this._dialog.open(DialogCompanyHistoryComponent, {
      width: '900px',
      data: {
        countries: this.Countries,
        selectedCountry: selectedCountry ? selectedCountry.Country : undefined
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.Histories.push(this._formBuilder.group({
          Name: [result.Name],
          ChangeDate: [result.ChangeDate],
          Address: [result.Address],
          LegalForm: [result.LegalForm],
          Representatives: [result.Representatives],
          HasNameChange: [result.HasNameChange],
          HasSeatAddressChange: [result.HasSeatAddressChange],
          HasLegalFormChange: [result.HasLegalFormChange],
          HasRepresentativeChange: [result.HasRepresentativeChange],
        }));

        Swal.fire({
          title: `${this.translate.instant('General_Created')}`,
          text: `${this.translate.instant('CRM_ChangeHistory_Sucess_Created')}`,
          icon: 'success',
          timer: 3000
        });
      }
    });
  }

  removeChangeHistory(index: number): void {
    this.Histories.removeAt(index);
  }

  get Representatives(): FormArray {
    return this.companyFormGroup.get('Representatives') as FormArray;
  }

  get Histories(): FormArray {
    return this.companyFormGroup.get('Histories') as FormArray;
  }

  get Contacts(): FormArray {
    return this.companyFormGroup.get('Contacts') as FormArray;
  }

  get DeliveryAddresses(): FormArray {
    return this.companyFormGroup.get('DeliveryAddresses') as FormArray;
  }

  get Documents(): FormArray {
    return this.companyFormGroup.get('Documents') as FormArray;
  }

  get NaturalPersonDocumentIds(): FormArray {
    return this.companyFormGroup.get('NaturalPersonDocumentIds') as FormArray;
  }

  get CompanySeatCountry(): AbstractControl {
    return this.companyFormGroup.get(['SeatAddress', 'Country']);
  }

  get ContactName(): AbstractControl {
    return this.companyFormGroup.get(['Contact', 'Name']);
  }

  get ContactTitle(): AbstractControl {
    return this.companyFormGroup.get(['Contact', 'Title']);
  }

  get ContactEmail(): AbstractControl {
    return this.companyFormGroup.get(['Contact', 'Email']);
  }

  get ContactPhone(): AbstractControl {
    // return this._formBuilder.control({});
    return this.companyFormGroup.get(['Contact', 'Phone']);
  }

  showTermsConditions(): void {
    this._dialog.open(DialogTermsConditionsComponent, {
      width: '900px'
    });
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup || control instanceof FormArray)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

  showPrivacyPolicy(): void {
    this._dialog.open(DialogPrivacyPolicyComponent, {
      width: '900px'
    });
  }

  IsRepresentedByNaturalPerson(representatives: any) {
    const resultList = [];

    representatives?.map(currentRepresentative => {
      if (Object.keys(currentRepresentative).includes('Representative')) {
        currentRepresentative = currentRepresentative.Representative;
      }
      if (currentRepresentative?.IsNaturalPerson === true) {
        resultList.push(true);
      } else if (currentRepresentative?.IsNaturalPerson === false && currentRepresentative?.Representatives.length > 0) {
        resultList.push(this.IsRepresentedByNaturalPerson(currentRepresentative?.Representatives));
      } else if (currentRepresentative?.IsNaturalPerson === false && currentRepresentative?.Representatives.length === 0) {
        resultList.push(false);
      }
    });

    return resultList;
  }

  representativeErrorHandler(event: any) {
    this.representativeValidationList = event;
  }
}
