<div id="services" class="padding-bottom-120 mt-5">
  <div class="container">
    <div class="cover-your-wrap">
      <div class="row">
        <div class="col-lg-4">
          <div class="cover-img bg-image-02 wow animate__ animate__backInUp animated"
            style="background-image: url('assets/images/illustrations/01_.png'); visibility: visible; animation-name: backInUp;">
          </div>
        </div>
        <div class="col-lg-5 offset-lg-2">
          <div class="unique-content-area">
            <div class="section-title brand">
              <h4 class="title">
                {{'Cardboard_Services_Heading' | translate }}
              </h4>

              <p [innerHTML]="'Cardboard_Services_Info' | translate">
                
              </p>

              <div class="btn-wrapper padding-top-30">
                <a [routerLink]="['/register']" class="boxed-btn btn-business">
                  {{'Cardboard_Services_CallToAction' | translate}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="cover-your-area padding-top-110 margin-bottom-120">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title brand desktop-center padding-bottom-40">
          <h3 class="title">
            {{'Cardboard_Body_Heading1' | translate | uppercase}}
          </h3>

          <p class="text-justify">
            {{'Cardboard_Body_Heading1_Info' | translate }}
          </p>
        </div>
      </div>
    </div>

    <br>

    <div class="cover-your-wrap my-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xs-12 col-sm-12 col-lg-4">
            <div class="cover-img style-01 bg-image-02 wow animate__ animate__backInUp animated"
              style="background-image: url(&quot;/assets/images/illustrations/04.svg&quot;); visibility: visible; animation-name: backInUp;">
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-lg-8">
            <div class="unique-content-area">
              <div class="section-title brand">
                <h4 class="title">
                  {{'Cardboard_Body_Heading2' | translate | uppercase}}
                </h4>

                <p class="text-justify">
                  {{'Cardboard_Body_Heading2_Info' | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cover-your-area padding-bottom-120 my-5">
  <div class="container">
    <div class="cover-your-wrap">
      <div class="row justify-content-center">
        <div class="col-xs-12 col-sm-12 col-lg-8">
          <div class="unique-content-area">
            <div class="section-title brand">
              <h4 class="title">
                {{'Cardboard_Body_Heading3' | translate | uppercase}}
              </h4>

              <p class="text-justify">
                {{'Cardboard_Body_Heading3_Info' | translate }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-lg-4">
          <div class="cover-img style-01 bg-image-02 wow animate__ animate__backInUp animated"
            style="background-image: url(&quot;/assets/images/illustrations/05.svg&quot;); visibility: visible; animation-name: backInUp;">
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
