import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IComponentView } from 'src/app/models/navigation/IComponentView';

@Injectable({
  providedIn: 'root'
})
export class ViewTitleService {
  private emitChangeSource = new Subject<IComponentView>();
  changeEmitted$ = this.emitChangeSource.asObservable();

  constructor() { }

  emitChange(change:IComponentView) {
    this.emitChangeSource.next(change);
  }
}
