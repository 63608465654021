<h1 mat-dialog-title>
    {{'General_Privacy_Policy' | translate}}
</h1>

<div mat-dialog-content>
    <div [innerHTML]="(privacypolicy$ | async)?.data.content" *ngIf="!withLabel"></div>

    <section *ngIf="withLabel">
        <span [innerHTML]="data.label | translate"></span>
    </section>
</div>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{'General_Close' | translate}}
    </button>
</div>