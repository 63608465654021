<app-header [hideElements]="{ register: true }"></app-header>

<div class="container-xl mt-5">
  <div class="row">
    <div class="col-12 mt-5">
      <div class="card shadow-sm mb-5 mt-5">
        <div class="card-body pt-4 pb-5" *ngIf="company?.signed?.length < 1">         
          <div class="container-fluid" style="width: 85%;">
            <div class="row">
              <div class="col-12">
                <h4 class="title text-center">
                  {{'Cardboard_RepresentativeSignatureTitle_CPA' | translate}}
                </h4>

                <p class="mt-2 text-justify">
                  <span [innerHTML]="'Cardboard_RepresentativeSignatureText_CPA' | translate"></span>
                </p>
              </div>
              <div class="card-download mb-2" *ngFor="let item of company.cpaContracts" (click)="downloadFile(item)">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-2 my-auto">
                    <img class="img-fluid download-img" src="/assets/icons/word-file.png" alt="Document file image" />
                  </div>

                  <div class="col-12 col-sm-12 col-md-8 my-auto text-start">
                    <strong>{{item?.fileName}}</strong>
                  </div>

                  <div class="col-12 col-sm-12 col-md-2 my-auto text-center">
                    <section class="animate__animated animate__rubberBand animate__repeat-3">
                      <fa-icon class="download-icon" icon="cloud-arrow-down"></fa-icon>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-fluid mt-4">
            <div class="row">
              <hr class="mb-3">

              <form class="mt-3 row" [formGroup]="formGroup" (ngSubmit)="uploadContract()">
                <div class="col-9 mx-auto">
                  <div class="mb-3 dashed-bottom-border">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-2 my-auto">
                        <strong class="step-form">1.</strong>
                      </div>

                      <div class="col-12 col-sm-12 col-md-10">
                        <label>{{'Cardboard_BeCause_SignatureDocument1' | translate}}</label>
                        <br><br>
                        <smartec-file-upload [message]="'General_DnDMessage'" [limit]="1"
                          (files)="getUploadedFiles($event, '1')"></smartec-file-upload>
                      </div>
                    </div>
                  </div>

                  <div class="mb-3 dashed-bottom-border">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-2 my-auto">
                        <strong class="step-form">2.</strong>
                      </div>

                      <div class="col-12 col-sm-12 col-md-10">
                        <label>{{'Cardboard_BeCause_SignatureDocument2' | translate}}</label>
                        <br><br>
                        <smartec-file-upload [message]="'General_DnDMessage'" [limit]="6"
                          (files)="getUploadedFiles($event, '2')"></smartec-file-upload>
                      </div>
                    </div>
                  </div>

                  <button type="submit" class="col-12" mat-flat-button color="primary"
                    [disabled]="formGroup.invalid || signatureEvidences.length < 1">
                    <mat-icon>save</mat-icon> {{'General_UploadData' | translate}}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <section class="card-body pt-4 pb-5" *ngIf="company?.signed?.length > 0">
          <div class="container-fluid" style="width: 85%;">
            <div class="row">
              <div class="col-12">
                <h4 class="title text-center">
                  {{'Cardboard_RepresentativeHasSignedContractTitle_CPA' | translate}}
                </h4>
              </div>

              <div class="col-12 mt-3">
                <div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-4 mb-3">
                      <img src="/assets/images/illustrations/contract.svg" class="img-fluid" alt="Contract">
                    </div>

                    <div class="col-12 col-sm-12 col-md-8 text-justify">
                      <article class="alert alert-success">
                        <span [innerHTML]="'Cardboard_RepresentativeHasSignedContractText_CPA' | translate"></span>
                      </article>

                      <br>

                      <section class="mb-3" *ngFor="let item of company.signed">
                        <p class="mb-0">
                          {{'General_' + item.type | translate}}
                        </p>

                        <button type="button" (click)="downloadSignedDocument(item.url)" class="col-12 mb-2"
                          mat-flat-button color="primary">
                          <mat-icon>file_download</mat-icon> <small class="ps-1">{{item.fileName}}</small>
                        </button>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
