import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import Swal from "sweetalert2";
import { DialogPrivacyPolicyComponent } from "../../dialogs/dialog-privacy-policy/dialog-privacy-policy.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogTermsConditionsComponent } from "../../dialogs/dialog-terms-conditions/dialog-terms-conditions.component";
import { ICountryService } from "src/app/services/country/country";
import { VatValidator } from "src/app/validators/validator.vat";

@Component({
  selector: "app-company-registration-europe",
  templateUrl: "./company-registration-europe.component.html",
  styleUrls: ["./company-registration-europe.component.scss"],
})
export class CompanyRegistrationEuropeComponent implements OnInit {
  @ViewChild("stepper") private stepper: MatStepper;

  public userAccountsLimit: number = 10;
  public companyFormGroup: FormGroup;
  public legalForms: any[] = [];
  public provinces: any[] = [];
  public vat: string = "";
  public countries: any[] = [];

  public completed: any = {
    companyInformation: false,
    cardboardRepresentatives: false,
    contractSignature: false,
  };
  public titles: any[] = [
    "General_Mr",
    "General_Ms",
    "General_Dr",
    "General_Dra",
  ];
  public phoneCountries: any[] = [];
  public selectedPhone: any;
  public createLegalForm: boolean = false;

  get cardboardUsers(): FormArray {
    return this.companyFormGroup.get("cardboardUsers") as FormArray;
  }

  get ContactName(): AbstractControl {
    return this.companyFormGroup.get(["contact", "name"]);
  }

  get ContactLastName(): AbstractControl {
    return this.companyFormGroup.get(["contact", "lastName"]);
  }

  get ContactTitle(): AbstractControl {
    return this.companyFormGroup.get(["contact", "title"]);
  }

  get ContactEmail(): AbstractControl {
    return this.companyFormGroup.get(["contact", "email"]);
  }

  get ContactPhone(): AbstractControl {
    return this.companyFormGroup.get(["contact", "phone"]);
  }

  get ContactCountryPhone(): AbstractControl {
    return this.companyFormGroup.get(["contact", "countryPhone"]);
  }

  constructor(
    private cardboardService: ICardboardService,
    private countryService: ICountryService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.companyFormGroup = this.formBuilder.group({
      id: 0,
      name: ["", [Validators.required, Validators.maxLength(100)]],
      vat: ["", [ Validators.required, VatValidator() ]],
      legalForm: ["", Validators.required],
      comment: [""],
      contact: this.formBuilder.group({
        id: 0,
        name: ["", Validators.required],
        lastName: ["", Validators.required],
        title: ["", Validators.required],
        email: [
          "",
          [
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            Validators.required,
          ],
        ],
        countryPhone: ["", Validators.required],
        phone: [
          "",
          [Validators.required, Validators.pattern("^[- +()0-9]{7,}")],
        ],
      }),
      country: ["", Validators.required],
      street: ["", Validators.required],
      number: [""],
      city: ["", Validators.required],
      state: ["", Validators.required],
      postalCode: ["", Validators.required],
      cardboardUsers: this.formBuilder.array([]),
      termsConditions: [false, Validators.requiredTrue],
      privacyPolicy: [false, Validators.requiredTrue],

      createLegalFormName: "",
      createLegalFormDescription: "",
    });
  }

  ngOnInit(): void {
    this.getCountries();
  }

  onSubmit(): void {
    if (
      this.cardboardUsers.length < 1 ||
      !this.cardboardUsers.value[0]?.name ||
      !this.cardboardUsers.value[0]?.lastName ||
      !this.cardboardUsers.value[0]?.email
    ) {
      Swal.fire({
        title: `${this.translate.instant("SCALE_MissingDataTitle")}`,
        text: `${this.translate.instant("SCALE_MissingUserDetails")}`,
        icon: "warning",
        timer: 4000,
      });

      return;
    }

    if (this.companyFormGroup.invalid) {
      return;
    }

    const company = Object.assign({}, this.companyFormGroup.value);
    company.country = company.country.name;
    company.cardboardContacts = [company.contact];

    if (!company.id) {
      this.cardboardService
        .companyRegistration(company)
        .subscribe((companyId) => {
          if (companyId) {
            Swal.fire({
              title: `${this.translate.instant("General_Created")}`,
              text: `${this.translate.instant("CRM_Company_Sucess_Created")}`,
              icon: "success",
              timer: 3000,
            }).then(() => {
              this.router.navigate([`/register/${company.vat}/${companyId}`]);

              this.completed.companyInformation = true;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
          }
        });
    } else {
      this.cardboardService
        .updateCardboardCompany(company)
        .subscribe((updated: boolean) => {
          if (updated) {
            Swal.fire({
              title: `${this.translate.instant("General_Updated")}`,
              text: `${this.translate.instant("CRM_Company_Sucess_Updated")}`,
              icon: "success",
              timer: 3000,
            }).then(() => {
              // this.router.navigate([`/register/${company.vat}/${company.id}`]);

              this.completed.companyInformation = true;
              this.stepper.selected.completed = true;
              this.stepper.next();
            });
          }
        });
    }
  }

  submitLegalForm(): void {
    const createLegalForm = {
      country: this.companyFormGroup.value?.country?.name,
      name: this.companyFormGroup.value?.createLegalFormName,
      description: this.companyFormGroup.value?.createLegalFormDescription,
    };

    this.countryService
      .createLegalForm(createLegalForm)
      .subscribe((response: any) => {
        if (response) {
          this.createLegalForm = false;

          this.legalForms.push({
            name: createLegalForm.name,
            description: createLegalForm.description,
          });

          this.companyFormGroup.patchValue({
            legalForm: createLegalForm.name,
          });
        }
      });
  }

  getCountries(): void {
    this.countryService
      .getCountriesDropdownWithLegalForms()
      .subscribe((countries: any) => {
        this.countries = countries;
        this.phoneCountries = countries.map((country: any) => {
          return {
            name: country.name,
            code: `+${country.phoneCode}`,
            flag: country.iso,
          };
        });
      });
  }

  setPhoneFlag(event: any): void {
    let selectedValue = event.value;
    this.selectedPhone = this.phoneCountries.find(
      (x) => x.code == selectedValue
    );
  }

  removeLegalFormFromCompanyName(): void {
    let company = this.companyFormGroup.value.name;

    if (!company) {
      return;
    }

    if (this.legalForms?.length < 1) {
      return;
    }

    let split = company.split(" ");

    if (split?.length < 1) {
      return;
    }

    split
      .filter((value: any) => value)
      .map((value: string) => {
        const find = this.legalForms.filter((legalForm: any) =>
          legalForm.name.toLowerCase().includes(value.toLowerCase())
        )[0];

        if (find) {
          company = company.replaceAll(value, "").trim();

          this.companyFormGroup.patchValue({
            name: company,
          });
        }
      });
  }

  removeCharacters(event: any): void {
    let value = event.target.value;

    if (!value) {
      return;
    }

    value = value.replace(/\D/g, "");

    if (event.srcElement.id == "vatInput") {
      this.companyFormGroup.patchValue({
        vat: value,
      });
    } else if (event.srcElement.id == "postalCodeInput") {
      this.companyFormGroup.patchValue({
        postalCode: value,
      });
    }
  }

  setValueToFirstUser(type: string): void {
    switch (type) {
      case "title":
        this.cardboardUsers.at(0).patchValue({
          title: this.ContactTitle.value,
        });

        break;

      case "name":
        this.cardboardUsers.at(0).patchValue({
          name: this.ContactName.value,
        });

        break;

      case "lastName":
        this.cardboardUsers.at(0).patchValue({
          lastName: this.ContactLastName.value,
        });

        break;

      case "email":
        this.cardboardUsers.at(0).patchValue({
          email: this.ContactEmail.value,
        });

        break;
    }
  }

  addUserToList(): void {
    const usersCount = this.cardboardUsers.length;

    if (usersCount < this.userAccountsLimit) {
      this.cardboardUsers.push(
        this.formBuilder.group({
          id: 0,
          name: ["", Validators.required],
          lastName: ["", Validators.required],
          title: ["", Validators.required],
          email: [
            "",
            [
              Validators.required,
              Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
            ],
          ],
        })
      );
    } else {
      Swal.fire({
        title: `${this.translate.instant("SCALE_AmountLimit")}`,
        text: `${this.translate.instant("SCALE_UsersLimitMessage", {
          value: this.userAccountsLimit,
        })}`,
        icon: "warning",
        timer: 4000,
      });
    }
  }

  removeCardboardUserElement(user: any, index: number) {
    if (!user) {
      return;
    }

    Swal.fire({
      title: `${this.translate.instant("General_Delete")}`,
      text: `${this.translate.instant("General_Delete_Confirmation", {
        value: this.translate.instant("General_User"),
      })}`,
      confirmButtonText: `${this.translate.instant("General_Confirm")}`,
      cancelButtonText: `${this.translate.instant("General_Cancel")}`,
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        if (user.id) {
          this.cardboardService
            .deleteUser({ vat: this.vat, userId: user.id })
            .subscribe((value: boolean) => {
              if (value) {
                this.cardboardUsers.removeAt(index);

                Swal.fire({
                  title: `${this.translate.instant("General_Deleted")}`,
                  text: `${this.translate.instant("General_Delete_Success")}`,
                  icon: "success",
                  timer: 3000,
                });
              }
            });
        } else {
          this.cardboardUsers.removeAt(index);

          Swal.fire({
            title: `${this.translate.instant("General_Deleted")}`,
            text: `${this.translate.instant("General_Delete_Success")}`,
            icon: "success",
            timer: 3000,
          });
        }
      }
    });
  }

  showPrivacyPolicy(): void {
    this.dialog.open(DialogPrivacyPolicyComponent, {
      width: "980px",
      disableClose: true,
      data: {
        withLabel: true,
        label: "Cardboard_Privacy_Content",
      },
    });
  }

  showTermsConditions(): void {
    this.dialog.open(DialogTermsConditionsComponent, {
      width: "900px",
      disableClose: true,
      data: {
        withLabel: true,
        label: "Cardboard_TermsOfUse_Content",
      },
    });
  }

  setLegalFormList(): void {
    this.legalForms = [];

    if (!this.companyFormGroup.value.country) {
      return;
    }

    this.legalForms = this.companyFormGroup.value?.country.legalForms;
    this.provinces = this.companyFormGroup.value?.country.states;
  }
}
