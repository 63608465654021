import { ICountry } from "src/app/models/parameters/ICountry";
import { Observable } from "rxjs";

export abstract class ICountryService {
  abstract getCountries(): Observable<ICountry[]>;
  abstract getAllNationalities(): Observable<any>;
  abstract getCountriesDropdown(): Observable<any>;
  abstract getCountriesDropdownWithLegalForms(): Observable<any>;
  abstract createLegalForm(legalForm: any): Observable<any>;
}
