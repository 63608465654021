import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ICardboardService } from "src/app/services/cardboard/cardboard.interface";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-company-status",
  templateUrl: "./company-status.component.html",
  styleUrls: ["./company-status.component.scss"],
})
export class CompanyStatusComponent implements OnInit {
  public stepsCompleted = {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
  };

  public currentStatus = {
    step: 1,
    title: "",
    description: "",
    details: false
  };

  public companyVAT: string;
  public companyStatus: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cardboardService: ICardboardService
  ) {
    this.activatedRoute.params.subscribe((value: any) => {
      this.companyVAT = value.id;

      if (this.companyVAT) {
        this.getCompanyStatus();
      }
    });
  }

  ngOnInit(): void {}

  getCompanyStatus(): void {
    this.cardboardService
      .getCompanyStatus(this.companyVAT)
      .subscribe((company: any) => {
        this.companyStatus = company;
        console.log(this.companyStatus);

        if (
          this.companyStatus.stepsCompleted &&
          this.companyStatus.stepsCompleted.length > 0
        ) {
          for (let i = this.companyStatus.stepsCompleted.length - 1; i >= 0; i--) {
            this.stepsCompleted[`step${i + 1}`] = true;
          }

          const currentState =
            this.companyStatus.stepsCompleted[this.companyStatus.stepsCompleted.length - 1];

            this.currentStatus.step = currentState.step;
          this.currentStatus.title = currentState.title;
          this.currentStatus.description = currentState.description;
        }
      });
  }

  downloadTradeRegisterExcerpt(): void {
    let url = `${environment.API_URL}/companies/${this.companyStatus?.company.id}/${this.companyStatus?.tradeRegisterExcerpt?.document}`;
    window.open(url, "_blank")?.focus();
  }
}
