import { Observable } from "rxjs";

export abstract class ICardboardService {
  abstract getCardboardCompanyByVAT(VAT: string): Observable<any>;
  abstract getCardboardCompanyById(id: number): Observable<any>;
  abstract companyRegistration(cardboardCompany: any): Observable<any>;
  abstract updateCardboardCompany(cardboardCompany: any): Observable<any>;
  abstract setRepresentatives(representatives: any): Observable<any>;
  abstract uploadDocumentToCompany(
    companyId: number,
    documentType: string,
    file: any
  ): Observable<any>;
  abstract createCardboardNDA(data: any): Observable<any>;
  abstract getCardboardUnsubscribeByEmail(email: string): Observable<any>;
  abstract unsubscribeToEmails(email: string): Observable<any>;
  abstract deleteUser(user: any): Observable<any>;
  abstract saveSystemLogs(logs: any): Observable<any>;
  abstract getCardboardCompanySignature(
    id: number,
    email: string
  ): Observable<any>;
  abstract uploadRepresentativeContract(form: any, file: any): Observable<any>;
  abstract getCompanyStatus(vat: string): Observable<any>;
  abstract uploadBeCauseSignedContract(form: any): Observable<any>;
}
