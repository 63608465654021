<app-header></app-header>
<app-header-area></app-header-area>

<app-landing-page-services></app-landing-page-services>

<app-landing-page-more-info></app-landing-page-more-info>

<p class="text-center pb-3">
  {{'General_Footer_CDC_CR' | translate}}
</p>

<!-- <app-landing-page-faq></app-landing-page-faq> -->

<!-- <app-landing-page-call-to-action></app-landing-page-call-to-action>

<app-landing-page-contact-section></app-landing-page-contact-section> -->

<!-- <app-footer></app-footer> -->
