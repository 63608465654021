import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        language: this.translateService.currentLang
          ? this.translateService.currentLang
          : "",
      },
    });

    return next.handle(request);
  }
}
