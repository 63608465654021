import { Injectable } from '@angular/core';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IIndustry } from 'src/app/models/parameters/IIndustry';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";


@Injectable({
  providedIn: 'root'
})
export class IndustryService {

  private _apiURL: String = `${environment.API_URL}/api/industries/`;

    constructor(
        private http: HttpClient,
        private translate: TranslateService
        ) { }

    getIndustries() {
        return this.http.get<IIndustry[]>(this._apiURL + 'GetIndustries').pipe(
            retry(0),
            catchError(this.handleError)
        )
    }

    handleError(error: any) {
        var id = Sentry.captureException(error);
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        Swal.fire({
            title: `${this.translate.instant('General_Error')}`,
            text: `Report id: ${id} \n ${errorMessage}`,
            icon: 'error'
        });

        return throwError(errorMessage);
    }
}
