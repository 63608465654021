import { ITermConditionsService } from "src/app/services/terms-conditions/terms-conditions";
import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { Observable } from "rxjs";

@Component({
  selector: "dialog-dialog-terms-conditions",
  templateUrl: "./dialog-terms-conditions.component.html",
  styleUrls: ["./dialog-terms-conditions.component.scss"],
})
export class DialogTermsConditionsComponent implements OnInit {
  public withLabel: boolean = false;
  public termsCondition$: Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogTermsConditionsComponent>,
    private termsConditionService: ITermConditionsService
  ) {}

  ngOnInit() {
    if(this.data?.withLabel) {
      this.withLabel = true;
    } else {
      this.getTermsAndConditions();
    }
  }

  getTermsAndConditions(): void {
    this.termsCondition$ =
      this.termsConditionService.getTermConditionByLanguage();
  }
}
