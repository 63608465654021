<div id="faqs" class="accoridions padding-bottom-120">
    <div class="container-fluid">
        <div class="row justify-content-center padding-bottom-50">
            <div class="col-lg-7">
                <div class="section-title startup desktop-center">
                    <h2 class="title">{{'Cardboard_FAQ' | translate }}</h2>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-7">
                <div class="accordion-wrapper">
                    <div id="accordion">
                        <!-- <div class="card">
                            <div class="card-header" id="headingOwo">
                                <h5 class="mb-0">
                                    <a class="collapsed" role="button" data-toggle="collapse" data-target="#collapseOwo"
                                        aria-expanded="false" aria-controls="collapseOwo">
                                        {{'Cardboard_FAQ_1' | translate }}
                                    </a>
                                </h5>
                            </div>

                            <div id="collapseOwo" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    {{'Cardboard_FAQ_1_Answer' | translate }}
                                </div>
                            </div>
                        </div> -->

                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item" *ngFor="let faq of faqList; let i = index">
                                <h2 class="accordion-header" id="header_{{i}}">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        attr.data-bs-target="#id{{i}}" aria-expanded="false"
                                        attr.aria-controls="id{{i}}">
                                        <span [innerHTML]="faq.title | translate"></span>
                                    </button>
                                </h2>

                                <div id="id{{i}}" class="accordion-collapse collapse" attr.aria-labelledby="header_{{i}}"
                                    data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p class="text-justify" style="font-size: .91rem; line-height: 1.8rem;">
                                            <span [innerHTML]="faq.content | translate"></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
