import { Observable } from 'rxjs';
import { ICompany } from 'src/app/models/company/ICompany';

export abstract class ICompanyService {
    abstract getCompanies(): Observable<ICompany[]>;
    abstract getCompanyById(id: string): Observable<ICompany>;
    abstract getSimpleCompanyById(id: string): Observable<ICompany>;
    abstract getCompanyByVAT(vat: string): Observable<any>;
    abstract getCompanyByVATAndContactEmail(form: any): Observable<any>;
    abstract saveCompany(company: ICompany): Observable<any>;
    abstract userCompanyRegistration(company: ICompany): Observable<any>;
    abstract saveCompanyDecrypted(company: ICompany): Observable<any>;
    abstract saveCompanyRegistration(company: ICompany): Observable<any>;
    abstract saveCompanyLSCRegistration(company: any): Observable<any>;
    abstract companyRegistration(company: any): Observable<any>;
    abstract updateCompanyRegistration(company: any): Observable<any>;
    abstract updateCompany(company: ICompany): Observable<any>;
    abstract deleteCompany(id: string): Observable<any>;
    abstract uploadFileToCompany(companyId: string, documentType: string, file: any): Observable<any>;
    abstract companyEmailValidation(token: string, randomKey: string, companyId: string): Observable<any>;
    abstract companyCount(): Observable<any>;
    abstract updateDocumentsCompany(documentsCompany: any): Observable<any>;
    abstract addRepresentatives(data: any): Observable<any>;
    abstract createCardboardNDA(data: any): Observable<any>;
}
