import { IUserRegistration } from "src/app/models/users/IUserRegistration";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs";

export abstract class IHelperService {
  abstract encryptUser(user: IUserRegistration): Observable<any>;
  abstract generateObjectId(): string;
  abstract errorHandler(httpError: HttpErrorResponse): any;
  abstract fileToBase64(file: File): Promise<string>;
}
