import { Injectable } from '@angular/core';
import { throwError, BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IUserRegistration } from 'src/app/models/users/IUserRegistration';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private _apiURL: String = `${environment.API_URL}/api/users/`;
  private currentUserSubject: BehaviorSubject<IUserRegistration>;
  public currentUser: Observable<IUserRegistration>;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private translate: TranslateService
  ) { 
    this.currentUserSubject = new BehaviorSubject<IUserRegistration>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  loginUser(user: IUserRegistration) {
    return this._http.post<any>(`${this._apiURL}Login`, user)
        .pipe(map((response: any) => {
            if (response.status === 200) {
                if(response.data.token) {
                    localStorage.setItem('currentUser', JSON.stringify(response.data));
                    this.currentUserSubject.next(response.data);
                } else {
                    var id = Sentry.captureMessage("Error on CRM Token Generation");
                    Swal.fire({
                        icon: 'error',
                        title: `${this.translate.instant('General_Error')}`,
                        text:  `${this.translate.instant('CRM_Token_Generation_Error')} \n ${id} \n`
                    });
                }
            } else {
                var id = Sentry.captureMessage("General Error while Login");
                Swal.fire({
                    icon: 'error',
                    title: `${this.translate.instant('General_Error')}`,
                    text: `${response.errors.join(', ')} \n ${id}`
                });
            }

            return user;
        }));
};

logout() {
    localStorage.removeItem('currentUser');

    this._router.navigateByUrl('');
    this.currentUserSubject.next(null);
};

public currentUserValue(): IUserRegistration {
    return this.currentUserSubject.value;
}

// Error handling 
handleError(error: any) {
    var id = Sentry.captureException(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
    } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
        title:  `${this.translate.instant('General_Error')}`,
        text: `${errorMessage} \n Report id: ${id} \n`,
        icon: 'error'
    });

    return throwError(errorMessage);
};
}
