import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from '../pagination/pagination.service';
import { retry, catchError } from 'rxjs/operators';
import { IVATService } from './vat';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class VATService extends IVATService {
  private _apiURL: String = `${environment.API_URL}/api/vat/`;

  constructor(
    private http: HttpClient,
    private paginationService: PaginationService,
    private _translateService: TranslateService
  ) {
    super();
  }

  getVatByNumber(number: String) {
    return this.http.get<any>(`${this._apiURL}GetVatByNumber?vat=${number}`).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  saveVat(vat: any) {
    return this.http.post<any>(`${this._apiURL}SaveVAT`, vat).pipe(
      retry(0),
      catchError(this.handleError)
    );
  }

  handleError(error: any) {
    var id = Sentry.captureException(error);
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `${this._translateService.instant('General_Error')}`,
      text: `Report id: ${id} \n ${errorMessage}`,
      icon: 'error'
    });

    return throwError(errorMessage);
  }
}
