import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-more-info',
  templateUrl: './landing-page-more-info.component.html',
  styleUrls: ['./landing-page-more-info.component.scss']
})
export class LandingPageMoreInfoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
