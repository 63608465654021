import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { ITranslationService } from "./translation";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class TranslationService implements ITranslationService {
  public selectedCurrentLanguage: Subject<string> = new Subject<string>();

  constructor(private http: HttpClient, private translate: TranslateService) {}

  onLanguageChange(language: string) {
    this.selectedCurrentLanguage.next(language);
  }

  getLanguage(): Observable<any> {
    return this.selectedCurrentLanguage.asObservable();
  }

  getLanguagesList() {
    return this.http
      .get<any>(`${environment.Resources_API_Url}/resources/getLanguageList`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage("General Error on GET:getLanguageList endpoint");
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      );
  }
}
