import { Observable } from 'rxjs';
import { IUserRegistration } from 'src/app/models/users/IUserRegistration';

export abstract class IUsersService {
    abstract getUsers(): Observable<IUserRegistration[]>;
    abstract getAllUsers(): Observable<IUserRegistration[]>;
    abstract saveUser(user:IUserRegistration):Observable<any>;
    abstract deleteUser(id:String):Observable<any>;
    abstract validateToken(token:String):Observable<any>;
    abstract decryptUserInfo(user:IUserRegistration):Observable<any>;
    
}
