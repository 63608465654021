<mat-card>
    <p [innerHTML]="'CRM_MilkCaseWelcome' | translate:{'value':cartelName}"></p>
</mat-card>

<br>

<div class="container" *ngIf="!formTypeSelection">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-warning" role="alert">
                <fa-icon icon="info-circle"></fa-icon> {{'General_Company_Selection_Message' | translate}}
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-xl-6 mb-4">
            <div class="card card-button card-button--person p-2"
                (click)="formTypeSelection = 'person'; initGoogleMapsSearch()">
                <div class="card-body text-center">
                    <fa-icon class="col-12" icon="user-tie" size="5x"></fa-icon>
                    <h1>
                        {{'General_RegistrationType_Person' | translate }}
                    </h1>
                </div>
            </div>
        </div>

        <div class="col-12 col-xl-6 mb-4">
            <div class="card card-button card-button--building p-2"
                (click)="formTypeSelection = 'company'; initGoogleMapsSearch()">
                <div class="card-body text-center">
                    <fa-icon class="col-12" icon="building" size="5x"></fa-icon>
                    <h1>
                        {{'General_RegistrationType_Company' | translate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="text-right mb-3" *ngIf="formTypeSelection">
    <div class="btn-group btn-group-sm btn-group-toggle">
        <label class="btn"
            [ngClass]="{'active btn-primary': formTypeSelection == 'person', 'btn-secondary': formTypeSelection == 'company'}"
            (click)="formTypeSelection = 'person'"
            onclick="_paq.push(['trackEvent', 'Button cliked', 'Click', 'Persona física']);">
            <input type="radio" name="options" />
            <fa-icon icon="user-tie"></fa-icon> {{'General_RegistrationType_Person' | translate }}
        </label>

        <label class="btn"
            [ngClass]="{'active btn-primary': formTypeSelection == 'company', 'btn-secondary': formTypeSelection == 'person'}"
            (click)="formTypeSelection = 'company'"
            onclick="_paq.push(['trackEvent', 'Button cliked', 'Click', 'Persona jurídica']);">
            <input type="radio" name="options" />
            <fa-icon icon="building"></fa-icon> {{'General_RegistrationType_Company' | translate }}
        </label>
    </div>
</div>

<section id="summary_errors" class="card mb-3" *ngIf="formTypeSelection && showSummaryErrors">
    <div class="card-body">
        <h3 class="form-divider summary-title">
            {{'General_ErrorSummaryTitle' | translate}}
        </h3>

        <div
            *ngIf="companyFormGroup.invalid || !hasOneDigit(companyFormGroup.controls['SeatAddress'].get('StreetNumber').value)">
            <p [innerHTML]="'General_ErrorSummaryMessage' | translate"></p>

            <ul>
                <li *ngIf="companyFormGroup.controls['Name'].errors !== null"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_NameLastName')}">
                </li>

                <li *ngIf="companyFormGroup.controls['Email'].errors !== null"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Email')}">
                </li>

                <li *ngIf="companyFormGroup.controls['Phone'].errors !== null"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Phone')}">
                </li>

                <li *ngIf="companyFormGroup.controls['LegalForm'].errors !== null"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Legal_Form')}">
                </li>

                <li *ngIf="formTypeSelection == 'person' && !companyFormGroup.controls['RepresentativeNationality'].value"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Nationality')}">
                </li>

                <li *ngIf="!companyFormGroup.controls['VAT'].value && formTypeSelection == 'person'"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Id_Number')}">
                </li>

                <li *ngIf="!companyFormGroup.controls['VAT'].value && formTypeSelection == 'company'"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_VAT')}">
                    <div *ngIf="companyFormGroup.get('SeatAddress').invalid">
                <li *ngIf="companyFormGroup.controls['SeatAddress'].get('Country').invalid && companyFormGroup.get('SeatAddress').get('Country').errors.required"
                    [innerHTML]="'General_Country' | translate: {'value': translate.instant('General_VAT')}">
                </li>

                <li *ngIf="companyFormGroup.controls['SeatAddress'].get('City').invalid && companyFormGroup.controls['SeatAddress'].get('City').errors.required"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_VAT')}">
                </li>

                <li *ngIf="companyFormGroup.controls['SeatAddress'].get('StreetNumber').invalid && companyFormGroup.get('SeatAddress').get('StreetNumber').errors.required"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_StreetNumber')}">
                </li>
        </div>
        </li>

        <li *ngIf="!hasOneDigit(companyFormGroup.controls['SeatAddress'].get('StreetNumber').value)"
            [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Address')}">
        </li>

        <li *ngIf="formTypeSelection == 'company' && Representatives.length < 1"
            [innerHTML]="'General_RepresentativesEntry' | translate">
        </li>

        <div *ngIf=" representativeValidationList !== null && representativeValidationList !== undefined">
            <div *ngIf="representativeValidationList.length > 0">
                <li *ngIf="representativeValidationList.includes('missingNotaryState')"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_Notary')}">
                </li>
                <li *ngIf="representativeValidationList.includes('missingNotaryDate')"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_NotaryRegistrationDate')}">
                </li>
                <li *ngIf="representativeValidationList.includes('missingNotaryName')"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('CRM_NotaryName')}">
                </li>
                <li *ngIf="representativeValidationList.includes('missingNotaryRegistrationNumber')"
                    [innerHTML]="'General_MandatoryField' | translate: {'value': translate.instant('General_RegisterNumber')}">
                </li>
            </div>
        </div>
        </ul>
    </div>
    </div>
</section>

<!-- Form Init -->
<div class="card mb-4" *ngIf="formTypeSelection">
    <div class="card-body">
        <form [formGroup]="companyFormGroup" (ngSubmit)="onSubmit()" class="row">
            <div class="col-xl-12 col-lg-12">
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-12 col-sm-3">
                                <div class="form-group">
                                    <label class="label-control">
                                        <span *ngIf="formTypeSelection == 'person'">
                                            {{'General_NameLastName' | translate | titlecase}} <span
                                                class="required-star">*</span>
                                        </span>

                                        <span *ngIf="formTypeSelection == 'company'">
                                            {{'General_Company_Name' | translate}} <span
                                                class="required-star">*</span>
                                        </span>
                                    </label>

                                    <input type="text" class="form-control" name="name" formControlName="Name"
                                        autocomplete="off"
                                        [ngClass]="{'is-valid': !companyFormGroup.get('Name').invalid && (companyFormGroup.get('Name').touched || companyFormGroup.get('Name').dirty), 'is-invalid': companyFormGroup.get('Name').invalid && companyFormGroup.get('Name').errors.required && (companyFormGroup.get('Name').touched || companyFormGroup.get('Name').dirty)}"
                                        required />
                                </div>
                            </div>

                            <div class="col-12 col-sm-3" *ngIf="formTypeSelection == 'person'">
                                <div class="form-group">
                                    <label class="label-control">{{'General_Nationality' | translate }} <span
                                            class="required-star">*</span></label>

                                    <ng-select [clearable]="true" [searchable]="true" [items]="Nationalities"
                                        bindLabel="name" bindValue="name" formControlName="RepresentativeNationality"
                                        appendTo="body" [virtualScroll]="true"></ng-select>
                                </div>
                            </div>

                            <div class="col-12 col-sm-3">
                                <div class="form-group">
                                    <label class="label-control">
                                        <span *ngIf="formTypeSelection == 'person'">
                                            {{'General_Id_Number' | translate | uppercase}} <span
                                                class="required-star">*</span>
                                        </span>

                                        <span *ngIf="formTypeSelection == 'company'">
                                            {{'General_VAT' | translate | uppercase}} <span
                                                class="required-star">*</span>
                                        </span>
                                    </label>

                                    <div *ngIf="formTypeSelection == 'company'">
                                        <input type="text" class="form-control"
                                            [ngClass]="{'is-valid': VatValidation && VatValidation.valid, 'is-invalid': VatValidation && VatValidation.valid == false}"
                                            formControlName='VAT' (change)="validateVatNumber()" maxlength="20"
                                            placeholder="{{'General_VAT' | translate | uppercase}}" />
                                        <div
                                            [ngClass]="{'valid-feedback': VatValidation && VatValidation.valid, 'invalid-feedback': VatValidation && VatValidation.valid == false}">
                                            <div [ngSwitch]="VatValidation.valid">
                                                <span *ngSwitchCase="true">
                                                    {{'CRM_Validations_Company_VAT_true' | translate:
                                                    {"value":VatValidation.company_name} }}
                                                </span>

                                                <span *ngSwitchCase="false">
                                                    {{'CRM_Validations_Company_VAT_false' | translate }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="formTypeSelection == 'person'">
                                        <input type="text" class="form-control"
                                            [ngClass]="{'is-valid': !companyFormGroup.get('VAT').invalid && (companyFormGroup.get('VAT').touched || companyFormGroup.get('VAT').dirty), 'is-invalid': companyFormGroup.get('VAT').invalid && companyFormGroup.get('VAT').errors.required && (companyFormGroup.get('VAT').touched || companyFormGroup.get('VAT').dirty)}"
                                            formControlName='VAT' maxlength="20"
                                            placeholder="{{'General_Id_Number' | translate | uppercase}}" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-3">
                                <div class="form-group">
                                    <label class="label-control">{{'General_Email' | translate | titlecase}} <span
                                            class="required-star">*</span></label>
                                    <input formControlName="Email" class="form-control" [ngClass]="{'is-valid': !companyFormGroup.get('Email').invalid && (companyFormGroup.get('Email').touched
                                                                    || companyFormGroup.get('Email').dirty), 'is-invalid': companyFormGroup.get('Email').invalid
                                                                    && companyFormGroup.get('Email').errors.required && (companyFormGroup.get('Email').touched
                                                                    || companyFormGroup.get('Email').dirty)}">
                                </div>
                            </div>
                            <div class="col-12 col-sm-3">
                                <div class="form-group">
                                    <label class="label-control">{{'General_PhoneNumber' | translate }} <span
                                            class="required-star">*</span></label>
                                    <input formControlName="Phone" class="form-control">
                                </div>
                            </div>
                        </div>

                        <!-- Upload ID -->
                        <!-- <div class="row" *ngIf="formTypeSelection == 'person'">
                            <div class="col">
                                <h6 class="form-divider">
                                    <fa-icon icon="cloud-upload-alt"></fa-icon>
                                    {{'CRM_Person_UploadId' | translate }}
                                </h6>

                                <app-file-upload
                                    [companyId]="idCompany"
                                    [documents]="NaturalPersonDocumentIds"
                                    [documentType]="'Representative-ID'"
                                    [docLimit]="2"></app-file-upload>
                            </div>
                        </div> -->

                        <h6 class="form-divider">
                            <fa-icon icon="map-marked-alt"></fa-icon>

                            <span *ngIf="formTypeSelection == 'company'">
                                {{'General_CompanySeat' | translate }} <span class="required-star">*</span>
                            </span>

                            <span *ngIf="formTypeSelection == 'person'">
                                {{'General_HomeAddress' | translate }} <span class="required-star">*</span>
                            </span>
                        </h6>

                        <div class="row" [hidden]="!Countries || Countries.length < 1">
                            <div class="col">
                                <div class="form-group">
                                    <label class="label-control">{{'General_AddressSearch' | translate}}</label>
                                    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                                        placeholder="{{'General_AddressSearchPlaceholder' | translate }}"
                                        autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search />
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="Countries && Countries.length > 0">
                            <div class="col">
                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                                </agm-map>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group" formGroupName="SeatAddress">
                                    <label class="label-control">{{'General_Country' | translate | titlecase}} <span
                                            class="required-star">*</span></label>
                                    <ng-select [clearable]="true" [searchable]="true" [items]="LangCountries"
                                        bindLabel="name" [virtualScroll]="true"
                                        (change)="getStatesByCountry(); getLegalFormsByCountry(); getRegistersByCountry()"
                                        formControlName="Country"
                                        [ngClass]="{'is-valid': !CompanySeatCountry.invalid && (CompanySeatCountry.touched || CompanySeatCountry.dirty), 'is-invalid': CompanySeatCountry.invalid && CompanySeatCountry.errors.required && (CompanySeatCountry.touched || CompanySeatCountry.dirty)}">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group" formGroupName="SeatAddress">
                                    <label class="label-control">{{'General_State' | translate | titlecase}}</label>
                                    <ng-select [clearable]="true" [searchable]="true" [items]="States" bindValue="name"
                                        [virtualScroll]="true" bindLabel="name" (change)="getCitiesByState()"
                                        formControlName="State" appendTo="body"></ng-select>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group" formGroupName="SeatAddress">
                                    <label class="label-control">{{'General_City' | translate }} <span
                                            class="required-star">*</span></label>
                                    <ng-select [clearable]="true" [searchable]="true" [items]="Cities" bindLabel="name"
                                        [virtualScroll]="true" bindValue="name" formControlName="City"
                                        (change)="getPostalCodesByCity()" appendTo="body"></ng-select>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div class="form-group" formGroupName="SeatAddress">
                                    <label class="label-control">{{'General_PostalCode' | translate }}</label>
                                    <ng-select [clearable]="true" [searchable]="true" [items]="PostalCodes"
                                        bindLabel="code" bindValue="code" formControlName="PostalCode"
                                        [virtualScroll]="true" appendTo="body">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group" formGroupName="SeatAddress">
                                    <label class="label-control">{{'General_StreetNumber' | translate }} <span
                                            class="required-star">*</span></label>
                                    <input type="text" class="form-control" formControlName='StreetNumber'
                                        maxlength='100' />
                                </div>
                            </div>

                            <div class="col-md-3" *ngIf="formTypeSelection !== 'person'">
                                <div class="form-group">
                                    <label class="label-control">{{'General_LegalForm' | translate }} <span
                                            class="required-star">*</span></label>
                                    <ng-select [clearable]="true" [searchable]="true" [items]="LegalForms"
                                        bindLabel="name" formControlName="LegalForm" appendTo="body"></ng-select>
                                </div>
                            </div>
                        </div>

                        <!-- <pre>{{companyFormGroup.get('SeatAddress').value.Country | json}}</pre> -->



                        <!-- Extra Delivery address -->
                        <!-- <div class="row" *ngIf="formTypeSelection == 'company'">
                            <div class="col">
                                <div class="form-group">
                                    <label
                                        class="control-label">{{'CRM_Company_ExtraDeliveryAddress' | translate }}</label>
                                    <br>
                                    <div class="pretty p-switch p-toggle p-fill">
                                        <input type="checkbox" (click)="toggleExtraDeliveryAddress()" />
                                        <div class="state p-success p-on">
                                            <label>{{'General_Yes' | translate | titlecase}}</label>
                                        </div>
                                        <div class="state p-danger p-off">
                                            <label>{{'General_No' | translate | titlecase}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div *ngIf="hasExtraDeliveryAddress">
                            <h6 class="form-divider">
                                <fa-icon icon="map-marker-alt"></fa-icon>
                                {{'General_DeliveryAddress' | translate | titlecase}}
                            </h6>

                            <div class="row">
                                <div class="col-12 mb-2 mt-2">
                                    <button type="button" class="btn btn-xs btn-success" (click)="addDeliveryAddress()">
                                        <fa-icon [icon]="['fas', 'plus']"></fa-icon>
                                        {{'General_AddDeliveryAddress' | translate | titlecase}}
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="table-responsive">
                                        <table class="table table-sm table-hover table-bordered table-striped">
                                            <thead class="thead-dark">
                                                <th scope="col">#</th>
                                                <th scope="col">{{'General_Country' | translate | titlecase}}</th>
                                                <th scope="col">{{'General_State' | translate | titlecase}}</th>
                                                <th scope="col">{{'General_City' | translate | titlecase}}</th>
                                                <th scope="col">{{'General_PostalCode' | translate | titlecase}}</th>
                                                <th scope="col">{{'General_StreetNumber' | translate | titlecase}}</th>
                                                <th></th>
                                            </thead>

                                            <tbody formArrayName="DeliveryAddresses">
                                                <tr>
                                                    <td class="text-center" colspan="7"
                                                        *ngIf="DeliveryAddresses.controls.length < 1">
                                                        {{'CRM_Validations_Company_TableDeliveryAddress' | translate}}
                                                    </td>
                                                </tr>

                                                <tr *ngFor="let deliveryAddress of DeliveryAddresses.controls; let i=index"
                                                    [formGroupName]="i">
                                                    <th scope="row">{{i + 1}}</th>
                                                    <td>
                                                        <div class="form-group">
                                                            <ng-select [clearable]="true" [searchable]="true"
                                                                [items]="Countries" bindLabel="name" bindValue="name"
                                                                (change)="getStatesByCountryDeliveryAddress(i);"
                                                                formControlName="Country" appendTo="body"></ng-select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <ng-select [clearable]="true" [searchable]="true"
                                                                [items]="StatesDelivery" bindLabel="name"
                                                                bindValue="name"
                                                                (change)="getCitiesByStateDeliveryAddress(i)"
                                                                formControlName="State" appendTo="body"></ng-select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <ng-select [clearable]="true" [searchable]="true"
                                                                [items]="CitiesDelivery" bindLabel="name"
                                                                bindValue="name" formControlName="City"
                                                                (change)="getPostalCodesByCityDeliveryAddress(i)"
                                                                appendTo="body"></ng-select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <ng-select [clearable]="true" [searchable]="true"
                                                                [items]="PostalCodesDelivery" bindLabel="code"
                                                                bindValue="code" formControlName="PostalCode"
                                                                appendTo="body"></ng-select>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="form-group">
                                                            <input class="form-control form-control-sm" type="text"
                                                                formControlName="StreetNumber" required
                                                                [ngClass]="{'is-valid': !deliveryAddress.get('StreetNumber').invalid && (deliveryAddress.get('StreetNumber').touched || deliveryAddress.get('StreetNumber').dirty), 'is-invalid': deliveryAddress.get('StreetNumber').invalid && (deliveryAddress.get('StreetNumber').touched || deliveryAddress.get('StreetNumber').dirty)}" />
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <button type="button" class="btn btn-sm btn-rounded btn-danger"
                                                            title="Remove" (click)="removeDeliveryAddress(i)">
                                                            <fa-icon icon="trash"></fa-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- End delivery address -->

                        <section *ngIf="formTypeSelection == 'company'">
                            <hr />

                            <app-add-representative [companyId]="idCompany" [Countries]="Countries" #addRepresentative
                                [Nationalities]="Nationalities" [LangCountries]="LangCountries"
                                [ActualLanguage]="ActualLanguage" [RepresentativesInputList]="Representatives"
                                [OnlyNaturalPerson]="false" [PersonName]="companyFormGroup.get('Name')"
                                [Registers]="Registers" (RepresentativesChildList)="assignRepresentatives($event)"
                                [NotaryFromList]="NotaryFromList" (RepresentativeForm)="RepresentativeHandler($event)"
                                (ErrorSummaryEvent)="representativeErrorHandler($event)">
                            </app-add-representative>

                            <hr />
                        </section>
                        <!-- Contact Person starts -->
                        <div>
                            <h6 class="form-divider">
                                <fa-icon icon="address-book"></fa-icon>
                                {{'General_ContactPerson' | translate }}
                            </h6>

                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group" formGroupName="Contact">
                                        <label class="label-control">{{'General_NameLastName' | translate |
                                            titlecase}}</label>
                                        <input type="text" class="form-control" formControlName="Name"
                                            autocomplete="off" maxlength="50"
                                            [ngClass]="{'is-valid': !ContactName.invalid && (ContactName.touched || ContactName.dirty), 'is-invalid': ContactName.invalid && ContactName.errors.required && (ContactName.touched || ContactName.dirty)}" />
                                    </div>
                                </div>

                                <div class="col-md-3" *ngIf="formTypeSelection == 'company'">
                                    <div class="form-group" formGroupName="Contact">
                                        <label class="label-control">{{'General_Title' | translate | titlecase}}</label>
                                        <input type="text" class="form-control" formControlName="Title"
                                            autocomplete="off" maxlength="50"
                                            [ngClass]="{'is-valid': !ContactTitle.invalid && (ContactTitle.touched || ContactTitle.dirty), 'is-invalid': ContactTitle.invalid && ContactTitle.errors.required && (ContactTitle.touched || ContactTitle.dirty)}" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group" formGroupName="Contact">
                                        <label class="label-control">{{'General_Email' | translate | titlecase}}</label>
                                        <input type="email" class="form-control" formControlName="Email"
                                            autocomplete="off" maxlength="50"
                                            [ngClass]="{'is-valid': !ContactEmail.invalid && (ContactEmail.touched || ContactEmail.dirty), 'is-invalid': ContactEmail.invalid && ContactEmail.errors.required && (ContactEmail.touched || ContactEmail.dirty)}" />
                                    </div>
                                </div>
                                <!-- <div class="col">
                                    <div class="form-group" formGroupName="Contact">
                                        <label class="label-control">{{'General_Phone' | translate | titlecase}}</label>
                                        <input type="email" class="form-control" formControlName="Phone" autocomplete="off"
                                            required maxlength="50"  />
                                    </div>
                                </div> -->
                                <div class="col-md-3">
                                    <div class="form-group" formGroupName="Contact">
                                        <label class="label-control">{{'General_Phone' | translate | titlecase}}</label>

                                        <input type="tel" class="form-control" formControlName="Phone"
                                            [ngClass]="{'is-valid': !ContactPhone.invalid && (ContactPhone.touched || ContactPhone.dirty), 'is-invalid': ContactPhone.invalid && ContactPhone.errors.required && (ContactPhone.touched || ContactPhone.dirty)}" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- end contact person section -->


                        <!-- Register section -->
                        <!-- <h6 class="form-divider">
                            <fa-icon icon="info-circle"></fa-icon> {{'General_CompanyDetails' | translate | titlecase}}
                        </h6> -->
                        <!-- <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <label class="label-control">{{'General_Register' | translate | titlecase}}</label>
                                    <ng-select [clearable]="true" [searchable]="true"
                                        (change)="getRegistrationAuthorities()" [items]="Registers" bindLabel="register"
                                        bindValue="register" formControlName="Register"
                                        [ngClass]="{'is-valid': !companyFormGroup.get('Register').invalid && (companyFormGroup.get('Register').touched || companyFormGroup.get('Register').dirty), 'is-invalid': companyFormGroup.get('Register').invalid && companyFormGroup.get('Register').errors.required && (companyFormGroup.get('Register').touched || companyFormGroup.get('Register').dirty)}">
                                    </ng-select>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <label
                                        class="label-control">{{'General_RegistrationAuthority' | translate | titlecase}}</label>

                                    <ng-select [clearable]="true" [searchable]="true" [items]="Authorities"
                                        bindLabel="authority" bindValue="authority"
                                        formControlName="RegistrationAuthority"></ng-select>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <label
                                        class="label-control">{{'General_RegistrationNumber' | translate | titlecase}}</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'General_RegistrationNumber' | translate | titlecase}}"
                                        formControlName="RegistrationNumber" autocomplete="off" />
                                </div>
                            </div>
                        </div> -->
                        <!-- -- End register section -->

                        <!-- Notary section -->
                        <!-- <h6 class="form-divider">
                            <fa-icon icon="info-circle"></fa-icon> Detalles de registro de la Compañia
                        </h6>
                        <div class="row" *ngIf="formTypeSelection == 'company'">
                            <div class="col">
                                <div class="form-group" formGroupName="Notary">
                                    <label
                                        class="label-control">Notario de</label>

                                    <ng-select [clearable]="true"
                                               [searchable]="true" [items]="NotaryFromList"
                                               bindLabel="name" bindValue="name"
                                               [ngClass]="{'is-invalid':
                                                                NotaryState.invalid &&
                                                                NotaryState.errors.required &&
                                                                (NotaryState.touched ||NotaryState.dirty),
                                                            'is-valid':
                                                                NotaryState.valid &&
                                                                (NotaryState.touched ||NotaryState.dirty)
                                                          }"
                                                formControlName="State"></ng-select>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group" formGroupName="Notary">
                                    <label class="label-control">Fecha</label>

                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control"
                                                [matDatepicker]="RegistrationDate"
                                                formControlName="RegistrationDate"
                                                [ngClass]="{'is-invalid':
                                                                NotaryRegistrationDate.invalid &&
                                                                NotaryRegistrationDate.errors.required &&
                                                                (NotaryRegistrationDate.touched ||NotaryRegistrationDate.dirty),
                                                            'is-valid':
                                                                NotaryRegistrationDate.valid &&
                                                                (NotaryRegistrationDate.touched ||NotaryRegistrationDate.dirty)
                                                            }">
                                        <mat-datepicker-toggle [for]="RegistrationDate"></mat-datepicker-toggle>

                                        <div class="input-group-append">
                                            <mat-datepicker #RegistrationDate></mat-datepicker>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group" formGroupName="Notary">
                                    <label
                                        class="label-control">Nombre del notario</label>
                                    <input type="text" class="form-control"
                                    [ngClass]="{'is-invalid':
                                                    NotaryName.invalid &&
                                                    NotaryName.errors.required &&
                                                    (NotaryName.touched ||NotaryName.dirty),
                                                'is-valid':
                                                    NotaryName.valid &&
                                                    (NotaryName.touched ||NotaryName.dirty)
                                                }"
                                        formControlName="Name" autocomplete="off" />
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group" formGroupName="Notary">
                                    <label
                                        class="label-control">Número de registro</label>
                                    <input type="text" class="form-control"
                                        [ngClass]="{'is-invalid':
                                                        NotaryRegistrationNumber.invalid &&
                                                        NotaryRegistrationNumber.errors.required &&
                                                        (NotaryRegistrationNumber.touched ||NotaryRegistrationNumber.dirty),
                                                    'is-valid':
                                                        NotaryRegistrationNumber.valid &&
                                                        (NotaryRegistrationNumber.touched ||NotaryRegistrationNumber.dirty)
                                                   }"
                                        formControlName="RegistrationNumber" autocomplete="off" />
                                </div>
                            </div>
                        </div> -->
                        <!-- end notary section -->

                        <!--
                        <div class="row">
                            <div class="col-12">
                                <h6 class="form-divider">
                                    <fa-icon icon="cloud-upload-alt"></fa-icon>
                                    {{'CRM_Company_UploadDocuments' | translate | titlecase}} <b
                                        *ngIf="companyFormGroup.get('Name').value"
                                        style="color: #0497ff;">({{companyFormGroup.get('Name').value}})</b>
                                </h6>

                                <app-file-upload [companyId]='idCompany'
                                    [documents]="companyFormGroup.controls['Documents']"></app-file-upload>
                            </div>
                        </div> -->

                        <!-- Temporally disabled -->
                        <!-- <h6 class="form-divider">
                            <fa-icon icon="info-circle"></fa-icon> {{'General_BankingDetails' | translate | titlecase}}
                        </h6> -->

                        <!-- <div class="row" >
                            <div class="col">
                                <div class="form-group">
                                    <label class="label-control">{{'General_IBAN' | translate | uppercase}}</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'General_IBAN' | translate | uppercase}}" formControlName="IBAN"
                                        autocomplete="off" required maxlength="50"
                                        [ngClass]="{'is-valid': !companyFormGroup.get('IBAN').invalid && (companyFormGroup.get('IBAN').touched || companyFormGroup.get('IBAN').dirty), 'is-invalid': companyFormGroup.get('IBAN').invalid && companyFormGroup.get('IBAN').errors.required && (companyFormGroup.get('IBAN').touched || companyFormGroup.get('IBAN').dirty) || companyFormGroup.get('IBAN').value !== '' && companyFormGroup.get('IBAN').errors !== null}" />

                                    <small class="text-danger"
                                        *ngIf="companyFormGroup.get('IBAN').value !== '' && companyFormGroup.get('IBAN').errors !== null">{{'CRM_Validations_Company_IBAN' | translate}}</small>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group">
                                    <label class="label-control">{{'General_BIC' | translate | uppercase}}</label>
                                    <input type="text" class="form-control"
                                        placeholder="{{'General_BIC' | translate | uppercase}}" formControlName="BIC"
                                        autocomplete="off" required maxlength="20"
                                        [ngClass]="{'is-valid': !companyFormGroup.get('BIC').invalid && (companyFormGroup.get('BIC').touched || companyFormGroup.get('BIC').dirty), 'is-invalid': companyFormGroup.get('BIC').invalid && companyFormGroup.get('BIC').errors.required && (companyFormGroup.get('BIC').touched || companyFormGroup.get('BIC').dirty) || companyFormGroup.get('BIC').value !== '' && companyFormGroup.get('BIC').errors !== null}" />

                                    <small class="text-danger"
                                        *ngIf="companyFormGroup.get('BIC').value !== '' && companyFormGroup.get('BIC').errors !== null">{{'CRM_Validations_Company_BIC' | translate}}</small>
                                </div>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="label-control">{{'General_Comment' | translate | titlecase}}</label>
                                    <textarea type="text" class="form-control" formControlName='Comment'
                                        [ngClass]="{'is-valid': !companyFormGroup.get('Comment').invalid && (companyFormGroup.get('Comment').touched || companyFormGroup.get('Comment').dirty), 'is-invalid': companyFormGroup.get('Comment').invalid && companyFormGroup.get('Comment').errors.required && (companyFormGroup.get('Comment').touched || companyFormGroup.get('Comment').dirty)}"></textarea>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <div class="col">
                                <div class="form-group" formGroupName="Contact">
                                    <label>
                                        <input type="checkbox" formControlName="isSubscribeNewsletter" />
                                        {{'General_Subscribe_Newsletter' | translate }}
                                        {{'General_Optional' | translate }}
                                    </label>
                                </div>
                            </div>

                            <div class="col">
                                <div class="form-group" formGroupName="Contact">
                                    <input type="checkbox" formControlName="termsAndConditions" />
                                    {{'General_AgreeTo' | translate }} <a href="javascript:void(0)"
                                        (click)="showTermsConditions()"> {{'General_TermsConditions' | translate }}</a>
                                    {{'General_AndTheTerms' | translate }} <a href="javascript:void(0)"
                                        (click)="showPrivacyPolicy()">{{'General_Privacy_Policy' | translate }}</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="form--footer text-center">
                    <button type="submit" class="btn btn-sm btn-primary mr-1"
                        onclick="_paq.push(['trackEvent', 'Button cliked', 'Click', 'Request Contracts']);">
                        <i class="far fa-save"></i>
                        {{'CRM_RequestOffer' | translate | titlecase}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
