import { NgxTranslateDebugService } from "ngx-translate-debug";
import { ShortcutInput } from "ng-keyboard-shortcuts";
import { Component } from "@angular/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  public shortcuts: ShortcutInput[] = [];
  public secretAudio = new Audio();

  constructor(private translateDebugService: NgxTranslateDebugService) {
    this.shortcuts.push({
      key: "ctrl + shift + l",
      preventDefault: true,
      command: (e) => this.toggleTranslation(),
    });

    this.secretAudio.src = "/assets/notifications/secret.mp3";
  }

  toggleTranslation(): void {
    this.secretAudio.load();
    this.secretAudio.play();

    this.translateDebugService.toggleDebug();
  }
}
