import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import Swal from "sweetalert2";

import { ModalRepresentativeComponent } from "../dialog-representative/modal-representative.component";
import { IState } from "src/app/models/parameters/IState";
import { ICity } from "src/app/models/parameters/ICity";
import { IPostalCode } from "src/app/models/parameters/IPostalCode";
import { ILegalForm } from "src/app/models/parameters/ILegalForm";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "dialog-dialog-company-history",
  templateUrl: "./dialog-company-history.component.html",
  styleUrls: ["./dialog-company-history.component.scss"],
})
export class DialogCompanyHistoryComponent implements OnInit {
  public formGroup: FormGroup;
  public states: IState[];
  public cities: ICity[];
  public postalCodes: IPostalCode[];
  public legalForms: ILegalForm[];

  constructor(
    public dialogRef: MatDialogRef<DialogCompanyHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    public _dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.data && this.data.history) {
      const history = this.data.history;

      this.formGroup = this._formBuilder.group({
        Name: [history.Name],
        ChangeDate: [history.ChangeDate],
        Address: this._formBuilder.group({
          Country: [history.Address.Country],
          State: [history.Address.State],
          City: [history.Address.City],
          PostalCode: [history.Address.PostalCode],
          StreetNumber: [history.Address.StreetNumber],
        }),
        LegalForm: [history.LegalForm],
        Representatives: this._formBuilder.array([]),
        HasNameChange: [history.HasNameChange],
        HasSeatAddressChange: [history.HasSeatAddressChange],
        HasLegalFormChange: [history.HasLegalFormChange],
        HasRepresentativeChange: [history.HasRepresentativeChange],
      });

      this.setRepresentativesValues(history.Representatives);

      this.getLegalFormsByCountry();
      this.getStatesByCountry();
      this.getCitiesByState();
      this.getPostalCodesByCity();
    } else {
      const selectedCountry = this.data.selectedCountry;
      this.createFormBuilder();

      this.getLegalFormsByCountry(selectedCountry);
    }
  }

  setRepresentativesValues(representativeList: any): void {
    if (!representativeList || representativeList.length < 1) {
      return;
    }

    representativeList.map((representative: any) => {
      this.Representatives.push(
        this._formBuilder.group({
          Name: representative.Name,
          Title: representative.Title,
          IsNaturalPerson: representative.IsNaturalPerson,
          VAT: representative.VAT,
          Address: representative.Address,
        })
      );
    });
  }

  createFormBuilder(): void {
    this.formGroup = this._formBuilder.group({
      Name: [""],
      ChangeDate: [""],
      Address: this._formBuilder.group({
        Country: [""],
        State: [""],
        City: [""],
        PostalCode: [""],
        StreetNumber: [""],
      }),
      LegalForm: [{}],
      Representatives: this._formBuilder.array([], Validators.required),
      HasNameChange: [false],
      HasSeatAddressChange: [false],
      HasLegalFormChange: [false],
      HasRepresentativeChange: [false],
    });
  }

  addRepresentative(): void {
    const dialogRef = this._dialog.open(ModalRepresentativeComponent, {
      width: "900px",
      data: {
        countries: this.data.countries,
        //representative: this.formGroup.controls['Representatives'].value
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.Representatives.push(
          this._formBuilder.group({
            Name: [
              result.RepresentativeName
                ? result.RepresentativeName
                : result.CompanyName,
            ],
            Title: [result.RepresentativeTitle],
            VAT: [result.VAT],
            IsNaturalPerson: [result.IsNaturalPerson],
            Address: [result.Address],
            LegalForm: [result.LegalForm],
          })
        );
      }
    });
  }

  updateRepresentative(index: number) {
    let representative = this.Representatives.value[index];

    const dialogRef = this._dialog.open(ModalRepresentativeComponent, {
      width: "900px",
      data: {
        countries: this.data.countries,
        representative: representative,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let currentList = this.Representatives.value;

        if (!result.IsNaturalPerson) {
          currentList[index] = {
            Name: result.CompanyName,
            Title: result.RepresentativeTitle,
            IsNaturalPerson: result.IsNaturalPerson,
            VAT: result.VAT,
            Address: result.Address,
          };

          this.Representatives.patchValue(currentList);
        } else {
          currentList[index] = {
            Name: result.RepresentativeName,
            Title: result.RepresentativeTitle,
            IsNaturalPerson: result.IsNaturalPerson,
          };

          this.Representatives.patchValue(currentList);
        }

        Swal.fire({
          title: `${this.translate.instant("General_Updated")}`,
          text: `${this.translate.instant(
            "CRM_Representative_Sucess_Updated"
          )}`,
          icon: "success",
          timer: 3000,
        });
      }
    });
  }

  getLegalFormsByCountry(country: string = ""): void {
    let selectedCountry = country
      ? country
      : this.formGroup.controls["Address"].value.Country;

    if (!selectedCountry) {
      return;
    }

    this.legalForms =
      this.data.countries.filter((c) => c.name === selectedCountry)[0]
        .legalForms || [];
  }

  getStatesByCountry(): void {
    let selectedCountry = this.formGroup.controls["Address"].value.Country;

    if (!selectedCountry) {
      return;
    }

    this.states =
      this.data.countries.filter((c) => c.name === selectedCountry)[0].states ||
      [];
  }

  getCitiesByState(): void {
    const state = this.formGroup.controls["Address"].value.State;

    if (!state) {
      return;
    }

    this.cities =
      this.states !== undefined
        ? this.states.filter((c) => c.name === state)[0].cities
        : [];
  }

  getPostalCodesByCity(): void {
    const city = this.formGroup.controls["Address"].value.City;

    if (!city) {
      return;
    }

    this.postalCodes =
      this.cities.filter((c) => c.name === city)[0].postalCodes || [];
  }

  removeRepresentative(index: number): void {
    this.Representatives.removeAt(index);
  }

  get Representatives(): FormArray {
    return this.formGroup.get("Representatives") as FormArray;
  }

  close(): void {
    this.dialogRef.close();
  }
}
