import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IPrivacyPolicyService } from 'src/app/services/privacy-policy/privacy-policy';

@Component({
  selector: 'dialog-dialog-privacy-policy',
  templateUrl: './dialog-privacy-policy.component.html',
  styleUrls: ['./dialog-privacy-policy.component.css']
})
export class DialogPrivacyPolicyComponent implements OnInit {
  public withLabel: boolean = false;
  public privacypolicy$:Observable<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogPrivacyPolicyComponent>,
    public dialog: MatDialog,
    private privacyPolicyService: IPrivacyPolicyService
  ) { }

  ngOnInit(): void {
    if(this.data?.withLabel) {
      this.withLabel = true;
    } else {
      this.getPrivacyPolicy();
    }
  }

  getPrivacyPolicy(): void {
    this.privacypolicy$ = this.privacyPolicyService.getPrivacyPoliciesByLanguage();
  }
}
