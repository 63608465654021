<div class="card-group mb-3" *ngIf="showHeaders">
    <div class="card mr-2">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                        <div>
                            <fa-icon class="card-icon" icon="building"></fa-icon>
                            <p class="font-16 font-weight-bold m-b-5">
                                <span *ngIf="companyFormGroup.controls['FormTypeSelection'].value == 'person'">
                                    {{'General_Name' | translate | titlecase}}
                                </span>

                                <span *ngIf="companyFormGroup.controls['FormTypeSelection'].value == 'company'">
                                    {{'General_Company_Name' | translate}}
                                </span>
                            </p>
                        </div>

                        <div class="ml-auto">
                            <h3 class="font-light text-right">{{company.name}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card mr-2">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                        <div>
                            <fa-icon class="card-icon" icon="id-card-alt"></fa-icon>
                            <p class="font-16 font-weight-bold m-b-5">
                                <span *ngIf="companyFormGroup.controls['FormTypeSelection'].value == 'person'">
                                    {{'General_Id_Number' | translate | uppercase}}
                                </span>

                                <span *ngIf="companyFormGroup.controls['FormTypeSelection'].value == 'company'">
                                    {{'General_VAT' | translate | uppercase}}
                                </span>
                            </p>
                        </div>
                        <div class="ml-auto">
                            <h3 class="font-light text-right">{{company.vat}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card  mr-2">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                        <div>
                            <fa-icon class="card-icon" icon="file-contract"></fa-icon>
                            <p class="font-16 font-weight-bold m-b-5">
                                {{'General_LegalForm' | translate | titlecase}}
                            </p>
                        </div>
                        <div class="ml-auto">
                            <h3 class="font-light text-right">{{company.legalForm}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex no-block align-items-center">
                        <div>
                            <fa-icon class="card-icon" icon="calendar-alt"></fa-icon>
                            <p class="font-16 font-weight-bold m-b-5">{{'General_CreatedDate' | translate | titlecase}}</p>
                        </div>
                        <div class="ml-auto">
                            <h3 class="font-light text-right">{{company.dateCreated | date: 'dd/MM/yyyy'}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="card text-white bg-info mb-4">
    <div class="card-body text-white">
        <h5 class="form-divider">
            <fa-icon icon="cloud-upload-alt"></fa-icon> Documentos
        </h5>
    </div>
</div> -->

<div class="row">
    <div *ngIf="showHeaders" class="col-12 col-sm-6 col-md-4 col-lg-2">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="form-divider">
                    <fa-icon icon="cloud-download-alt"></fa-icon> {{'General_Files' | translate | titlecase}}
                </h5>

                <ul class="list-unstyled">
                    <li class="media mt-4" *ngFor="let item of files">
                        <fa-icon icon="file-pdf" size="2x" class="mr-3 pdf-icon"></fa-icon>

                        <div class="media-body">
                            <h5 class="mt-0 mb-0">{{item.name}}</h5>

                            <small>
                                <a href="{{item.url}}" download="{{item.name}}" target="_blank">
                                    {{'General_Download' | translate | titlecase}}
                                </a>
                            </small>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-12 col-sm-6 col-md-8 col-lg-10">
        <div class="card mb-4">
            <div class="card-body">
                <h5 class="form-divider">
                    <fa-icon icon="cloud-upload-alt"></fa-icon> {{'General_Documents' | translate | titlecase}}
                </h5>

                <app-file-upload [companyId]='companyId' (documentsUploadChage)="updateDocumentsCount($event)" [documentType]="'signedDocument'" [documents]="companyFormGroup.controls['Documents']">
                </app-file-upload>
            </div>
        </div>
    </div>
</div>

<div class="row">
        <div class="col-12 text-center mr-1">
            <button type="button" class="btn btn-rounded btn-success mr-1" title="Completar la carga de documentos"
            (click)="completeUpload()" [disabled]="false">
            <i class="far fa-save"></i> {{'General_CompleteDocumentUpload' | translate}}
        </button>
    </div>
</div>
