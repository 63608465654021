import { CompanyRegistrationEuropeComponent } from "./components/company-registration/company-registration-europe/company-registration-europe.component";
import { CompanyEmailValidationComponent } from "./components/company-registration/company-email-validation/company-email-validation.component";
import { RepresentativeSignatureComponent } from "./components/signature/representative-signature/representative-signature.component";
import { AddLSCCompanyComponent } from "./components/company-registration/LCS-registration/add-company/add-company.component";
import { CompanyDocumentUploadComponent } from "./components/company-document-upload/company-document-upload.component";
import { TermsAndConditionsComponent } from "./components/pages/terms-and-conditions/terms-and-conditions.component";
import { CompanyStatusComponent } from "./components/company-registration/company-status/company-status.component";
import { BecauseSignatureComponent } from "./components/signature/because-signature/because-signature.component";
import { EmailUnsubscribeComponent } from "./components/pages/email-unsubscribe/email-unsubscribe.component";
import { AddCompanyComponent } from "./components/company-registration/add-company/add-company.component";
import { CompanyCreatedComponent } from "./components/company-created/company-created.component";
import { LandingPageComponent } from "./components/home/landing-page/landing-page.component";
import { AboutUsComponent } from "./components/pages/about-us/about-us.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { Routes, RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
  { path: "", component: LandingPageComponent },
  { path: "register", component: AddLSCCompanyComponent },
  { path: "register/europe", component: CompanyRegistrationEuropeComponent },
  { path: "register/:vat/:id", component: AddLSCCompanyComponent },

  { path: "status/:id", component: CompanyStatusComponent },

  { path: "register-2/:id", component: AddCompanyComponent },
  { path: "company", component: AddCompanyComponent },
  { path: "company-validation", component: CompanyEmailValidationComponent },
  { path: "company-documents", component: CompanyDocumentUploadComponent },
  { path: "company-created/:id", component: CompanyCreatedComponent },
  { path: "lsc/add-company", component: AddLSCCompanyComponent },

  { path: "faq", component: FaqComponent },
  { path: "terms-conditions", component: TermsAndConditionsComponent },
  { path: "about-us", component: AboutUsComponent },

  {
    path: "email-unsubscribe/:identifier/:email",
    component: EmailUnsubscribeComponent,
  },

  { path: "signature/:id/:email", component: RepresentativeSignatureComponent },
  {
    path: "be-cause/signature/:companyId",
    component: BecauseSignatureComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
