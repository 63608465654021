import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-faq',
  templateUrl: './landing-page-faq.component.html',
  styleUrls: ['./landing-page-faq.component.scss']
})
export class LandingPageFAQComponent implements OnInit {
  public faqList: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.faqList = [
      {
        title: 'Cardboard_FAQ_1',
        content: 'Cardboard_FAQ_1_Answer',
        open: true
      },
      {
        title: 'Cardboard_FAQ_2',
        content: 'Cardboard_FAQ_2_Answer',
        open: false
      },
      {
        title: 'Cardboard_FAQ_3',
        content: 'Cardboard_FAQ_3_Answer',
        open: false
      },
      {
        title: 'Cardboard_FAQ_4',
        content: 'Cardboard_FAQ_4_Answer',
        open: false
      }
    ];
  }

}
