<div class="header-style-01">
  <nav class="navbar navbar-area navbar-expand-lg nav-style-02">
    <div class="container nav-container">
      <div class="responsive-mobile-menu">
        <div class="btn-wrapper padding-top-30 ms-3 me-3">
          <a [routerLink]="['/register']" class="boxed-btn btn-business" *ngIf="!hideElements?.register">
            {{'Cardboard_Services_CallToAction' | translate}}
          </a>
        </div>

        <button class="navbar-toggler" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="main_menu">
        <ul class="navbar-nav">
          <li class="current-menu-item">
            <div class="logo-wrapper">
              <div class="row">
                <div class="col-6">
                  <img src="/assets/images/logo-because.svg" class="img-fluid mt-2" alt="CDC Logo" style="max-height: 90px;" />
              
              
                </div>
                <div class="col-6 my-auto">
                  <a [routerLink]="['/']" class="logo">
                    <img src="/assets/images/cdc-logo.png" alt="CDC Logo" />
                  </a>
                </div>
              </div>
            </div>
          </li>

          <!-- <li class="current-menu-item">
            <a href="#">
              {{'General_Home' | translate}}
            </a>
          </li> -->

          <li class="menu-item-has-children" *ngIf="!hideElements?.register">
            <a href="#">
              {{'General_Sections' | translate}}
            </a>

            <ul class="sub-menu">
              <li>
                <a href="#title">
                  {{'Cardboard_Services_Title' | translate}}
                </a>
              </li>

              <li>
                <a href="#services">
                  {{'Cardboard_Services_Heading' | translate}}
                </a>
              </li>
            </ul>
          </li>

          <li class="menu-item-has-children">
            <a href="#">
              <span class="flag-icon flag-icon-{{selectedLanguage.flag == 'en' ? 'gb' : selectedLanguage.flag}}"></span>
            </a>

            <ul class="sub-menu">
              <li *ngFor="let lang of listLang">
                <a href="javascript:void(0)" (click)="selectLanguage(lang)">
                  <span class="flag-icon flag-icon-{{lang.flag == 'en' ? 'gb' : lang.flag}}"></span>
                  {{lang.name | translate}}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- <div class="nav-right-content">
                <div class="btn-wrapper">
                    <a [routerLink]="['/register/']" class="boxed-btn btn-business">
                        {{'Cardboard_CallToAction' | translate}}
                    </a>
                </div>
            </div> -->
    </div>
  </nav>
</div>
