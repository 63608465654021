import { ITranslationService } from "src/app/services/translations/translation";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  private _hideElements: any;

  @Input() set hideElements(value: any) {
    this._hideElements = value;
  }

  get hideElements(): any {
    return this._hideElements;
  }

  public selectedLanguage: any = {
    name: "Italian",
    value: "ita",
    flag: "it",
  };

  public includedLanguages = ["en", "it"];
  public listLang: any = [];

  constructor(
    public translate: TranslateService,
    public translationService: ITranslationService,
    public title: Title
  ) {
    this.translate.stream('Cardboard_PageTitle').subscribe((value: string) => {
      this.title.setTitle(value);
    });
  }

  setLanguageLocalStorage(): void {
    let language = localStorage.getItem("cardboard_language");

    if (!language) {
      localStorage.setItem("cardboard_language", "it");
      language = "it";
    }

    this.translate.setDefaultLang(language);
    this.translate.use(language);

    const selectedLanguage = this.listLang.filter(
      (lang: any) => lang.value === language
    )[0];
    if (selectedLanguage) {
      this.selectedLanguage = selectedLanguage;
    }
  }

  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages() {
    this.translationService.getLanguagesList().subscribe((response: any) => {
      const filter = response.filter((lang: any) =>
        this.includedLanguages.includes(lang.value)
      );

      this.listLang = filter.sort((a: any, b: any) => a.name - b.name);

      if(this.listLang) {
        this.listLang = this.listLang.map((language: any) => {
          if(language.value == 'it') {
            language.name = 'Italian';
          }

          if(language.value == 'es') {
            language.name = 'Spanish';
          }

          language.name = 'General_' + language.name.trim();

          return language;
        });
      }

      this.setLanguageLocalStorage();
    });
  }

  selectLanguage(lang: any): void {
    this.selectedLanguage = lang;
    this.translationService.onLanguageChange(lang.value);
    this.translate.use(lang.value);

    localStorage.setItem("cardboard_language", lang.value);
  }
}
