<h1 mat-dialog-title>
    {{'General_ChangesHistory' | translate | titlecase}}
</h1>

<div mat-dialog-content [formGroup]="formGroup">
    <div class="row">
        <div class="col">
            <div class="form-group">
                <label class="label-control">{{'General_Change_Date' | translate | titlecase}}</label>

                <div class="input-group mb-3">
                    <input type="text" class="form-control" [matDatepicker]="ChangeDate" formControlName="ChangeDate">
                    <mat-datepicker-toggle [for]="ChangeDate"></mat-datepicker-toggle>

                    <div class="input-group-append">
                        <mat-datepicker #ChangeDate></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="form-group">
                <div class="pretty p-switch p-fill">
                    <input type="checkbox" formControlName="HasNameChange" />
                    <div class="state p-success">
                        <label>{{'General_Name_Changed' | translate | titlecase}}</label>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="formGroup.get('HasNameChange').value == true">
                <label class="label-control">{{'General_Name' | translate | titlecase}}</label>
                <input type="text" class="form-control" formControlName="Name" placeholder="Name" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="form-group">
                <div class="pretty p-switch p-fill">
                    <input type="checkbox" formControlName="HasSeatAddressChange" />
                    <div class="state p-success">
                        <label>{{'General_Seat_Address_Changed' | translate | titlecase}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="formGroup.get('HasSeatAddressChange').value == true">
        <div class="row">
            <div class="col">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_Country' | translate | titlecase}}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="data.countries" bindLabel="name"
                        bindValue="name" formControlName='Country'
                        (change)="getStatesByCountry(); getLegalFormsByCountry();" appendTo="body"></ng-select>
                </div>
            </div>
    
            <div class="col">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_States' | translate | titlecase}}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="states" bindLabel="name" bindValue="name"
                        formControlName='State' (change)="getCitiesByState()" appendTo="body"></ng-select>
                </div>
            </div>
    
            <div class="col">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_Cities' | translate | titlecase}}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="cities" bindLabel="name" bindValue="name"
                        formControlName='City' (change)='getPostalCodesByCity()' appendTo="body"></ng-select>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-4">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_Postal_Code' | translate | titlecase}}</label>
                    <ng-select [clearable]="true" [searchable]="true" [items]="postalCodes" bindLabel="code" bindValue="code"
                        formControlName='PostalCode' appendTo="body"></ng-select>
                </div>
            </div>

            <div class="col-4">
                <div class="form-group" formGroupName='Address'>
                    <label class="label-control">{{'General_Street_Number' | translate | titlecase}}</label>
                    <input type="text" class="form-control" formControlName='StreetNumber'>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="form-group">
                <div class="pretty p-switch p-fill">
                    <input type="checkbox" formControlName="HasLegalFormChange" />
                    <div class="state p-success">
                        <label>{{'General_Legal_Form_Changed' | translate | titlecase}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="formGroup.get('HasLegalFormChange').value == true">
        <div class="col">
            <div class="form-group">
                <label class="label-control">{{'General_Legal_Form' | translate | titlecase}}</label>
                <ng-select [clearable]="true" [searchable]="true" [items]="legalForms" bindLabel="name" formControlName='LegalForm' appendTo="body"></ng-select>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <div class="form-group">
                <div class="pretty p-switch p-fill">
                    <input type="checkbox" formControlName="HasRepresentativeChange" />
                    <div class="state p-success">
                        <label>{{'General_Representatives_Changed' | translate | titlecase}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="formGroup.get('HasRepresentativeChange').value == true">
        <div class="row">
            <div class="col">
                <button type="button" class="btn btn-sm btn-success mb-2" (click)="addRepresentative()">
                    {{'General_Representatives' | translate | titlecase}}
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <table class="table table-sm table-hover table-bordered table-striped">
                        <thead class="thead-dark">
                            <th scope="col">#</th>
                            <th scope="col">{{'General_Name' | translate | titlecase}}</th>
                            <th scope="col">{{'General_IsNaturalPerson' | translate | titlecase}}</th>
                            <th></th>
                        </thead>

                        <tbody formArrayName="Representatives">
                            <tr>
                                <td class="text-center" colspan="4" *ngIf="Representatives.controls.length < 1">
                                    {{'CRM_Validations_Company_TableRepresentatives' | translate }}
                                </td>
                            </tr>

                            <tr *ngFor="let representative of Representatives.controls; let i=index" [formGroupName]="i">
                                <th scope="row">{{i + 1}}</th>
                                <td>
                                    <span>{{representative.get('Name').value}}</span>
                                </td>
                                <td>
                                    <span>{{representative.get('IsNaturalPerson').value == true ? 'Yes' : 'No'}}</span>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-xs btn-rounded btn-info mr-1" title="Update"
                                        (click)="updateRepresentative(i)">
                                        <fa-icon icon="pencil-alt"></fa-icon>
                                    </button>

                                    <button type="button" class="btn btn-xs btn-rounded btn-danger" title="Remove"
                                        (click)="removeRepresentative(i)">
                                        <fa-icon icon="trash"></fa-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="close()">
        {{'General_Cancel' | translate | titlecase}}
    </button>

    <button mat-button color="primary" [mat-dialog-close]="formGroup.value" cdkFocusInitial>
        {{'General_Save' | translate | titlecase}}
    </button>
</div>