<div class="container mt-3">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="p-4">
                                <img src="/assets/images/illustrations/chat.svg" alt="Success" class="img-fluid" />
                            </div>
                        </div>

                        <div class="col-12 col-sm-9">
                            <h1>
                                {{'General_Thanks' | translate | titlecase}}
                            </h1>

                            <div class="alert alert-success p-3" role="alert">
                                <h2 class="mb-2">
                                    {{'CRM_Company_Sucess_Created' | translate }}
                                </h2>

                                <p class="text-justify">
                                    {{'CRM_Company_Created_Message' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>