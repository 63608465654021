import { environment } from "src/environments/environment";
import { catchError, map, retry } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { throwError, Observable } from "rxjs";
import { IProjectService } from "./project";
import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class ProjectService implements IProjectService {
  constructor(
    private _http: HttpClient,
    private translateService: TranslateService
  ) {}

  getProjectById(id: String): Observable<any> {
    return this._http
      .get<any>(`${environment.API_URL}/api/projects/getProjectById?id=${id}`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getAllProjects(): Observable<any> {
    return this._http
      .get<any>(`${environment.API_URL}/api/projects/getAllProjects`)
      .pipe(retry(1), catchError(this.handleError));
  }

  getProjectIdByName(name: string): Observable<any> {
    return this._http
      .get<any>(
        `${environment.API_URL}/api/projects/getProjectIdByName?name=${name}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.errors.join(", "),
            });
          }
        })
      )
      .pipe(catchError(this.handleError));
  }

  // Error handling
  handleError(error: any): any {
    var id = Sentry.captureException(error);
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    Swal.fire({
      title: `Error`,
      text: `Report id: ${id} \n ${errorMessage}`,
      icon: "error",
    });

    return throwError(errorMessage);
  }
}
