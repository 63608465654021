import { Observable } from 'rxjs';
import { IRegister } from 'src/app/models/parameters/IRegister';


export abstract class IRegisterService {
    abstract getRegisters(): Observable<IRegister[]>;
    abstract getRegisterById(id: string): Observable<IRegister>;
    abstract getRegistersByCountry(country: string): Observable<any>;
    abstract saveRegister(register: IRegister): Observable<number>;
    abstract updateRegister(register: IRegister): Observable<any>;
    abstract deleteRegister(id: string): Observable<any>;
}
