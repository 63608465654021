<h1 mat-dialog-title>
    {{'General_TermsConditions' | translate}}
</h1>

<div mat-dialog-content>
    <section *ngIf="!withLabel">
        <div [innerHTML]="(termsCondition$ | async)?.data.content"></div>
    </section>

    <section *ngIf="withLabel">
        <span [innerHTML]="data.label | translate"></span>
    </section>
</div>

<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{'General_Close' | translate}}
    </button>
</div>