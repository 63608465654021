<h1 mat-dialog-title>
    {{'General_Representatives' | translate | titlecase}}
</h1>

<form mat-dialog-content [formGroup]="formGroup">
    <!-- <ui-add-representative [representativeList]="{}" [Countries]="[]" [Registers]="[]"></ui-add-representative> -->
</form>

<div mat-dialog-actions align="end">
    <button mat-button (click)="close()">
        {{'General_Close' | translate | titlecase}}
    </button>
</div>
