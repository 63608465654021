<div class="breadcrumb-area mb-5">
  <div class="shape"></div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb-inner text-justify">
          <p class="page-title" [innerHTML]="'SCALE_LSCWelcomeMessage' | translate">
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-header [hideElements]="{ register: true }"></app-header>

<main class="container-xl mt-1">
  <section class="row">
    <div class="col-12">
      <div class="card shadow-lg mb-5">
        <div class="card-body pt-4 pb-5">
          <section class="container-fluid">
            <div class="col-12 mx-auto">
              <section class="mt-2">
                <form class="container" [formGroup]="companyFormGroup" (ngSubmit)="onSubmit()">
                  <div class="col-12">
                    <div class="mb-3">
                      <mat-form-field class="col-12">
                        <mat-label>{{'General_Country' | translate}}</mat-label>
                        <mat-select formControlName="country" (selectionChange)="setLegalFormList()">
                          <mat-option *ngFor="let country of countries" [value]="country">
                            <span class="me-1 flag-icon flag-icon-{{country.iso == 'en' ? 'gb' : country.iso}}"></span>
                            {{'General_' + country.name | translate}}
                          </mat-option>

                          <mat-select-trigger>
                            <span
                              class="me-1 flag-icon flag-icon-{{companyFormGroup.value?.country.iso == 'en' ? 'gb' : companyFormGroup.value?.country.iso}}"></span>
                            {{companyFormGroup.value?.country.name}}
                          </mat-select-trigger>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="mb-3">
                          <mat-form-field class="col-12">
                            <mat-label>{{'General_Company_Name' | translate}}</mat-label>
                            <input matInput formControlName="name" autocomplete="off"
                              (change)="removeLegalFormFromCompanyName()" />
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="mb-3">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-11">
                              <mat-form-field class="col-12" *ngIf="!createLegalForm">
                                <mat-label>{{'General_LegalForm' | translate}}</mat-label>
                                <mat-select formControlName="legalForm" [disabled]="completed.cardboardRepresentatives">
                                  <mat-option *ngFor="let legalForm of legalForms" [value]="legalForm.name">
                                    <strong>{{legalForm.name}}</strong><span
                                      *ngIf="legalForm.description">&nbsp;-&nbsp;{{legalForm.description}}</span>
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>

                              <section class="row" *ngIf="createLegalForm">
                                <div class="col-12 col-sm-12 col-md-4">
                                  <mat-form-field class="col-12">
                                    <mat-label>{{'General_Siglas' | translate}}</mat-label>
                                    <input matInput placeholder="e.x S.A" formControlName="createLegalFormName" />
                                  </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-7">
                                  <mat-form-field class="col-12">
                                    <mat-label>{{'General_Description' | translate}}</mat-label>
                                    <input matInput placeholder="e.x Sociedad Anonima"
                                      formControlName="createLegalFormDescription" />
                                  </mat-form-field>
                                </div>

                                <div class="col-12 col-sm-12 col-md-1 text-center my-auto">
                                  <button type="button" mat-icon-button color="primary" (click)="submitLegalForm()"
                                    [disabled]="!companyFormGroup?.value?.createLegalFormName || !companyFormGroup?.value?.createLegalFormDescription">
                                    <mat-icon>save</mat-icon>
                                  </button>
                                </div>
                              </section>
                            </div>

                            <div class="col-12 col-sm-12 col-md-1 text-center my-auto">
                              <button type="button" mat-icon-button color="primary"
                                [disabled]="!companyFormGroup.value?.country"
                                (click)="createLegalForm = !createLegalForm">
                                <mat-icon *ngIf="!createLegalForm">add_circle_outline</mat-icon>
                                <mat-icon *ngIf="createLegalForm">remove_circle_outline</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="label-control">
                        <span>
                          {{'General_VAT' | translate}} <span class="text-danger">*</span>
                        </span>
                      </label>

                      <div class="input-group">
                        <span class="input-group-text">
                          {{companyFormGroup.value?.country?.iso ? companyFormGroup.value?.country?.iso?.toUpperCase() :
                          '-'}}
                        </span>

                        <input id="vatInput" type="text" class="form-control"
                          oninput="this.value = this.value.toUpperCase()"
                          [ngClass]="{'is-valid': !companyFormGroup.get('vat').invalid &&
                                  (companyFormGroup.get('vat').touched || companyFormGroup.get('vat').dirty),
                                  'is-invalid': companyFormGroup.get('vat').invalid && (companyFormGroup.get('vat').errors.required || companyFormGroup.get('vat').errors?.invalidVAT)
                                                                                    && (companyFormGroup.get('vat').touched || companyFormGroup.get('vat').dirty)}"
                          formControlName="vat" (change)="removeCharacters($event)" maxlength="11" />
                      </div>

                      <small class="text-danger">
                        <span *ngIf="companyFormGroup?.controls?.vat?.errors?.invalidVAT">
                        {{'CRM_Validations_Company_VAT_false' | translate}}
                        </span>
                      </small>
                    </div>

                    <h6 class="title-border">
                      <fa-icon icon="map-marker-alt"></fa-icon>
                      {{'General_Address' | translate }}
                      <fa-icon class="cursor--help text-muted" style="vertical-align: middle;" icon="question-circle"
                        matTooltip="{{'SCALE_AddressTooltip' | translate}}" [matTooltipPosition]="'right'"></fa-icon>
                    </h6>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label class="label-control">
                            {{'General_Street' | translate }}
                          </label>
                          <span class="text-danger"> *</span>

                          <input type="text" class="form-control" formControlName="street" autocomplete="off"
                            [ngClass]="{'is-valid': !companyFormGroup.get('street').invalid && (companyFormGroup.get('street').touched || companyFormGroup.get('street').dirty), 'is-invalid': companyFormGroup.get('street').invalid && companyFormGroup.get('street').errors.required && (companyFormGroup.get('street').touched || companyFormGroup.get('street').dirty)}" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-3">
                        <div class="mb-3">
                          <label class="label-control">
                            {{'General_Number' | translate }}
                          </label>

                          <input type="text" class="form-control" formControlName="number" autocomplete="off" />
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-3">
                        <div class="mb-3">
                          <label class="label-control">
                            {{'General_PostalCode' | translate}}
                          </label>
                          <span class="text-danger"> *</span>

                          <input id="postalCodeInput" type="text" class="form-control" formControlName="postalCode"
                            autocomplete="off" (change)="removeCharacters($event)" maxlength="5"
                            [ngClass]="{'is-valid': !companyFormGroup.get('postalCode').invalid && (companyFormGroup.get('postalCode').touched || companyFormGroup.get('postalCode').dirty), 'is-invalid': companyFormGroup.get('postalCode').invalid && companyFormGroup.get('postalCode').errors.required && (companyFormGroup.get('postalCode').touched || companyFormGroup.get('postalCode').dirty)}" />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="mb-3">
                          <mat-form-field class="col-12">
                            <mat-label>{{'General_Province' | translate}}</mat-label>
                            <mat-select formControlName="state" [disabled]="completed.cardboardRepresentatives">
                              <mat-option *ngFor="let item of provinces" [value]="item">
                                <span *ngIf="item.initial"><strong>{{item.initial}}</strong> -</span> {{item.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-6">
                        <div class="mb-3">
                          <label class="label-control">
                            {{'General_City' | translate}}
                          </label>
                          <span class="text-danger"> *</span>

                          <input type="text" class="form-control" formControlName="city" autocomplete="off"
                            [ngClass]="{'is-valid': !companyFormGroup.get('city').invalid && (companyFormGroup.get('city').touched || companyFormGroup.get('city').dirty), 'is-invalid': companyFormGroup.get('city').invalid && companyFormGroup.get('city').errors.required && (companyFormGroup.get('city').touched || companyFormGroup.get('city').dirty)}" />
                        </div>
                      </div>
                    </div>

                    <h6 class="title-border">
                      <fa-icon icon="address-book"></fa-icon>
                      {{'General_ContactPerson' | translate }}
                      <fa-icon class="cursor--help text-muted" style="vertical-align: middle;" icon="question-circle"
                        matTooltip="{{'SCALE_ContactPersonTooltip' | translate}}"
                        [matTooltipPosition]="'right'"></fa-icon>
                    </h6>

                    <div class="mb-3" formGroupName="contact">
                      <mat-form-field class="col-12">
                        <mat-label>
                          {{'General_Title' | translate}}
                        </mat-label>
                        <mat-select formControlName="title" (selectionChange)="setValueToFirstUser('title')">
                          <mat-option *ngFor="let title of titles" [value]="title">
                            {{title | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div class="mb-3" formGroupName="contact">
                      <label class="label-control">
                        {{'General_Name' | translate }}
                      </label> <span class="text-danger"> *</span>
                      <input type="text" class="form-control" formControlName="name" autocomplete="off"
                        (change)="setValueToFirstUser('name')"
                        [ngClass]="{'is-valid': !ContactName.invalid && (ContactName.touched || ContactName.dirty), 'is-invalid': ContactName.invalid && ContactName.errors.required && (ContactName.touched || ContactName.dirty)}" />
                    </div>

                    <div class="mb-3" formGroupName="contact">
                      <label class="label-control">
                        {{'General_LastName' | translate}}
                      </label> <span class="text-danger"> *</span>
                      <input type="text" class="form-control" formControlName="lastName" autocomplete="off"
                        (change)="setValueToFirstUser('lastName')"
                        [ngClass]="{'is-valid': !ContactLastName.invalid && (ContactLastName.touched || ContactLastName.dirty), 'is-invalid': ContactLastName.invalid && ContactLastName.errors.required && (ContactLastName.touched || ContactLastName.dirty)}" />
                    </div>

                    <div class="mb-3" formGroupName="contact">
                      <label class="label-control">
                        {{'General_Email' | translate }}</label> <span class="text-danger">*</span>
                      <input type="email" class="form-control" formControlName="email" autocomplete="off"
                        oninput="this.value = this.value.toLowerCase()" maxlength="50"
                        (change)="setValueToFirstUser('email')"
                        [ngClass]="{'is-valid': !ContactEmail.invalid && (ContactEmail.touched || ContactEmail.dirty), 'is-invalid': ((ContactEmail.touched || ContactEmail.dirty) && (ContactEmail.invalid ))}" />

                      <div *ngIf="ContactEmail.touched && ContactEmail.invalid">
                        <div class="text-danger">
                          {{'General_InvalidEmailMessage' |
                          translate}}</div>
                      </div>
                    </div>

                    <div class="mb-3" formGroupName="contact">
                      <label class="label-control">{{'General_Phone' | translate}}</label> <span
                        class="text-danger">*</span>

                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-3">
                          <mat-form-field class="col-12">
                            <mat-select formControlName="countryPhone" (selectionChange)="setPhoneFlag($event)">
                              <mat-option *ngFor="let phone of phoneCountries" [value]="phone.code">
                                <span
                                  class="me-1 flag-icon flag-icon-{{phone.flag == 'en' ? 'gb' : phone.flag}}"></span>
                                (<strong>{{phone.code}}</strong>) {{phone.name | translate}}
                              </mat-option>

                              <mat-select-trigger *ngIf="selectedPhone">
                                <span
                                  class="me-1 flag-icon flag-icon-{{selectedPhone?.flag == 'en' ? 'gb' : selectedPhone?.flag}}"></span>
                                (<strong>{{selectedPhone?.code}}</strong>) {{selectedPhone?.name}}
                              </mat-select-trigger>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="col-12 col-sm-12 col-md-9">
                          <input type="tel" class="form-control" formControlName="phone"
                            [ngClass]="{'is-valid': !ContactPhone.invalid && (ContactPhone.touched || ContactPhone.dirty),
                                  'is-invalid': ((ContactPhone.touched || ContactPhone.dirty) && (ContactPhone.invalid))}" />
                          <div *ngIf="ContactPhone.touched && ContactPhone.invalid">
                            <div class="text-danger">{{'General_InvalidPhone' |
                              translate}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h6 class="title-border">
                      <fa-icon icon="user-friends"></fa-icon>
                      {{'General_UserList' | translate}} <fa-icon class="cursor--help text-muted"
                        style="vertical-align: middle;" icon="question-circle"
                        matTooltip="{{'SCALE_UserListTooltip' | translate}}" [matTooltipPosition]="'right'">
                      </fa-icon>
                    </h6>

                    <button type="button" mat-flat-button color="success" class="mb-2"
                      *ngIf="!completed.cardboardRepresentatives" (click)="addUserToList()">
                      <mat-icon>add_circle_outline</mat-icon> {{'General_Add_User'
                      | translate}}
                    </button>

                    <table class="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th width="150px">{{'General_Title' | translate }}
                            <span class="text-danger">
                              *</span>
                          </th>
                          <th>{{'General_Name' | translate}}
                            <span class="text-danger">
                              *</span>
                          </th>
                          <th>{{'General_LastName' | translate |
                            titlecase}}
                            <span class="text-danger">
                              *</span>
                          </th>
                          <th>{{'General_Email' | translate}}
                            <span class="text-danger">
                              *</span>
                          </th>
                          <th *ngIf="!completed.cardboardRepresentatives">{{'General_Actions' | translate }}
                          </th>
                        </tr>
                      </thead>

                      <tbody formArrayName="cardboardUsers">
                        <tr *ngFor="let user of cardboardUsers.controls; let i=index" [formGroupName]="i">
                          <td>
                            <mat-form-field class="col-12">
                              <mat-label>{{'General_Title' | translate}}</mat-label>
                              <mat-select formControlName="title" [disabled]="completed.cardboardRepresentatives">
                                <mat-option *ngFor="let title of titles" [value]="title">
                                  {{title | translate}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </td>

                          <td>
                            <input type="text" formControlName="name" class="form-control"
                              [readonly]="completed.cardboardRepresentatives"
                              [ngClass]="{'is-valid': !user.get('name').invalid && (user.get('name').touched || user.get('name').dirty), 'is-invalid':
                                                                      ((user.get('name').touched
                                                                      || user.get('name').dirty) && (user.get('name').invalid ))}" />
                          </td>

                          <td>
                            <input type="text" formControlName="lastName" class="form-control"
                              [readonly]="completed.cardboardRepresentatives"
                              [ngClass]="{'is-valid': !user.get('lastName').invalid && (user.get('lastName').touched || user.get('lastName').dirty), 'is-invalid':
                                                                      ((user.get('lastName').touched
                                                                      || user.get('lastName').dirty) && (user.get('lastName').invalid ))}" />
                          </td>

                          <td>
                            <input type="email" formControlName="email" class="form-control"
                              [readonly]="completed.cardboardRepresentatives"
                              oninput="this.value = this.value.toLowerCase()"
                              [ngClass]="{'is-valid': !user.get('email').invalid && (user.get('email').touched || user.get('email').dirty), 'is-invalid':
                                                                                                          ((user.get('email').touched
                                                                                                          || user.get('email').dirty) && (user.get('email').invalid ))}" />
                            <div *ngIf="user.get('email').touched  && user.get('email').invalid">
                              <div class="text-danger">
                                {{'General_InvalidEmailMessage' |
                                translate}}
                              </div>
                            </div>
                          </td>

                          <td *ngIf="!completed.cardboardRepresentatives">
                            <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                              <mat-icon>more_vert</mat-icon>
                            </button>

                            <mat-menu #menu="matMenu">
                              <button type="button" mat-menu-item (click)="removeCardboardUserElement(user.value, i)">
                                <mat-icon>delete</mat-icon>
                                {{'General_Remove' | translate |
                                titlecase}}
                              </button>
                            </mat-menu>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <hr>

                    <div class="mb-3">
                      <label class="label-control">
                        {{'General_Comment' | translate}}
                      </label>

                      <textarea type="text" class="form-control" formControlName="comment" rows="5"
                        [ngClass]="{'is-valid': !companyFormGroup.get('comment').invalid && (companyFormGroup.get('comment').touched || companyFormGroup.get('comment').dirty), 'is-invalid': companyFormGroup.get('comment').invalid && companyFormGroup.get('comment').errors.required && (companyFormGroup.get('comment').touched || companyFormGroup.get('comment').dirty)}"></textarea>
                    </div>

                    <div class="mb-3">
                      <mat-checkbox formControlName="termsConditions" color="primary">
                        <span (click)="showTermsConditions()">
                          {{'General_Terms_Conditions' | translate}}
                        </span>
                      </mat-checkbox>
                    </div>

                    <div class="mb-3">
                      <mat-checkbox formControlName="privacyPolicy" color="primary">
                        <span (click)="showPrivacyPolicy()">
                          {{'Cars_Privacy' | translate}}
                        </span>
                      </mat-checkbox>
                    </div>

                    <!-- Company registration button -->
                    <button class="cardboard-button col-12" type="submit"
                      [disabled]="companyFormGroup.invalid || cardboardUsers.length < 1"
                      *ngIf="!completed.cardboardRepresentatives">
                      <mat-icon class="mr-2">check_circle</mat-icon>
                      <span *ngIf="!companyFormGroup.value.id">
                        {{'SCALE_RegisterCompany' | translate}}
                      </span>

                      <span *ngIf="companyFormGroup.value.id">
                        {{'SCALE_UpdateCompany' | translate}}
                      </span>
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>
