import { Component, OnInit, EventEmitter, Input, Output, ViewChild, QueryList } from '@angular/core';
import { ILegalForm } from 'src/app/models/parameters/ILegalForm';
import { FormGroup, FormArray, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { IVatValidationObject } from 'src/app/models/company/IVatValidationObject';
import { IState } from 'src/app/models/parameters/IState';
import { IPostalCode } from 'src/app/models/parameters/IPostalCode';
import { IVATService } from 'src/app/services/vat/vat';
import { ICountry } from 'src/app/models/parameters/ICountry';
import { IRegister } from 'src/app/models/parameters/IRegister';
import { ICity } from 'src/app/models/parameters/ICity';
import { IVatValidation } from 'src/app/services/vat-validation/vat-validation';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddRepresentativeComponent } from '../../dialogs/add-representative/add-representative/add-representative.component';
import { IRegisterAuthorities } from 'src/app/models/parameters/IRegisterAuthorities';
import { IRegisterService } from 'src/app/services/register/register';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ITranslationService } from 'src/app/services/translations/translation';
import { INationality } from 'src/app/models/parameters/INationality';
import { FileUploadComponent } from '../../addons/file-upload/file-upload.component';
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: 'app-add-representative',
  templateUrl: './add-representative.component.html',
  styleUrls: ['./add-representative.component.scss']
})
export class AddRepresentativeComponent implements OnInit {
  // @Input() representativeList: FormArray = this.formBuilder.array([]);
  @Input() Countries: ICountry[];
  @Input() Nationalities: INationality[];
  @Input() LangCountries: any;
  @Input() Registers: IRegister[];
  @Input() RepresentativesInputList: FormArray;
  @Input() companyId = '';
  @Input() index: any;
  @Input() ActualLanguage: any;
  @Input() OnlyNaturalPerson: boolean;
  @Input() PersonName: FormControl;
  @Input() NotaryFromList: any[];
  @Output() RepresentativesChildList = new EventEmitter<FormArray>();
  @Output() RepresentativeForm = new EventEmitter<FormGroup>();
  @Output() ErrorSummaryEvent = new EventEmitter<string[]>();
  
  
  @ViewChild('fileUploadRepresentativeID') fileUploadRepresentativeID: FileUploadComponent;
  @ViewChild('fileUploadRepresentativeUploadReresentationNotary') fileUploadRepresentativeUploadReresentationNotary: FileUploadComponent;


  public representativeFormGroup: FormGroup;
  public VatValidation: IVatValidationObject;
  public StatesRepresentatives: IState[];
  public LegalFormsRepresentatives: ILegalForm[];
  public CitiesRepresentatives: ICity[];
  public PostalCodesRepresentatives: IPostalCode[];
  public Authorities: IRegisterAuthorities[];
  public validationErrors: string[];
  // public RepresentativesInDialog: FormArray;

  constructor(
    private formBuilder: FormBuilder,
    private vatService: IVATService,
    private vatValidation: IVatValidation,
    public dialog: MatDialog,
    public registerService: IRegisterService,
    private translate: TranslateService,
    private translationService: ITranslationService
  ) { }

  ngOnInit(): void {
    this.setForm();
    this.initVat();
    this.RepresentativeForm.emit(this.representativeFormGroup);
  }

  setForm() {
    this.representativeFormGroup = this.formBuilder.group({
      Representative: this.formBuilder.group({
        RepresentativeName: ['', Validators.required],
        RepresentativeTitle: ['', Validators.required],
        DNI: [''],
        RepresentativeNationality: ['', Validators.required],
        IsNaturalPerson: [true],
        CompanyName: ['', Validators.required],
        VAT: ['', Validators.required],
        Address: this.formBuilder.group({
          Country: ['', Validators.required],
          State: ['', Validators.required],
          City: ['', Validators.required],
          PostalCode: ['', Validators.required],
          StreetNumber: ['', Validators.required]
        }),
        Notary: this.formBuilder.group({
          State: ['', Validators.required],
          RegistrationDate: ['', Validators.required],
          Name: ['', Validators.required], 
          RegistrationNumber: ['', Validators.required]
        }),
        LegalForm: [{}],
        Register: [''],
        RegistrationAuthority: [''],
        RegistrationNumber: [''],
        Index: [''],
        Representatives: this.formBuilder.array([], Validators.required),
        Documents: this.formBuilder.array([], Validators.required)
      }),
      Representatives: this.formBuilder.array([], Validators.required)
    });
    if (this.RepresentativesInputList.value.length > 0) {
      this.RepresentativesInputList.controls.map(formControlRepresentative => {
        (this.representativeFormGroup.controls.Representatives as FormArray).push(formControlRepresentative);
      });
    }
  }

  initVat() {
    this.VatValidation = {
      valid: null,
      company_address: '',
      company_name: '',
      country_code: '',
      database: '',
      format_valid: false,
      query: '',
      vat_number: ''
    };
  }

  validateVatNumber() {
    const vat = this.representativeFormGroup.controls['VAT'].value;

    if (!vat) {
      return;
    }

    const reVAT = new RegExp('^(AE[0-9]{8}|ATU[0-9]{8}|BE[0-9]{10}|BG[0-9]{9,10}|CY[0-9]{8}[A-Z]|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|EL|GR[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9,A-H,J-N,P-Z]{2}[0-9]{9}|GB[0-9]{9}|HR[0-9]{11}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9,10}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10}|(CH|CHE)(\d{6}|\d{9})(MWST|TVA|IVA))$');
    const isVAT = reVAT.test(vat);
    if (isVAT) {
      this.vatService.getVatByNumber(vat).subscribe((response: any) => {
        if (response.status === 200) {
          if (!response.data) {
            this.vatValidation.validateVAT(vat).subscribe((vatLayer: any) => {
              this.VatValidation = vatLayer;

              if (vatLayer.valid === true) {
                this.saveValidVAT(vatLayer);
              }
            });

            return;
          }

          this.VatValidation = {
            valid: true,
            format_valid: true,
            country_code: response.data.countryCode,
            vat_number: response.data.vatNumber,
            company_name: response.data.companyName,
            company_address: response.data.companyAddress,
            database: '',
            query: ''
          };
        } else {
          var id = Sentry.captureMessage("Error sending VAT to CRM");
          Swal.fire({
            title: `${this.translate.instant('General_Error')}`,
            text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            icon: 'error'
          });
        }
      });
    } else {
      return;
    }
  };
  
  saveValidVAT(vat: any) {
    const validVat = {
      companyName: vat.company_name,
      companyAddress: vat.company_address,
      vatNumber: vat.vat_number,
      countryCode: vat.country_code
    };

    this.vatService.saveVat(validVat).subscribe((response: any) => {
      if (response.status === 200) {
      } else {
        var id = Sentry.captureMessage("Error sending VAT to CRM");
        Swal.fire({
          title: `${this.translate.instant('General_Error')}`,
          text: response.errors.join(','),
          icon: 'error'
        });
      }
    });
  }

  // get representativeArray(): FormArray {
  //   return <FormArray>this.representativeList as FormArray;
  // }

  saveRepresentative(): void {
    this.validationErrors=[];
    const currentRepresentative = this.representativeFormGroup.controls['Representative'].value;
    let isInvalid = false;

    // Natural person
    if (currentRepresentative.IsNaturalPerson) {
      if (this.OnlyNaturalPerson === true) {
        this.representativeFormGroup.get("Representative").get('RepresentativeName').patchValue(this.PersonName.value);
        currentRepresentative.RepresentativeName = this.PersonName.value;
      }

      const representativeNameValidation = this.representativeFormGroup.get("Representative").get("RepresentativeName").value == null || this.representativeFormGroup.get("Representative").get("RepresentativeName").value === '';
      const representativeTitleValidation = this.representativeFormGroup.get("Representative").get("RepresentativeTitle").value == null || this.representativeFormGroup.get("Representative").get("RepresentativeTitle").value === '';
      const representativeNationalityValidation = this.representativeFormGroup.get('Representative').get('RepresentativeNationality').value == null || this.representativeFormGroup.get("Representative").get("RepresentativeNationality").value === '';
      const representativeNotaryState = this.NotaryState.value == null || this.NotaryState.value === '';
      const representativeNotaryDate = this.NotaryRegistrationDate.value == null || this.NotaryRegistrationDate.value === '';
      const representativeNotaryName = this.NotaryName.value == null || this.NotaryName.value === '';
      const representativeNotaryRegistrationNumber = this.NotaryRegistrationNumber.value == null || this.NotaryRegistrationNumber.value === '';

      if (representativeNameValidation) {
        this.representativeFormGroup.get('Representative').get('RepresentativeName').markAsTouched();
        isInvalid = true;
      }

      if (representativeTitleValidation) {
        this.representativeFormGroup.get('Representative').get('RepresentativeTitle').markAsTouched();
        isInvalid = true;
      }

      if(representativeNationalityValidation) {
        this.representativeFormGroup.get('Representative').get('RepresentativeNationality').markAsTouched();
        isInvalid = true;
      }

      if(representativeNotaryState) {
        this.validationErrors.push('missingNotaryState');
        this.NotaryState.markAsTouched();
        isInvalid = true;
      }
      if(representativeNotaryDate) {
        this.validationErrors.push('missingNotaryDate');
        this.NotaryRegistrationDate.markAsTouched();
        isInvalid = true;
      }
      if(representativeNotaryName) {
        this.validationErrors.push('missingNotaryName');
        this.NotaryName.markAsTouched();
        isInvalid = true;
      }
      if(representativeNotaryRegistrationNumber) {
        this.validationErrors.push('missingNotaryRegistrationNumber');
        this.NotaryRegistrationNumber.markAsTouched();
        isInvalid = true;
      }

      this.ErrorSummaryEvent.emit(this.validationErrors);
      if (isInvalid) {
        return;
      }
    } else {
      const companyNameValidation = this.representativeFormGroup.get("Representative").get("CompanyName").value == null
        || this.representativeFormGroup.get("Representative").get("CompanyName").value == '';
      const representativeVatValidation = this.representativeFormGroup.get("Representative").get("VAT").value == null
        || this.representativeFormGroup.get("Representative").get("VAT").value == '';
      const countryValidation = this.representativeFormGroup.get("Representative").get("Address").get("Country").value == null
        || this.representativeFormGroup.get("Representative").get("Address").get("Country").value == '';
      const statesValidation = this.representativeFormGroup.get("Representative").get("Address").get("State").value == null
        || this.representativeFormGroup.get("Representative").get("Address").get("State").value == '';
      const cityValidation = this.representativeFormGroup.get("Representative").get("Address").get("City").value == null
        || this.representativeFormGroup.get("Representative").get("Address").get("City").value == '';
      const postalCodeValidation = this.representativeFormGroup.get("Representative").get("Address").get("PostalCode").value == null
        || this.representativeFormGroup.get("Representative").get("Address").get("PostalCode").value == '';
      const streetNumberValidation = this.representativeFormGroup.get("Representative").get("Address").get("StreetNumber").value == null
        || this.representativeFormGroup.get("Representative").get("Address").get("StreetNumber").value == '';
      const legalFormValidation = this.representativeFormGroup.get("Representative").get("LegalForm").value == null
        || this.representativeFormGroup.get("Representative").get("LegalForm").value == '';

      if (companyNameValidation) {
        this.representativeFormGroup.get("Representative").get("CompanyName").markAsTouched();
        isInvalid = true;
      }

      if (representativeVatValidation) {
        this.representativeFormGroup.get("Representative").get("VAT").markAsTouched();
        isInvalid = true;
      }
      if (countryValidation) {
        this.representativeFormGroup.get("Representative").get("Address").get("Country").markAsTouched();
        isInvalid = true;
      }
      if (statesValidation) {
        this.representativeFormGroup.get("Representative").get("Address").get("State").markAsTouched();
        isInvalid = true;
      }
      if (cityValidation) {
        this.representativeFormGroup.get("Representative").get("Address").get("City").markAsTouched();
        isInvalid = true;
      }
      if (postalCodeValidation) {
        this.representativeFormGroup.get("Representative").get("Address").get("PostalCode").markAsTouched();
        isInvalid = true;
      }
      if (streetNumberValidation) {
        this.representativeFormGroup.get("Representative").get("Address").get("StreetNumber").markAsTouched();
        isInvalid = true;
      }
      if (legalFormValidation) {
        this.representativeFormGroup.get("Representative").get("LegalForm").markAsTouched();
        isInvalid = true;
      }

      if (isInvalid) {
        return;
      }
    }

    if (currentRepresentative.Index !== '') {
      const currentList = this.representativeFormGroup.controls['Representatives'].value;

      if (currentRepresentative.IsNaturalPerson) {
        currentList[currentRepresentative.Index] = {
          RepresentativeName: currentRepresentative.IsNaturalPerson ? currentRepresentative.RepresentativeName : currentRepresentative.CompanyName,
          RepresentativeTitle: currentRepresentative.RepresentativeTitle,
          DNI: currentRepresentative.DNI,
          RepresentativeNationality: currentRepresentative.RepresentativeNationality,
          IsNaturalPerson: currentRepresentative.IsNaturalPerson,
          Documents: currentRepresentative.Documents,
          Notary: currentRepresentative.Notary
        };
      } else {
        currentList[currentRepresentative.Index] = {
          RepresentativeName: currentRepresentative.IsNaturalPerson ? currentRepresentative.RepresentativeName : currentRepresentative.CompanyName,
          RepresentativeTitle: currentRepresentative.RepresentativeTitle,
          IsNaturalPerson: currentRepresentative.IsNaturalPerson,
          VAT: currentRepresentative.VAT,
          Address: currentRepresentative.Address,
          Representatives: currentRepresentative.Representatives,
          Documents: currentRepresentative.Documents,
          Register: currentRepresentative.Register,
          RegistrationAuthority: currentRepresentative.RegistrationAuthority,
          RegistrationNumber: currentRepresentative.RegistrationNumber
          
        };
      }

      this.Representatives.patchValue(currentList);
      this.RepresentativesChildList.emit(this.Representatives);
      this.cleanRepresentative();
      return;
    } else {
      if (currentRepresentative.IsNaturalPerson === true) {
        this.Representatives.push(this.formBuilder.group({
          RepresentativeName: [currentRepresentative.RepresentativeName],
          RepresentativeTitle: [currentRepresentative.RepresentativeTitle],
          DNI: [currentRepresentative.DNI],
          RepresentativeNationality: [currentRepresentative.RepresentativeNationality],
          IsNaturalPerson: [currentRepresentative.IsNaturalPerson],
          Documents: [currentRepresentative.Documents],
          Notary: currentRepresentative.Notary
        }));
      } else {
        this.Representatives.push(this.formBuilder.group({
          RepresentativeName: [currentRepresentative.CompanyName],
          VAT: [currentRepresentative.VAT],
          IsNaturalPerson: [currentRepresentative.IsNaturalPerson],
          Address: [currentRepresentative.Address],
          LegalForm: [currentRepresentative.LegalForm],
          Documents: [currentRepresentative.Documents],
          Register: [currentRepresentative.Register],
          RegistrationAuthority: [currentRepresentative.RegistrationAuthority],
          RegistrationNumber: [currentRepresentative.RegistrationNumber],
          Representatives: currentRepresentative.Representatives && currentRepresentative.Representatives.length > 0 ? currentRepresentative.Representatives : new FormArray([])
        }));
      }
    }

    this.RepresentativesChildList.emit(this.Representatives);
    this.cleanRepresentative();

  }

  updateRepresentative(index: number) {
    const representative = this.Representatives.value[index];

    if (!representative) {
      return;
    }

    if (representative.IsNaturalPerson === true) {
      this.representativeFormGroup.controls.Representative.patchValue({
        RepresentativeName: representative.RepresentativeName,
        RepresentativeTitle: representative.RepresentativeTitle,
        RepresentativeNationality: representative.RepresentativeNationality,
        DNI: representative.DNI,
        IsNaturalPerson: representative.IsNaturalPerson,
        Index: index
      });
    } else {
      this.setRepresentativeToEdit(representative, index);
    }
  }

  setRepresentativeToEdit(representative: any, index: number) {
    const countryValue = representative.Address.Country.value.name;
    const countryTranslationValue = this.Countries.filter(c => c.name === countryValue)[0];
    const countryName = countryTranslationValue.translations.filter(t => t.code === this.ActualLanguage)[0];

    const countryObj = {
      name: countryName.name,
      value: countryTranslationValue
    };

    representative.Address.Country = countryObj;

    this.representativeFormGroup.controls.Representative.patchValue({
      CompanyName: representative.RepresentativeName,
      VAT: representative.VAT,
      IsNaturalPerson: representative.IsNaturalPerson,
      DNI: representative.DNI,
      Address: representative.Address, // representative.Address,
      LegalForm: representative.LegalForm,
      Documents: representative.Documents,
      Register: representative.Register,
      RegistrationAuthority: representative.RegistrationAuthority,
      RegistrationNumber: representative.RegistrationNumber,
      Representatives: representative.Representatives,
      Notary: representative.Notary,
      Index: index
    });

    this.getStatesByCountry();
    this.getLegalFormsByCountry();
    this.getRegistersByCountry();
    this.getCitiesByState();
    this.getPostalCodesByCity();
    this.getRegistrationAuthorities();
  }

  addRepresentativeToRepresentative(index: number) {
    const RepresentativesInDialog = (this.Representatives.at(index).get('Representatives') as FormArray);

    // console.log('representative in dialog', representative.value);
    // const representativeList = <FormArray>this.Representatives.at(index)['controls']['Representatives']; //this.representativeFormGroup.controls['Representatives'].at() [index];
    // this.RepresentativesChild(index).push(this.formBuilder.group({Name:['test']}));

    const dialogRef = this.dialog.open(DialogAddRepresentativeComponent, {
      width: '900px',
      data: {
        index,
        representative: this.RepresentativesChild(index),
        representativesIn: RepresentativesInDialog,
        countries: this.Countries,
        registers: this.Registers,
        companyId: this.companyId,
        nationalities: this.Nationalities,
        NotaryFromList: this.NotaryFromList,
        OnlyNaturalPerson: this.OnlyNaturalPerson
      }
    });

    dialogRef.afterClosed().subscribe((response: any) => {
      console.log(this.representativeFormGroup.value);
      console.log('Closed List', this.Representatives);


      if (response != undefined) {
        (this.Representatives.at(response[1]) as FormGroup).setControl('Representatives', this.formBuilder.array(response[0].value));
      }
      this.RepresentativesChildList.emit(this.Representatives);
    });
  }

  removeRepresentative(index: number) {
    (this.representativeFormGroup.controls.Representatives as FormArray).removeAt(index);
  }

  cleanRepresentative() {
    this.representativeFormGroup.controls.Representative.patchValue({
      RepresentativeName: '',
      RepresentativeTitle: '',
      RepresentativeNationality: '',
      IsNaturalPerson: true,
      CompanyName: '',
      VAT: '',
      DNI: '',
      Address: {
        Country: '',
        State: '',
        City: '',
        PostalCode: '',
        StreetNumber: '',
      },
      LegalForm: {},
      Index: '',
      Documents: [],
      Register: [''],
      RegistrationAuthority: [''],
      RegistrationNumber: [''],
      Notary:{
        Name: '',
        RegistrationNumber: '',
        RegistrationDate:'',
        State:''
      }
    });

    // Clean validations
    this.representativeFormGroup.get('Representative').get('RepresentativeName').markAsUntouched();
    this.representativeFormGroup.get('Representative').get('RepresentativeName').markAsPristine();

    this.representativeFormGroup.get('Representative').get('RepresentativeTitle').markAsUntouched();
    this.representativeFormGroup.get('Representative').get('RepresentativeTitle').markAsPristine();

    this.representativeFormGroup.get('Representative').get('DNI').markAsUntouched();
    this.representativeFormGroup.get('Representative').get('DNI').markAsPristine();

    this.NotaryState.markAsUntouched();
    this.NotaryState.markAsPristine();

    this.NotaryRegistrationDate.markAsUntouched();
    this.NotaryRegistrationDate.markAsPristine();

    this.NotaryName.markAsUntouched();
    this.NotaryName.markAsPristine();

    this.NotaryRegistrationNumber.markAsUntouched();
    this.NotaryRegistrationNumber.markAsPristine();

    this.fileUploadRepresentativeID.clearLocalDocumentsList();
    this.fileUploadRepresentativeUploadReresentationNotary.clearLocalDocumentsList();
  }

  getStatesByCountry() {
    const country = this.representativeFormGroup.controls['Representative'].value.Address.Country.value.name;
    const states = this.Countries.filter(c => c.name === country)[0].states || [];

    this.StatesRepresentatives = states;
  }

  getLegalFormsByCountry() {
    const country = this.representativeFormGroup.controls['Representative'].value.Address.Country.value.name;
    const legalForms = this.Countries.filter(c => c.name === country)[0].legalForms || [];

    this.LegalFormsRepresentatives = legalForms;
  }

  getCitiesByState() {
    const state = this.representativeFormGroup.controls['Representative'].value.Address.State;
    const cities = this.StatesRepresentatives.filter(c => c.name === state)[0].cities || [];

    this.CitiesRepresentatives = cities;
  }

  getPostalCodesByCity() {
    const city = this.representativeFormGroup.controls['Representative'].value.Address.City;
    const postalCodes = this.CitiesRepresentatives.filter(c => c.name === city)[0].postalCodes || [];

    this.PostalCodesRepresentatives = postalCodes;
  }

  getRegistersByCountry() {
    const country = this.representativeFormGroup.controls['Representative'].value.Address.Country.value.name;

    if (!country) {
      return;
    }

    this.registerService.getRegistersByCountry(country).subscribe((response: any) => {
      const registerAuthorities = response && response.length > 0 ? response.map((register: any) => register.registerAuthorities).flat() : [];

      this.Registers = registerAuthorities;
    });
  }

  getRegistrationAuthorities() {
    const register = this.representativeFormGroup.controls['Representative'].value.Register;

    if (!register) {
      return;
    }

    const authorities = [];

    Object.assign([], this.Registers.filter((reg: any) => reg.register === register)).map(reg => {
      reg.authorities.map((auth: any) => {
        authorities.push(auth);
      });
    });

    this.Authorities = authorities;
  }

  get Representatives(): FormArray {
    return this.representativeFormGroup.get('Representatives') as FormArray;
  }

  get Documents(): FormArray {
    return this.representativeFormGroup.get('Representative').get('Documents') as FormArray;
  }

  get Country(): AbstractControl {
    return this.representativeFormGroup.get('Representatives')?.get('Address')?.get('Country');
  }
  //Notary selectors

  get NotaryName(): AbstractControl{
    return this.representativeFormGroup.get(['Representative','Notary', 'Name'])
  }

  get NotaryRegistrationNumber(): AbstractControl{
    return this.representativeFormGroup.get(['Representative','Notary', 'RegistrationNumber'])
  }

  get NotaryRegistrationDate(): AbstractControl{
    return this.representativeFormGroup.get(['Representative','Notary', 'RegistrationDate'])
  }
  get NotaryState(): AbstractControl{
    return this.representativeFormGroup.get(['Representative','Notary', 'State'])
  }
 //--Notary

  RepresentativesChild(index: number): FormArray {
    return this.Representatives.at(index).get('Representatives') as FormArray;
  }

  DocumentsChild(index: number): FormArray {
    return this.Representatives.at(index).get('Documents') as FormArray;
  }
}
