<div class="row">
  <div class="col-12">
    <div class="upload mb-2" (click)="file.click()" appDnd (fileChangeEmiter)="onFileChange($event.target.files)">
      <fa-icon class="col-12" icon="upload" size="3x"></fa-icon>

      <input class="col-12" type="file" multiple #file (change)="onFileChange($event.target.files)"
        style="display:none;" />

      <br />

      {{message | translate}}
    </div>
  </div>

  <div class="col-12">
    <div class="col-12 mt-3" *ngIf="uploadedFiles.length > 0">
      <table style="overflow-x: auto;" class="col-12" mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            {{'General_Name' | translate}}
          </th>

          <td mat-cell *matCellDef="let element">
            {{element.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{'General_Actions' | translate}}
          </th>

          <td mat-cell *matCellDef="let element; let i = index">
            <button (click)="removeFile(i)" class="btn btn-small">
              <fa-icon icon="times" style="color:red"></fa-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!-- <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator> -->
    </div>
  </div>
</div>
