<div class="container">
    <div class="row">
        <div class="col">
            <div class="alert alert-success" role="alert">
                <b>
                    <fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon> Activating company...
                </b>
            </div>
        </div>
    </div>
</div>
