<section class="mb-5">
  <app-header [hideElements]="{ register: true }"></app-header>
</section>

<div class="clearfix"></div>

<br>

<section class="status-container container-fluid mt-5">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-lg">
        <section class="card-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-5 mb-3 full-img--{{currentStatus?.step}}">
              </div>

              <div class="col-12 col-sm-12 col-md-7 px-5">
                <div class="d-flex flex-row mb-3 status">
                  <div class="mx-1 flex-fill text-center rounded-l-lg" [ngClass]="{'status--pending': !stepsCompleted.step1, 'status--completed': stepsCompleted.step1}" matTooltip="{{'General_CompanyInformation' | translate}}">
                    {{'General_CompanyInformation' | translate}}
                  </div>

                  <div class="mx-1 flex-fill text-center status--pending" [ngClass]="{'status--pending': !stepsCompleted.step2, 'status--completed': stepsCompleted.step2}" matTooltip="{{'General_CompanyLegalRepresentative' | translate}}">
                    {{'General_CompanyLegalRepresentative' | translate}}
                    <!-- <div class="justify-center rounded-full circle">
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512"
                        color="white" height="16" width="16" xmlns="http://www.w3.org/2000/svg" style="color: white;">
                        <path
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg>
                    </div> -->
                  </div>

                  <div class="mx-1 flex-fill text-center" [ngClass]="{'status--pending': !stepsCompleted.step3, 'status--completed': stepsCompleted.step3}" matTooltip="{{'General_CooperationAgreement' | translate}}">
                    {{'General_CooperationAgreement' | translate}}
                  </div>

                  <div class="mx-1 flex-fill text-center status--pending rounded-r-lg" [ngClass]="{'status--pending': !stepsCompleted.step4, 'status--completed': stepsCompleted.step4}" matTooltip="{{'General_ScaleAccess' | translate}}">
                    {{'General_ScaleAccess' | translate}}
                  </div>
                </div>

                <h1 class="mb-0">{{currentStatus.title | translate}}</h1>
                <h2>{{currentStatus.description | translate}}</h2>

                <hr>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-4 mb-2">
                    <label>{{'General_DateCreated' | translate}}</label> <br>
                    <strong>{{companyStatus?.company?.dateCreated | date:'medium'}}</strong>
                  </div>

                  <div class="col-12 col-sm-12 col-md-4 mb-2">
                    <label>{{'General_Company' | translate}}</label> <br>
                    <strong>{{companyStatus?.company?.name}}</strong>
                  </div>

                  <div class="col-12 col-sm-12 col-md-4 mb-2">
                    <label>{{'General_VAT' | translate}}</label> <br>
                    <strong>{{companyStatus?.company?.vat}}</strong>
                  </div>
                </div>

                <hr>

                <div class="card-download" style="padding: 1rem;" (click)="downloadTradeRegisterExcerpt()">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-1 my-auto">
                      <img class="img-fluid download-img" src="/assets/images/pdf.png" alt="PDF File" />
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 my-auto text-start">
                      <strong>
                        {{companyStatus?.tradeRegisterExcerpt?.fileName ? companyStatus?.tradeRegisterExcerpt?.fileName : companyStatus?.tradeRegisterExcerpt?.document}}
                      </strong>
                    </div>

                    <div class="col-12 col-sm-12 col-md-2 my-auto text-center">
                      <i class="fas fa-cloud-download-alt"></i>
                    </div>
                  </div>
                </div>

                <hr>

                <section class="row" *ngIf="companyStatus?.stepsCompleted">
                  <div class="col-12 col-sm-12 col-md-6 my-auto">
                    &nbsp;
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 text-end my-auto">
                    <span class="badge rounded-pill text-bg-secondary p-2 cursor--pointer" (click)="currentStatus.details = !currentStatus.details">
                      {{'General_DisplayDetails' | translate}}
                      <fa-icon class="ms-1" icon="chevron-down" *ngIf="!currentStatus.details"></fa-icon>
                      <fa-icon class="ms-1" icon="chevron-up" *ngIf="currentStatus.details"></fa-icon>
                    </span>
                  </div>

                  <div class="col-12 mt-2" *ngIf="currentStatus.details">
                    <article class="details-item mb-3" *ngFor="let step of companyStatus.stepsCompleted">
                      <label>{{'General_Step' | translate}} {{step.step}}: {{step.title | translate}}</label> <br>
                      <strong>{{step.description | translate}}</strong>
                      <br>
                      <time>{{step.dateCreated | date:'medium'}}</time>
                    </article>
                  </div>
                </section>

                <hr *ngIf="companyStatus?.stepsCompleted">

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-6 my-auto">
                    <h2 class="mb-0">Tienes algún problema?</h2>
                    <h1>Contáctanos</h1>
                  </div>

                  <div class="col-12 col-sm-12 col-md-6 text-end my-auto">
                    <span class="badge rounded-pill text-bg-success p-2">
                      Formulario de contacto
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<footer class="text-center">
  {{'General_Footer_CDC_CR' | translate}}
</footer>
