<div class="breadcrumb-area mb-5">
  <div class="shape"></div>
  <div class="container">
    <div class="row">
      <!-- <div class="col-lg-4">
                <div class="breadcrumb-inner">
                    <div class="icon">
                        <i class="far fa-lightbulb"></i>
                        <p>
                            {{'Cardboard_Information' | translate}}
                        </p>
                    </div>
                </div>
            </div> -->

      <div class="col-lg-12">
        <div class="breadcrumb-inner text-justify">
          <p class="page-title" [innerHTML]="'SCALE_LSCWelcomeMessage' | translate">
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-header [hideElements]="{ register: true }"></app-header>

<div class="container-xl mt-1">
  <div class="row">
    <div class="col-12">
      <div class="card shadow-lg mb-5">
        <div class="card-body pt-4 pb-5">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-11 mx-auto">
                <mat-horizontal-stepper [linear]="true" #stepper>
                  <!-- Step 1: Company details -->
                  <mat-step [completed]="completed.companyInformation">
                    <ng-template matStepLabel>
                      {{'Scale_CompanyInformation' | translate}}
                    </ng-template>

                    <section class="mt-2">
                      <form [formGroup]="companyFormGroup" (ngSubmit)="onSubmit()">
                        <div class="col-12">
                          <h6 class="title-border mt-2">
                            <fa-icon icon="address-book"></fa-icon>
                            {{'General_Company' | translate | titlecase}}
                          </h6>
                        </div>

                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label class="label-control">
                                  {{'General_Country' | translate}}
                                </label> <span class="text-danger"> *</span>

                                <input type="text" formControlName="country" class="form-control">
                              </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label class="label-control">{{'IC_Non_Italian_Company' | translate}}</label> <br>
                                <a mat-flat-button color="primary" href="/register/europe" class="col-12">
                                  <mat-icon>public</mat-icon> {{'IC_Non_Italian_Company_Btn' | translate}}
                                </a>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label class="label-control">

                                  <span>
                                    {{'General_Company_Name' | translate}}
                                    <span class="text-danger">*</span>
                                  </span>
                                </label>

                                <input type="text" class="form-control" formControlName="name" autocomplete="off"
                                  data-cy="Company_Name" (change)="removeLegalFormFromCompanyName()"
                                  [ngClass]="{'is-valid': !companyFormGroup.get('name').invalid && (companyFormGroup.get('name').touched || companyFormGroup.get('name').dirty), 'is-invalid': companyFormGroup.get('name').invalid && companyFormGroup.get('name').errors.required && (companyFormGroup.get('name').touched || companyFormGroup.get('name').dirty)}"
                                  required />
                              </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <mat-form-field class="col-12" *ngIf="!completed.companyInformation"
                                  data-cy="legalForm">
                                  <mat-label>{{'General_LegalForm' | translate}}</mat-label>
                                  <mat-select formControlName="legalForm"
                                    [disabled]="completed.cardboardRepresentatives">
                                    <mat-option *ngFor="let legalForm of legalForms" [value]="legalForm.name"
                                      data-cy="legalForm_Option">
                                      <strong>{{legalForm.name}}</strong><span
                                        *ngIf="legalForm.description">&nbsp;-&nbsp;{{legalForm.description}}</span>
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <section *ngIf="completed.companyInformation">
                                  <label class="label-control">
                                    {{'General_LegalForm' | translate}}
                                  </label>

                                  <input type="text" class="form-control" formControlName="legalForm" autocomplete="off"
                                    disabled />
                                </section>
                              </div>
                            </div>
                          </div>

                          <div class="mb-3">
                            <label class="label-control">
                              <span>
                                {{'General_VAT' | translate}} <span class="text-danger">*</span>
                              </span>
                            </label>

                            <div class="input-group">
                              <span class="input-group-text">IT</span>
                              <input id="vatInput" type="text" class="form-control" data-cy="vatInput"
                                oninput="this.value = this.value.toUpperCase()"
                                [ngClass]="{'is-valid': !companyFormGroup.get('vat').invalid &&
                                        (companyFormGroup.get('vat').touched || companyFormGroup.get('vat').dirty),
                                        'is-invalid': companyFormGroup.get('vat').invalid && (companyFormGroup.get('vat').errors.required || companyFormGroup.get('vat').errors.minlength)
                                                                                          && (companyFormGroup.get('vat').touched || companyFormGroup.get('vat').dirty)}"
                                formControlName="vat" (change)="removeCharacters($event)" maxlength="11"
                                minlength="11" />
                              <div
                                [ngClass]="{'valid-feedback': vatValidationObject && vatValidationObject.valid, 'invalid-feedback': vatValidationObject && vatValidationObject.valid == false}">
                                <!-- <div [ngSwitch]="vatValidationObject.valid">
                                                              <span *ngSwitchCase="true">
                                                                {{'CRM_Validations_Company_VAT_true' |
                                                                translate:
                                                                {"value":vatValidationObject.company_name}
                                                                }}
                                                              </span>

                                                              <span *ngSwitchCase="false">
                                                                {{'CRM_Validations_Company_VAT_false' |
                                                                translate }}
                                                              </span>
                                                            </div> -->
                              </div>
                            </div>
                          </div>

                          <h6 class="title-border">
                            <fa-icon icon="map-marker-alt"></fa-icon>
                            {{'General_Address' | translate }}
                            <fa-icon class="cursor--help text-muted" style="vertical-align: middle;"
                              icon="question-circle" matTooltip="{{'SCALE_AddressTooltip' | translate}}"
                              [matTooltipPosition]="'right'"></fa-icon>
                          </h6>

                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label class="label-control">
                                  {{'General_Street' | translate }}
                                </label>
                                <span class="text-danger"> *</span>

                                <input type="text" class="form-control" formControlName="street" autocomplete="off"
                                  *ngIf="!completed.companyInformation" data-cy="Street"
                                  [ngClass]="{'is-valid': !companyFormGroup.get('street').invalid && (companyFormGroup.get('street').touched || companyFormGroup.get('street').dirty), 'is-invalid': companyFormGroup.get('street').invalid && companyFormGroup.get('street').errors.required && (companyFormGroup.get('street').touched || companyFormGroup.get('street').dirty)}" />

                                <input type="text" class="form-control" formControlName="street" autocomplete="off"
                                  *ngIf="completed.companyInformation" disabled />
                              </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-3">
                              <div class="mb-3">
                                <label class="label-control">
                                  {{'General_Number' | translate }}
                                </label>

                                <input type="text" class="form-control" formControlName="number" autocomplete="off"
                                  *ngIf="!completed.companyInformation" data-cy="number" />
                                <input type="text" class="form-control" formControlName="number" autocomplete="off"
                                  *ngIf="completed.companyInformation" disabled />
                              </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-3">
                              <div class="mb-3">
                                <label class="label-control">
                                  {{'General_PostalCode' | translate}}
                                </label>
                                <span class="text-danger"> *</span>

                                <input id="postalCodeInput" type="text" class="form-control"
                                  *ngIf="!completed.companyInformation" data-cy="postalCodeInput"
                                  formControlName="postalCode" autocomplete="off" (change)="removeCharacters($event)"
                                  maxlength="5"
                                  [ngClass]="{'is-valid': !companyFormGroup.get('postalCode').invalid && (companyFormGroup.get('postalCode').touched || companyFormGroup.get('postalCode').dirty), 'is-invalid': companyFormGroup.get('postalCode').invalid && companyFormGroup.get('postalCode').errors.required && (companyFormGroup.get('postalCode').touched || companyFormGroup.get('postalCode').dirty)}" />

                                <input type="text" class="form-control" formControlName="postalCode" autocomplete="off"
                                  *ngIf="completed.companyInformation" disabled />
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <mat-form-field class="col-12" *ngIf="!completed.companyInformation"
                                  data-cy="General_Province">
                                  <mat-label>{{'General_Province' | translate}}</mat-label>
                                  <mat-select formControlName="state" [disabled]="completed.cardboardRepresentatives">
                                    <mat-option *ngFor="let item of provinces" [value]="item" data-cy="Province_Option">
                                      <strong>{{item.initial}}</strong> - {{item.name}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <section *ngIf="completed.companyInformation">
                                  <label>{{'General_Province' | translate}}</label>
                                  <input type="text" class="form-control"
                                    value="({{companyFormGroup?.value?.state?.initial}}) {{companyFormGroup?.value?.state?.name}}"
                                    autocomplete="off" disabled />
                                </section>
                              </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-6">
                              <div class="mb-3">
                                <label class="label-control">
                                  {{'General_City' | translate}}
                                </label>
                                <span class="text-danger"> *</span>

                                <input type="text" class="form-control" formControlName="city" autocomplete="off"
                                  *ngIf="!completed.companyInformation" data-cy="city"
                                  [ngClass]="{'is-valid': !companyFormGroup.get('city').invalid && (companyFormGroup.get('city').touched || companyFormGroup.get('city').dirty), 'is-invalid': companyFormGroup.get('city').invalid && companyFormGroup.get('city').errors.required && (companyFormGroup.get('city').touched || companyFormGroup.get('city').dirty)}" />

                                <input type="text" class="form-control" formControlName="city" autocomplete="off"
                                  *ngIf="completed.companyInformation" disabled />
                              </div>
                            </div>
                          </div>

                          <h6 class="title-border">
                            <fa-icon icon="address-book"></fa-icon>
                            {{'General_ContactPerson' | translate }}
                            <fa-icon class="cursor--help text-muted" style="vertical-align: middle;"
                              icon="question-circle" matTooltip="{{'SCALE_ContactPersonTooltip' | translate}}"
                              [matTooltipPosition]="'right'"></fa-icon>
                          </h6>

                          <div class="mb-3" formGroupName="contact">
                            <mat-form-field class="col-12" *ngIf="!completed.companyInformation"
                              data-cy="General_Title_Contact_Person">
                              <mat-label>
                                {{'General_Title' | translate}}
                              </mat-label>
                              <mat-select formControlName="title" (selectionChange)="setValueToFirstUser('title')">
                                <mat-option *ngFor="let title of titles" [value]="title"
                                  data-cy="General_Title_Contact_Person_Option">
                                  {{title | translate}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                            <input type="text" class="form-control"
                              value="{{companyFormGroup?.value?.contact?.title | translate}}" autocomplete="off"
                              *ngIf="completed.companyInformation" disabled />
                          </div>

                          <div class="mb-3" formGroupName="contact">
                            <label class="label-control">
                              {{'General_Name' | translate}} <span class="text-danger"> *</span>
                            </label>

                            <input type="text" class="form-control" formControlName="name" autocomplete="off"
                              data-cy="contact-name" (change)="setValueToFirstUser('name')"
                              *ngIf="!completed.companyInformation"
                              [ngClass]="{'is-valid': !ContactName.invalid && (ContactName.touched || ContactName.dirty), 'is-invalid': ContactName.invalid && ContactName.errors.required && (ContactName.touched || ContactName.dirty)}" />

                            <input type="text" class="form-control"
                              value="{{companyFormGroup?.value?.contact?.name | translate}}" autocomplete="off"
                              *ngIf="completed.companyInformation" disabled />
                          </div>

                          <div class="mb-3" formGroupName="contact">
                            <label class="label-control">
                              {{'General_LastName' | translate}} <span class="text-danger"> *</span>
                            </label>
                            <input type="text" class="form-control" formControlName="lastName" autocomplete="off"
                              data-cy="contact-lastname" (change)="setValueToFirstUser('lastName')"
                              *ngIf="!completed.companyInformation"
                              [ngClass]="{'is-valid': !ContactLastName.invalid && (ContactLastName.touched || ContactLastName.dirty), 'is-invalid': ContactLastName.invalid && ContactLastName.errors.required && (ContactLastName.touched || ContactLastName.dirty)}" />

                            <input type="text" class="form-control"
                              value="{{companyFormGroup?.value?.contact?.lastName | translate}}" autocomplete="off"
                              *ngIf="completed.companyInformation" disabled />
                          </div>

                          <div class="mb-3" formGroupName="contact">
                            <label class="label-control">
                              {{'General_Email' | translate}} <span class="text-danger">*</span>
                            </label>

                            <input type="email" class="form-control" formControlName="email" autocomplete="off"
                              data-cy="email" oninput="this.value = this.value.toLowerCase()" maxlength="50"
                              (change)="setValueToFirstUser('email')" *ngIf="!completed.companyInformation"
                              [ngClass]="{'is-valid': !ContactEmail.invalid && (ContactEmail.touched || ContactEmail.dirty), 'is-invalid': ((ContactEmail.touched || ContactEmail.dirty) && (ContactEmail.invalid ))}" />

                            <input type="text" class="form-control"
                              value="{{companyFormGroup?.value?.contact?.email | translate}}" autocomplete="off"
                              *ngIf="completed.companyInformation" disabled />

                            <div *ngIf="ContactEmail.touched && ContactEmail.invalid">
                              <div class="text-danger">
                                {{'General_InvalidEmailMessage' |
                                translate}}</div>
                            </div>
                          </div>

                          <div class="mb-3" formGroupName="contact">
                            <label class="label-control">
                              {{'General_Phone' | translate}} <span class="text-danger">*</span>
                            </label>

                            <div class="row">
                              <div class="col-12 col-sm-12 col-md-3">
                                <mat-form-field class="col-12" *ngIf="!completed.companyInformation">
                                  <mat-select formControlName="countryPhone">
                                    <mat-option *ngFor="let phone of phoneCountries" [value]="phone.code">
                                      <span
                                        class="me-1 flag-icon flag-icon-{{phone.flag == 'en' ? 'gb' : phone.flag}}"></span>
                                      (<strong>{{phone.code}}</strong>) {{phone.name | translate}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>

                                <input type="text" class="form-control"
                                  value="{{companyFormGroup?.value?.contact?.countryPhone}}" autocomplete="off"
                                  *ngIf="completed.companyInformation" disabled />
                              </div>

                              <div class="col-12 col-sm-12 col-md-9">
                                <input type="tel" class="form-control" formControlName="phone"
                                  *ngIf="!completed.companyInformation" data-cy="phone"
                                  [ngClass]="{'is-valid': !ContactPhone.invalid && (ContactPhone.touched || ContactPhone.dirty), 'is-invalid': ((ContactPhone.touched || ContactPhone.dirty) && (ContactPhone.invalid))}" />

                                <div *ngIf="ContactPhone.touched && ContactPhone.invalid">
                                  <div class="text-danger">{{'General_InvalidPhone' |
                                    translate}}
                                  </div>
                                </div>

                                <input type="text" class="form-control" formControlName="phone" autocomplete="off"
                                  *ngIf="completed.companyInformation" disabled />
                              </div>
                            </div>
                          </div>

                          <h6 class="title-border">
                            <fa-icon icon="user-friends"></fa-icon>
                            {{'General_UserList' | translate}} <fa-icon class="cursor--help text-muted"
                              style="vertical-align: middle;" icon="question-circle"
                              matTooltip="{{'SCALE_UserListTooltip' | translate}}" [matTooltipPosition]="'right'">
                            </fa-icon>
                          </h6>

                          <button type="button" mat-flat-button color="success" class="mb-2"
                            *ngIf="!completed.companyInformation" (click)="addUserToList()">
                            <mat-icon>add_circle_outline</mat-icon> {{'General_Add_User'
                            | translate}}
                          </button>

                          <table class="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th width="150px">{{'General_Title' | translate }}
                                  <span class="text-danger">
                                    *</span>
                                </th>
                                <th>{{'General_Name' | translate}}
                                  <span class="text-danger">
                                    *</span>
                                </th>
                                <th>{{'General_LastName' | translate |
                                  titlecase}}
                                  <span class="text-danger">
                                    *</span>
                                </th>
                                <th>{{'General_Email' | translate}}
                                  <span class="text-danger">
                                    *</span>
                                </th>
                                <th *ngIf="!completed.companyInformation">{{'General_Actions' | translate }}
                                </th>
                              </tr>
                            </thead>

                            <tbody formArrayName="cardboardUsers">
                              <tr *ngFor="let user of cardboardUsers.controls; let i=index" [formGroupName]="i">
                                <td>
                                  <mat-form-field class="col-12" *ngIf="!completed.companyInformation">
                                    <mat-label>{{'General_Title' | translate}}</mat-label>
                                    <mat-select formControlName="title" [disabled]="completed.cardboardRepresentatives">
                                      <mat-option *ngFor="let title of titles" [value]="title">
                                        {{title | translate}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <input type="text" class="form-control" value="{{user?.value?.title | translate}}"
                                    autocomplete="off" *ngIf="completed.companyInformation" disabled />
                                </td>

                                <td>
                                  <input type="text" formControlName="name" class="form-control" data-cy="user-name"
                                    [readonly]="completed.cardboardRepresentatives"
                                    *ngIf="!completed.companyInformation"
                                    [ngClass]="{'is-valid': !user.get('name').invalid && (user.get('name').touched || user.get('name').dirty), 'is-invalid':
                                                                            ((user.get('name').touched
                                                                            || user.get('name').dirty) && (user.get('name').invalid ))}" />

                                  <input type="text" class="form-control" formControlName="name" autocomplete="off"
                                    *ngIf="completed.companyInformation" disabled />
                                </td>

                                <td>
                                  <input type="text" formControlName="lastName" class="form-control"
                                    data-cy="user-lastname" [readonly]="completed.cardboardRepresentatives"
                                    *ngIf="!completed.companyInformation"
                                    [ngClass]="{'is-valid': !user.get('lastName').invalid && (user.get('lastName').touched || user.get('lastName').dirty), 'is-invalid':
                                                                            ((user.get('lastName').touched
                                                                            || user.get('lastName').dirty) && (user.get('lastName').invalid ))}" />

                                  <input type="text" class="form-control" formControlName="lastName" autocomplete="off"
                                    *ngIf="completed.companyInformation" disabled />
                                </td>

                                <td>
                                  <input type="email" formControlName="email" class="form-control" data-cy="user-email"
                                    [readonly]="completed.cardboardRepresentatives"
                                    *ngIf="!completed.companyInformation"
                                    oninput="this.value = this.value.toLowerCase()"
                                    [ngClass]="{'is-valid': !user.get('email').invalid && (user.get('email').touched || user.get('email').dirty), 'is-invalid':
                                                                                                                ((user.get('email').touched
                                                                                                                || user.get('email').dirty) && (user.get('email').invalid ))}" />
                                  <div *ngIf="user.get('email').touched  && user.get('email').invalid">
                                    <div class="text-danger">
                                      {{'General_InvalidEmailMessage' |
                                      translate}}
                                    </div>
                                  </div>

                                  <input type="text" class="form-control" formControlName="email" autocomplete="off"
                                    *ngIf="completed.companyInformation" disabled />
                                </td>

                                <td *ngIf="!completed.companyInformation">
                                  <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                  </button>

                                  <mat-menu #menu="matMenu">
                                    <button type="button" mat-menu-item
                                      (click)="removeCardboardUserElement(user.value, i)">
                                      <mat-icon>delete</mat-icon>
                                      {{'General_Remove' | translate |
                                      titlecase}}
                                    </button>
                                  </mat-menu>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <hr>

                          <div class="mb-3">
                            <label class="label-control">
                              {{'General_Comment' | translate}}
                            </label>

                            <textarea class="form-control" formControlName="comment" rows="5"
                              *ngIf="!completed.companyInformation" data-cy="comment"
                              [ngClass]="{'is-valid': !companyFormGroup.get('comment').invalid && (companyFormGroup.get('comment').touched || companyFormGroup.get('comment').dirty), 'is-invalid': companyFormGroup.get('comment').invalid && companyFormGroup.get('comment').errors.required && (companyFormGroup.get('comment').touched || companyFormGroup.get('comment').dirty)}"></textarea>

                            <textarea class="form-control" formControlName="comment" rows="5" disabled
                              *ngIf="completed.companyInformation"></textarea>
                          </div>

                          <div class="mb-3">
                            <mat-checkbox formControlName="termsConditions" color="primary"
                              data-cy="terms-conditions-checkbox">
                              <span (click)="showTermsConditions()">
                                {{'General_Terms_Conditions' | translate}}
                              </span>
                            </mat-checkbox>
                          </div>

                          <div class="mb-3">
                            <mat-checkbox formControlName="privacyPolicy" color="primary"
                              data-cy="privacy-policy-checkbox">
                              <span (click)="showPrivacyPolicy()">
                                {{'Cars_Privacy' | translate}}
                              </span>
                            </mat-checkbox>
                          </div>

                          <button type="submit" class="cardboard-button col-12" data-cy="btn-submit1"
                            [disabled]="companyFormGroup.invalid || cardboardUsers.length < 1"
                            *ngIf="!completed.companyInformation">
                            <mat-icon class="mr-2">check_circle</mat-icon>
                            <span *ngIf="!companyFormGroup.value.id">
                              {{'SCALE_RegisterCompany' | translate}}
                            </span>

                            <span *ngIf="companyFormGroup.value.id">
                              {{'SCALE_UpdateCompany' | translate}}
                            </span>
                          </button>
                        </div>
                      </form>
                    </section>
                  </mat-step>

                  <!-- Step 2: Add representatives and trade register excerpt -->
                  <mat-step [completed]="completed.cardboardRepresentatives">
                    <ng-template matStepLabel>
                      {{'Scale_Representatives' | translate}}
                    </ng-template>

                    <section class="mt-2">
                      <form class="container" (ngSubmit)="uploadContract()" [formGroup]="uploadContractFormGroup">
                        <div class="col-12 mx-auto">
                          <div class="mt-2" *ngIf="!tradeRegisterExcerpt">
                            <label class="form-label">
                              {{'General_TradeRegisterExcerpt' | translate}}
                            </label> <br>

                            <smartec-file-upload [message]="'General_DnDMessage'" [limit]="1"
                              (files)="getUploadedFiles($event)"></smartec-file-upload>

                            <!-- <input class="form-control form-control-lg" type="file" #inputFile formControlName="file"
                                                          (change)="handleFile($event)"
                                                          accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" /> -->
                          </div>

                          <div *ngIf="tradeRegisterExcerpt">
                            <div class="card-download" style="padding: 1rem;" (click)="downloadTradeRegisterExcerpt()">
                              <div class="row">
                                <div class="col-12 col-sm-12 col-md-1 my-auto">
                                  <img class="img-fluid download-img" src="/assets/images/pdf.png" alt="PDF File" />
                                </div>

                                <div class="col-12 col-sm-12 col-md-9 my-auto text-start">
                                  <strong>
                                    {{tradeRegisterExcerpt?.displayName ?
                                    tradeRegisterExcerpt?.displayName :
                                    tradeRegisterExcerpt?.fullName}}
                                  </strong>
                                </div>

                                <div class="col-12 col-sm-12 col-md-2 my-auto text-center">
                                  <i class="fas fa-cloud-download-alt"></i>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mt-4 mb-4">
                            <div class="row">
                              <div class="col-12">
                                <button type="button" mat-flat-button *ngIf="!completed.cardboardRepresentatives"
                                  data-cy="representative_add_button" color="success" class="mb-2"
                                  (click)="addRepresentative()">
                                  <mat-icon>add_circle_outline</mat-icon>
                                  {{'General_AddRepresentative'
                                  | translate}}
                                </button>

                                <table class="table table-striped table-hover">
                                  <thead>
                                    <tr>
                                      <th width="150px">
                                        {{'General_Title' | translate}}
                                        <span class="text-danger">*</span>
                                      </th>
                                      <th width="190px">
                                        {{'General_BusinessTitle' | translate}}
                                        <span class="text-danger">*</span>
                                      </th>
                                      <th>
                                        {{'General_Name' | translate}}
                                        <span class="text-danger">*</span>
                                      </th>
                                      <th>
                                        {{'General_LastName' | translate}}
                                        <span class="text-danger">*</span>
                                      </th>
                                      <th>
                                        {{'General_Email' | translate}}
                                        <span class="text-danger">*</span>
                                      </th>
                                      <th>
                                        {{'General_Phone' | translate}}
                                        <span class="text-danger">*</span>
                                      </th>
                                      <th *ngIf="!completed.cardboardRepresentatives">
                                        {{'General_Actions' | translate
                                        | titlecase}}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody formArrayName="cardboardRepresentatives">
                                    <tr *ngFor="let representative of cardboardRepresentatives.controls; let i=index"
                                      [formGroupName]="i">
                                      <td>
                                        <mat-form-field class="col-12">
                                          <mat-label>{{'General_Title' | translate}}</mat-label>
                                          <mat-select formControlName="title" data-cy="representative_title"
                                            [disabled]="completed.cardboardRepresentatives">
                                            <mat-option *ngFor="let title of titles" [value]="title"
                                              data-cy="representative_title_option">
                                              {{title | translate}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </td>
                                      <td>
                                        <mat-form-field class="col-12">
                                          <mat-select formControlName="businessTitle"
                                            data-cy="representative_businessTitle"
                                            [disabled]="completed.cardboardRepresentatives">
                                            <mat-option *ngFor="let title of businessTitles" [value]="title"
                                              data-cy="representative_businessTitle_option">
                                              {{title | translate}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                      </td>
                                      <td>
                                        <input type="text" formControlName="name" class="form-control"
                                          data-cy="representative_name"
                                          [readonly]="completed.cardboardRepresentatives" />
                                      </td>
                                      <td>
                                        <input type="text" formControlName="lastName" class="form-control"
                                          data-cy="representative_lastName"
                                          [readonly]="completed.cardboardRepresentatives" />
                                      </td>
                                      <td>
                                        <input type="email" formControlName="email" class="form-control"
                                          data-cy="representative_email" [readonly]="completed.cardboardRepresentatives"
                                          oninput="this.value = this.value.toLowerCase()"
                                          [ngClass]="{'is-valid': !representative.get('email').invalid && (representative.get('email').touched || representative.get('email').dirty), 'is-invalid': ((representative.get('email').touched || representative.get('email').dirty) && (representative.get('email').invalid ))}" />
                                      </td>
                                      <td>
                                        <mat-form-field class="col-12">
                                          <mat-select formControlName="countryPhone">
                                            <mat-option *ngFor="let phone of phoneCountries" [value]="phone.code">
                                              <span
                                                class="me-1 flag-icon flag-icon-{{phone.flag == 'en' ? 'gb' : phone.flag}}"></span>
                                              (<strong>{{phone.code}}</strong>) {{'General_' + phone.name | translate}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>

                                        <input type="tel" formControlName="phone" class="form-control"
                                          data-cy="representative_phone"
                                          [readonly]="completed.cardboardRepresentatives" />
                                      </td>
                                      <td *ngIf="!completed.cardboardRepresentatives">
                                        <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                                          <mat-icon>more_vert
                                          </mat-icon>
                                        </button>

                                        <mat-menu #menu="matMenu">
                                          <button type="button" mat-menu-item (click)="removeRepresentative(i)">
                                            <mat-icon>delete
                                            </mat-icon>
                                            {{'General_Remove' |
                                            translate | titlecase}}
                                          </button>
                                        </mat-menu>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>

                          <hr class="mb-4">

                          <button type="submit" class="cardboard-button col-12" data-cy="submit-stage2"
                            *ngIf="!completed.cardboardRepresentatives"
                            [disabled]="uploadContractFormGroup.invalid || !file || cardboardRepresentatives.length < 1 || cardboardUsers.length < 1">
                            <mat-icon class="mr-2">check_circle</mat-icon>
                            {{'IC_Send_CA' | translate}}
                          </button>
                        </div>

                        <!-- <div class="col-10 mx-auto">
                              <div class="mt-4 mb-5 dashed-bottom-border">
                                  <div class="row">
                                      <div class="col-12 col-sm-12 col-md-2 my-auto">
                                          <strong class="step-form">1.</strong>
                                      </div>

                                      <div class="col-12 col-sm-12 col-md-10">
                                          <label class="form-label float-start">
                                              {{'Cardboard_DownloadContract_InformationText' |
                                              translate}}
                                          </label>

                                          <div class="card-download"
                                              (click)="downloadContract()">
                                              <div class="row">
                                                  <div
                                                      class="col-12 col-sm-12 col-md-2 my-auto">
                                                      <img class="img-fluid download-img"
                                                          src="/assets/images/pdf.png"
                                                          alt="PDF File" />
                                                  </div>

                                                  <div
                                                      class="col-12 col-sm-12 col-md-8 my-auto text-start">
                                                      <strong>mandato_modelo_576.pdf</strong>
                                                  </div>

                                                  <div
                                                      class="col-12 col-sm-12 col-md-2 my-auto text-center">
                                                      <i
                                                          class="fas fa-cloud-download-alt"></i>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="mt-4 mb-5">
                                  <div class="row">
                                      <div class="col-12 col-sm-12 col-md-2 my-auto">
                                          <strong class="step-form">2.</strong>
                                      </div>

                                      <div class="col-12 col-sm-12 col-md-10">
                                          <label class="form-label float-start">
                                              {{'Cardboard_UploadContract_InformationText' | translate}}
                                          </label>

                                          <input class="form-control form-control-lg"
                                              type="file" #inputFile formControlName="file" (change)="handleFile($event)"
                                              accept="image/png, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                      </div>
                                  </div>
                              </div>

                              <button type="submit" class="cardboard-button btn-block"
                                  [disabled]="uploadContractFormGroup.invalid">
                                  <mat-icon class="mr-2">check_circle</mat-icon>
                                  {{'Cardboard_UploadContract' | translate}}
                              </button>
                          </div> -->
                      </form>
                    </section>
                  </mat-step>

                  <!-- Step 3: Contract signature message -->
                  <mat-step [completed]="completed.contractSignature">
                    <ng-template matStepLabel>
                      {{'General_SignContracts' | translate}}
                    </ng-template>

                    <section class="mt-2" data-cy="company_registered">
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-5">
                          <img src="/assets/images/illustrations/contract.svg" class="img-fluid" alt="Contract">
                        </div>

                        <div class="col-12 col-sm-12 col-md-7 text-justify">
                          <div class="alert alert-warning mb-3 mt-5">
                            <p class="pb-0 mb-0">
                              <span [innerHTML]="'Cardboard_CompanyRegistered' | translate"></span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
