import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { retry, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { PaginationService } from '../pagination/pagination.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ITermCondition } from 'src/app/models/parameters/ITermCondition';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService {

  private _apiURL: String = `${environment.API_URL}/api/termsConditions/`;


  constructor(
      private _http: HttpClient,
      private _paginationService:PaginationService,
      private _translateService:TranslateService
  ) { }

  getTermsConditions() {
      return this._http.get<ITermCondition[]>(`${this._apiURL}GetTermsConditions`).pipe(
          retry(1),
          catchError(this.handleError)
      );
  };

  getTermConditionByLanguage(language:String = 'en') {
      language = this._translateService.currentLang;

      return this._http.get<ITermCondition[]>(`${this._apiURL}GetTermConditionByLanguage?language=${language}`).pipe(
          retry(1),
          catchError(this.handleError)
      );
  }
  
  handleError(error: any) {
      var id = Sentry.captureException(error);
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
          // Get client-side error
          errorMessage = error.error.message;
      } else {
          // Get server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }

      Swal.fire({
          title: `${this._translateService.instant('General_Error')}`,
          text: `Report id: ${id} \n ${errorMessage}`,
          icon: 'error'
      });

      return throwError(errorMessage);
  }
}
