export class FileUploadItem {
    Name: string;
    DateName: String;
    Type: string;
    Size: string;
    File: File;
    Progress: Number;  

    constructor(name:string, dateName:String, type:string, size:string, file:File) {
        this.Name = name,
        this.DateName = dateName,
        this.Type = type,
        this.Size = size, 
        this.File = file,
        this.Progress = 0
    }
}
