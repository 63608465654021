<div class="container-fluid" style="width: 85%;">
  <div class="row">
    <div class="col-12">
      <h4 class="title text-center">
        {{'Cardboard_RepresentativeHasSignedContractTitle' | translate}}
      </h4>
    </div>

    <div class="col-12 mt-3">
      <div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-4 mb-3">
            <img src="/assets/images/illustrations/contract.svg" class="img-fluid" alt="Contract">
          </div>

          <div class="col-12 col-sm-12 col-md-8 text-justify">
            <article class="alert alert-success">
              <span [innerHTML]="'Cardboard_RepresentativeHasSignedContractText' | translate"></span>
            </article>

            <br>

            <p class="mb-0">
              {{'Cardboard_DownloadSignedContract' | translate}}
            </p>
            <button class="col-12 mb-2" *ngFor="let file of representative?.contractSignature"
              (click)="downloadFile(file)" mat-flat-button color="primary">
              <mat-icon>file_download</mat-icon> <small class="ps-1">{{file.filename}}</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
