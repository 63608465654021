import { AbstractControl, ValidatorFn } from "@angular/forms";

export function VatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }

    const vatWithCountryRegex =
      /^(ATU|BE|BG|HR|CY|CZ|DK|EE|FI|FR|DE|GR|HU|IE|IT|LV|LT|LU|MT|NL|PL|PT|RO|SK|SI|ES|SE|GB)(\d{8}|\d{9}|\d{10})$/;
    const vatWithoutCountryRegex = /^(\d{8}|\d{9}|\d{10})$/;

    const currentValue = control.value.trim().replace(/\s/g, '');

    if (
      vatWithCountryRegex.test(currentValue) ||
      vatWithoutCountryRegex.test(currentValue)
    ) {
      return null;
    }

    return { invalidVAT: true };
  };
}
