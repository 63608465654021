import { environment } from "src/environments/environment";
import { ICardboardService } from "./cardboard.interface";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Swal from "sweetalert2";
import { IHelperService } from "../helper/helper";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class CardboardService implements ICardboardService {
  constructor(
    private helperService: IHelperService,
    private translate: TranslateService,
    private http: HttpClient
  ) {}

  public getCardboardCompanyByVAT(VAT: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.API_URL}/api/cardboard/getCardboardCompanyByVAT?VAT=${VAT}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on GET:getCardboardByVAT"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardCompanyById(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${environment.API_URL}/api/cardboard/getCardboardCompanyById?id=${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on GET:getCardboardId endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public companyRegistration(cardboardCompany: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/cardboard/companyRegistration`,
        cardboardCompany
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on POST:companyRegistration endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public updateCardboardCompany(cardboardCompany: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.API_URL}/api/cardboard/updateCardboardCompany`,
        cardboardCompany
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on PUT:updateCardboardCompany endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public setRepresentatives(representatives: any): Observable<any> {
    return this.http
      .put<any>(
        `${environment.API_URL}/api/cardboard/setRepresentativesToCompany`,
        representatives
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on PUT:setRepresentativesToCompany endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public uploadDocumentToCompany(
    companyId: number,
    documentType: string,
    file: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("fileDocumentType", documentType);
    formData.append("file", file, file.name);
    formData.append("id", companyId.toString());

    return this.http
      .post<any>(
        `${environment.API_URL}/api/cardboard/uploadDocumentToCompany`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on POST:uploadDocumentToCompany endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      );
  }

  public createCardboardNDA(data: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/cardboard/createCardboardNDA`,
        data
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on POST:createCardboardNDA endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public getCardboardUnsubscribeByEmail(email: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.API_URL}/api/cardboard/getCardboardUnsubscribeByEmail?email=${email}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on GET:getCardboardUnsubscribeByEmail endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public unsubscribeToEmails(email: string): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/api/cardboard/UnsubscribeToEmails`, {
        email,
      })
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on POST:UnsubscribeToEmails endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  public deleteUser(user: any): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/api/cardboard/deleteUser`, user)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on POST:deleteUser endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  saveSystemLogs(logs: any): Observable<any> {
    return this.http
      .post<any>(`${environment.API_URL}/api/systemLogs/saveSystemLogs`, logs)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          }
        })
      );
  }

  getCardboardCompanySignature(id: number, email: string): Observable<any> {
    return this.http
      .get<any>(
        `${environment.API_URL}/api/cardboard/getCardboardCompanySignature?id=${id}&email=${email}`
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on GET:getCardboardCompanySignature endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      )
      .pipe(catchError(this.helperService.errorHandler));
  }

  uploadRepresentativeContract(form: any, file: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("id", form.id);
    formData.append("email", form.email);
    formData.append("file", file, file.name);

    return this.http
      .post<any>(
        `${environment.API_URL}/api/cardboard/uploadRepresentativeContract`,
        formData
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            var id = Sentry.captureMessage(
              "General Error on POST:uploadRepresentativeContract endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      );
  }

  getCompanyStatus(vat: string): Observable<any> {
    return this.http
      .get<any>(`${environment.API_URL}/api/cardboard/company/status/${vat}`)
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              "General Error on GET:getCompanyStatus endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      );
  }

  uploadBeCauseSignedContract(form: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.API_URL}/api/cardboard/be-cause/signature`,
        form
      )
      .pipe(
        map((response: any) => {
          if (response.status === 200) {
            return response.data;
          } else {
            let id = Sentry.captureMessage(
              "General Error on POST:be-cause/signature endpoint"
            );
            Swal.fire({
              icon: "error",
              title: `${this.translate.instant("General_Error")}`,
              text: `${response.errors.join(", ")} \n Report ID: \n ${id}`,
            });
          }
        })
      );
  }
}
