<ngx-ui-loader [fgsType]="'pulse'" [fgsColor]="'#137eff'" [overlayColor]="'rgb(18 36 58 / 80%)'" [pbColor]="'#137eff'">
</ngx-ui-loader>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div class="preloader" id="preloader" style="display: none;">
    <div class="preloader-inner">
        <div></div>
        <hr>
    </div>
</div>

<router-outlet></router-outlet>

<div class="back-to-top" style="display: none;">
    <span class="back-top"><i class="fa fa-angle-up"></i></span>
</div>
