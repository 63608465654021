<h1 mat-dialog-title>
    {{'General_Representatives' | translate }}
</h1>

<form mat-dialog-content>
    <app-add-representative [companyId]="data.companyId" 
    [OnlyNaturalPerson]="data.OnlyNaturalPerson" 
    [Nationalities]="data.nationalities" 
    [RepresentativesInputList]="data.representativesIn" 
    [NotaryFromList]="data.NotaryFromList"
    [Countries]="data.countries" 
    [Registers]="data.registers"
        (RepresentativesChildList)="assignRepresentatives($event)" [index]="data.index"></app-add-representative>
</form>

<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="[responseRepresentativeList, data.index]">
        {{'General_Close' | translate }}
    </button>
</div>